import { Button, Card, DatePicker, message, Modal, Space, Table, Upload } from "antd";
import React, { useRef, useEffect, useState } from "react";
import { Form, Input, Select, Spin } from "antd";
import dayjs, { locale } from "dayjs";
import { imgUploadSize, letterStatusEnum, process_type_name } from "components/common/enum";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { disabledPastDate, extractFileExtension, extractImageName } from "components/common/validation";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import moment from "moment";
import IconButton from "components/ui/Button/IconButton";
import { deleteDocumentById, getIssuedEmployeeDocById } from "./apihook/EmployeeIssuedDocApi";
import { LETTER_TYPE_API } from "constants";
import { prefillUrl } from "utils/StringUtils";
import { downloadPDF, getMethod } from "components/common/CommonFuntion";
import { CommonDeletePopup } from "components/common/CommonComponent";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";

export const EmployeeDocument = ({ formData, updateFormData, updateWholeEmpDocData, onSave, OnBack, isLoading, isScrLoading, employeeId, OnFinish, UpdateGET }) => {

    const [letterTypeApiList, setLetterTypeApiList] = useState([]);
    const [filteredLetterType, setfilteredLetterType] = useState([]);
    const [empList, setempList] = useState([]);
    const [documentList, setdocumentList] = useState([]);
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const hideCursorRef = useRef([]);
    const [preview, setPreview] = useState({
        visible: false,
        content: null,
        type: '', // 'image' or 'pdf'
        contentType: '',
    });
    const [existingFiles, setExistingFiles] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [deleteItem, setDeleteClicked] = useState({ record: undefined, isDeleteClicked: false, letter_type_id: undefined });
    const tableContainerHeight = "44dvh";

    const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
    const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));
    const customConfig = JSON.parse(sessionStorage.getItem("customConfig"));

    const styles = {
        uploadIcon: {
            width: "1vw",
            height: "1vw"
        },
        uploadInput: {
            flex: 1,
            padding: "0px !important",
            borderRadius: 0,
            border: 'none',
            borderBottom: '1px solid #616161',
        },
        uploadBtn: {
            border: 'none',
            margin: "0 !important",
            borderBottomRightRadius: '4px',
            borderTopRightRadius: '4px'
        },
        deleteBtn: {
            width: "2dvw",
            padding: "0px !important",
            border: "none"
        },
        fltContainer: {
            border: "1px solid #cbcbcb",
            width: "80.7vw",
            backgroundColor: "white"
        },
    }

    let isEditAccess = ((!is_super_admin && !is_admin) ? false : true);
    let isDeleteAccess = customConfig?.modify_issued_documents;

    useEffect(() => {
        getEmployeeDocument();
        getLetterTypeApi();
        employee();
    }, []);

    useEffect(() => {
        getEmployeeDocument();
    }, [UpdateGET]);

    // useEffect(() => {
    //     setIsDisabled(false)
    // }, [AddDisabled])

    // useEffect(() => {
    //     if (isDisabled) {
    //         onSave();
    //     }
    // }, [isDisabled]);

    const getEmployeeDocument = async () => {

        const data = await getIssuedEmployeeDocById(employeeId)
        if (data) {
            if (data.status === "fail") {
                message.warning(data.message)
            }
            else if (data.status === "success") {
                setdocumentList(data.data);
            }
        }
    }

    const getLetterTypeApi = async () => {
        try {
            let url = prefillUrl(LETTER_TYPE_API);
            url = `${url}${`drop_down=True`}`;

            const apiData = await getMethod(url);
            if (apiData && apiData.status === "success" && apiData.data) {
                setLetterTypeApiList([...apiData.data]);
            } else {
                setLetterTypeApiList([]);
            }
        } catch (error) {
            setLetterTypeApiList([]);
        }
    }

    const employee = async () => {
        setempList([])
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setempList([...apiData.data])
            } else {
                setempList([])
            }
        } catch (error) {
            setempList([])
        }
    };

    useEffect(() => {
        if (preview.type === 'pdf' && preview.content) {
            window.open(preview.content, '_blank');
            setPreview({ visible: false, content: null, type: '' });
        }
    }, [preview]);

    useEffect(() => {
        const updatedInitialValue = {
            ...formData,
            process_type: null,
            letter_type: null,
            processed_by: null,
            approved_by: null,
            letter_provided_date: null,
            letter_status: null,
            uploaded_documents: null
        }
        updateWholeEmpDocData("emp_issued_document", updatedInitialValue);
    }, [])

    useEffect(() => {
        if (Object.entries(formData).length > 0 && formData !== undefined) {
            form.setFieldsValue({
                ...formData,
                process_type: formData.process_type,
                letter_type: formData.letter_type,
                processed_by: formData.processed_by,
                approved_by: formData.approved_by,
                letter_provided_date: formData.letter_provided_date,
                letter_status: formData.letter_status,
                uploaded_documents: formData.uploaded_documents
            })
        }
    }, [form, formData]);

    const extractFileDetails = (file) => {
        if (typeof file === "string") {
            // URL-based file
            const fileName = file.split('/').pop().split('?')[0];
            const format = fileName.split('.').pop().toLowerCase();
            return { name: fileName, format, size: null };
        } else if (file.originFileObj) {
            // Object-based file with originFileObj
            return { name: file.name, format: file.type.split('/').pop().toLowerCase(), size: file.size };
        }
        return { name: '', format: '', size: null };
    };

    const handleCancelPreview = () => {
        setPreview({
            visible: false,
            content: null,
            type: '',
        });
    };

    const handleChange = (name, value) => {
        if (name === "process_type") {
            let filteredData = letterTypeApiList?.filter((item) => item?.process_type_name === value);
            setfilteredLetterType(filteredData);
            updateFormData("emp_issued_document", "letter_type", "");
        } else if (name === "letter_type") {
            let selectedOption = letterTypeApiList.find(option => option.id === value);
            let selectedData = selectedOption?.process_type_name === "on_bording" ? "on_bording" : selectedOption?.process_type_name === "off_bording" ? "off_bording" : "";
            updateFormData("emp_issued_document", "process_type", selectedData);
        }
        updateFormData("emp_issued_document", name, value);
    };

    const handleClear = () => {
        const updatedInitialValue = {
            process_type: undefined,
            letter_type: undefined,
            processed_by: undefined,
            approved_by: undefined,
            letter_provided_date: undefined,
            letter_status: undefined,
            uploaded_documents: []
        }
        updateWholeEmpDocData("emp_issued_document", updatedInitialValue);
    }

    const handlePreview = (fileList) => {
        const fileListType = (fileList && Array.isArray(fileList))
            ? "array"
            : (fileList && typeof fileList === "string")
                ? "string"
                : "unknown";

        if (fileListType === "array" && fileList.length > 0) {
            const file = fileList[0].originFileObj;
            // Check if the file is an image or PDF
            if (file && file instanceof File) {
                const fileExtension = file.type.startsWith('image/') ? 'image' : 'pdf';
                setPreview({
                    visible: fileExtension === 'image',
                    content: URL.createObjectURL(file),
                    type: fileExtension,
                    contentType: fileListType,
                });
            }
        } else if (fileListType === "string" && fileList) {
            const fileExtension = extractFileExtension(fileList) === "pdf" ? 'pdf' : 'image';
            setPreview({
                visible: fileExtension === "image",
                content: fileList,
                type: fileExtension,
                contentType: fileListType,
            });
        }
    };

    const checkForDuplicateFiles = (newFile) => {
        if (typeof newFile === "object" && newFile.originFileObj) {
            const newFileDetails = extractFileDetails(newFile);

            return existingFiles?.some(existingFile => {
                if (existingFile) {
                    const existingFileDetails = extractFileDetails(existingFile);
                    return (
                        newFileDetails.name === existingFileDetails.name &&
                        newFileDetails.format === existingFileDetails.format &&
                        newFileDetails.size === existingFileDetails.size
                    );
                }
                return false;
            });
        }
        return false;
    };

    const handleFileChange = (fieldName, fileList) => {
        if (Array.isArray(fileList) && fileList.length > 0) {
            // Check for duplicates
            const isDuplicate = checkForDuplicateFiles(fileList[0]);
            const fileSizeMB = fileList[0].size / (1024 * 1024);
            const isPdf = fileList[0].type === 'application/pdf';
            if (isDuplicate) {
                ToastMsg("error", "Duplicate file detected. Please upload a different file.");
                return;
            } else if (!isPdf) {
                ToastMsg("error", "Unsupported file type");
                return;
            } else if (fileSizeMB > imgUploadSize) {
                ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
                return;
            }
        }
        // Proceed to update form fields and state if no duplicates
        form.setFields([
            {
                name: [fieldName],
                value: fileList ? fileList : "",
            }
        ]);
        updateFormData("emp_issued_document", fieldName, fileList);
    };


    const renderUpload = (fieldName) => {
        let ImageUrl = (!Array.isArray(formData[fieldName]) && (formData[fieldName] && typeof formData[fieldName] === "string"))
            ? extractImageName(formData[fieldName]) : "";
        let isDocAvail = formData[fieldName] && formData[fieldName]?.length > 0 && formData[fieldName][0]?.name;

        return (
            <Form.Item
                name={fieldName}
                rules={[{ required: true, message: "This field is required" },]}
                style={{ width: "100%" }}
                fileList={(Array.isArray(formData[fieldName]) ? formData[fieldName] : [])}
            >
                <div className="commonInputTlt">Upload here {<span className='requiredTxt'>*</span>}</div>
                <Input
                    disabled={!isEditAccess ? true : false}
                    readonly
                    style={styles.uploadInput}
                    value={ImageUrl ? ImageUrl : (isDocAvail) ? formData[fieldName][0]?.name : ""}
                    onClick={() => {
                        const uploadInput = document.getElementById(`uploadInput_${fieldName}`);
                        if (uploadInput && isEditAccess) {
                            uploadInput.click();
                        }
                    }}
                    suffix={isEditAccess ? (ImageUrl || isDocAvail) ?
                        (
                            <div className="flex justify-center items-center">
                                <Button
                                    icon={<EyeOutlined style={styles.uploadIcon} />}
                                    onClick={() => handlePreview(formData[fieldName])}
                                    style={styles.deleteBtn}
                                />
                                <Button
                                    icon={<DeleteOutlined style={styles.uploadIcon} />}
                                    onClick={() => handleFileChange(fieldName, [])}
                                    style={styles.deleteBtn}
                                />
                            </div>
                        ) :
                        <Upload
                            id={`uploadInput_${fieldName}`}
                            maxCount={1}
                            fileList={Array.isArray(formData[fieldName]) ? formData[fieldName] : []}
                            onChange={({ fileList }) => handleFileChange(fieldName, fileList)}
                            beforeUpload={(file) => {
                                const isPDF = file.type === 'application/pdf';
                                const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;
                                // if (!isPDF) {
                                //     ToastMsg("error", "You can only upload PDF files!");
                                //     return false;
                                // } else if (!isWithinSizeLimit) {
                                //     ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                                //     return false;
                                // }
                                return false;
                            }}
                            showUploadList={false}
                            style={styles.uploadBtn}
                            accept=".pdf*"
                        >
                            <Button style={styles.deleteBtn} icon={<img src={ImagePaths.documentUpload.default} alt="documentUpload" style={styles.uploadIcon} />} />
                        </Upload> : null}
                />
            </Form.Item>
        )
    }

    const handleDeleteClicked = (record) => {
        setDeleteClicked((prevdata) => ({
            ...prevdata,
            record: record?.id,
            isDeleteClicked: true,
            letter_type_id: record?.letter_type
        }));
    }

    const handleCancelBtn = () => {
        setDeleteClicked((prevdata) => ({
            ...prevdata,
            id: undefined,
            isDeleteClicked: false
        }));
    }

    const handleDeleteBtn = (id, type_id) => {
        deleteDocumentById(id, type_id)
            .then((data) => {
                if (data.status === "success") {
                    ToastMsg("success", data.message);
                    getEmployeeDocument()
                } else if (data.status === "fail") {
                    ToastMsg("error", data.message);
                }
            })
            .catch((error) => {
                ToastMsg("error", error.message);
            })
            .finally(() => {
                handleCancelBtn();
            })
    }


    const columns = [
        {
            title: "Issued Date & Time",
            dataIndex: "issued_date",
            key: "issued_date",
            sorter: true,
            width: "13%",
            render: (_, record) => (
                <span style={{ color: "black" }}>
                    {record?.issued_date ? moment(record?.issued_date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY | hh:mmA") : "-"}
                </span>
            ),
            align: "center",
            fixed: "left",
        },
        {
            title: "Process Type",
            dataIndex: "process_type",
            key: "process_type",
            sorter: true,
            width: "10%",
            render: (_, record) => (
                <span style={{ color: "black", textTransform: "capitalize" }}>
                    {record?.process_type ? (record.process_type).replace("_", " ") : "-"}
                </span>
            ),
            align: 'center',
            fixed: "left",
        },
        {
            title: "Letter Type",
            dataIndex: "letter_type_name",
            key: "letter_type_name",
            sorter: true,
            width: "10%",
            render: (_, record) => (
                <span style={{ color: "black" }}>
                    {record?.letter_type_name ? record?.letter_type_name : "-"}
                </span>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Processed By",
            dataIndex: "processed_by_name",
            key: "processed_by_name",
            sorter: true,
            width: "10%",
            render: (_, record) => (
                <span style={{ color: "black" }}>
                    {record?.processed_by_name ? record?.processed_by_name : "-"}
                </span>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Approved By",
            dataIndex: "approved_by_name",
            key: "approved_by_name",
            sorter: true,
            width: "10%",
            render: (_, record) => (
                <span style={{ color: "black" }}>
                    {record?.approved_by_name ? record?.approved_by_name : "-"}
                </span>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Letter Status",
            dataIndex: "letter_status",
            key: "letter_status",
            sorter: true,
            width: "10%",
            render: (_, record) => (
                <span style={{ color: "black", textTransform: "capitalize" }}>
                    {record?.letter_status ? (record?.letter_status).replace("_", " ") : "-"}
                </span>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Attachment",
            dataIndex: "attachment",
            key: "attachment",
            sorter: true,
            width: "14%",
            render: (_, record) => {
                return (
                    <div>{record?.attachment ?
                        <a style={{ cursor: "pointer" }} onClick={(e) => {
                            e.preventDefault();
                            if (record?.attachment) {
                                downloadPDF(record?.attachment);
                            }
                        }}>
                            <span className='flex py-2' style={{ color: '#04B7B1' }}>
                                <img style={{marginRight:"0.3vw"}} src={ImagePaths.attachment_line.default} /> Download Attachment
                            </span>
                        </a> : <div>-</div>}
                    </div>
                )
            },
            align: "right",
            fixed: "left",
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            width: "9%",
            fixed: "left",
            render: (_, record) => (
                <div className="flex justify-center items-center">
                    <Space size={"middle"}>
                        <IconButton
                            title="Delete"
                            icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                            disabled={!isEditAccess || !isDeleteAccess}
                            onClick={() => (isLoading ? "" : handleDeleteClicked(record))}
                            testID={'ug_btn_delete'}
                        />
                    </Space>
                </div>
            ),
        }
    ];

    const handleTableComponent = () => {
        return (
            <div style={styles.fltContainer}>
                <Table
                    columns={columns}
                    dataSource={documentList?.length > 0 ? documentList : null}
                    style={{ "--table-container-height": tableContainerHeight }}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    pagination={false}
                />
            </div>
        )
    }

    return (
        <div style={{ width: "80.7vw", marginLeft: "0.5vw", overflow: "hidden" }}>
            <Form
                form={form}
                layout="vertical"
                name="basicform"
                style={{ width: "80.7vw", height: "100%" }}
                requiredMark={false}
                onFinish={onSave}
                initialValues={formData}
                onSubmit={(e) => e.preventDefault()}
            >
                <div style={{ height: "39dvh" }}>
                    <div className="grid grid-cols-1">
                        {isScrLoading && (
                            <div className="loaderOverlay">
                                <Spin />
                            </div>
                        )}
                        <Card >
                            <div class="grid grid-cols-3 gap-x-5" style={{ height: "31dvh" }} >
                                <div className="flex-initial ... text-left">
                                    <Form.Item
                                        name="process_type"
                                        rules={[{ required: true, message: "This field is required" },]}>
                                        <div className='commonInputTlt'>Process Type {<span className='requiredTxt'>*</span>}</div>
                                        <Select
                                            value={formData?.process_type ? formData?.process_type : ""}
                                            onChange={(value) => handleChange("process_type", value)}
                                            allowClear={true}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                            }
                                            disabled={!isEditAccess}
                                        >
                                            {process_type_name.map(option => {
                                                return (
                                                    <Select.Option value={option.value}> {option.text}</Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="flex-initial ... text-left">
                                    <Form.Item name="letter_type"
                                        rules={[{ required: true, message: "This field is required" },]}>
                                        <div className="commonInputTlt">Letter Type  {<span className='requiredTxt'>*</span>}</div>
                                        <Select
                                            value={formData?.letter_type ? formData.letter_type : ""}
                                            onChange={(value) => handleChange("letter_type", value)}
                                            allowClear={true}
                                            disabled={!isEditAccess}
                                        >
                                            {(filteredLetterType.length > 0 ? filteredLetterType : letterTypeApiList).map(option => {
                                                return (
                                                    <Select.Option key={option.id} value={option.id}>
                                                        {option.letter_type_name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="flex-initial ... text-left">
                                    <Form.Item name="processed_by"
                                        rules={[{ required: true, message: "This field is required" },]}>
                                        <div className="commonInputTlt">Processed By  {<span className='requiredTxt'>*</span>}</div>
                                        <Select
                                            showSearch
                                            value={formData.processed_by ? formData.processed_by : ""}
                                            onChange={(value) => handleChange("processed_by", value)}
                                            allowClear={true}
                                            disabled={!isEditAccess}
                                            filterOption={(input, option) => {
                                                const childrenText = option.children;
                                                return typeof childrenText === 'string' &&
                                                    childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }}
                                            ref={(el) => {
                                                hideCursorRef.current = el;
                                            }}
                                            onSelect={() => {
                                                if (hideCursorRef.current) {
                                                    hideCursorRef.current.blur();
                                                }
                                            }}
                                        >
                                            {empList.map(option => {
                                                return (
                                                    <Select.Option key={option.id} value={option.id}>
                                                        {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="flex-initial ... text-left">
                                    <Form.Item name="approved_by"
                                        rules={[{ required: true, message: "This field is required" },]}
                                    >
                                        <div className="commonInputTlt">Approved By  {<span className='requiredTxt'>*</span>}</div>
                                        <Select
                                            showSearch
                                            value={formData.approved_by ? formData.approved_by : ""}
                                            onChange={(value) => handleChange("approved_by", value)}
                                            allowClear={true}
                                            disabled={!isEditAccess}
                                            filterOption={(input, option) => {
                                                const childrenText = option.children;
                                                return typeof childrenText === 'string' &&
                                                    childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }}
                                            ref={(el) => {
                                                hideCursorRef.current = el;
                                            }}
                                            onSelect={() => {
                                                if (hideCursorRef.current) {
                                                    hideCursorRef.current.blur();
                                                }
                                            }}
                                        >
                                            {empList.map(option => {
                                                return (
                                                    <Select.Option key={option.id} value={option.id}>
                                                        {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="flex-initial ... text-left">
                                    <Form.Item
                                        name="letter_provided_date"
                                        rules={[{ required: true, message: "This field is required" },]}>
                                        <div className='commonInputTlt'>Letter Provided Date {<span className='requiredTxt'>*</span>}</div>
                                        <DatePicker
                                            allowClear
                                            locale={locale}
                                            style={{ width: "100%" }}
                                            name={"letter_provided_date"}
                                            format={"DD-MM-YYYY"}
                                            value={formData?.letter_provided_date ? dayjs(formData.letter_provided_date) : undefined}
                                            onChange={(date) => handleChange('letter_provided_date', date)}
                                            disabledDate={disabledPastDate}
                                            placeholder=""
                                            disabled={!isEditAccess}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="flex-initial ... text-left">
                                    <Form.Item name="letter_status"
                                        rules={[{ required: true, message: "This field is required" },]}
                                    >
                                        <div className="commonInputTlt">Letter Status {<span className='requiredTxt'>*</span>}</div>
                                        <Select
                                            showSearch
                                            value={formData.letter_status ? formData.letter_status : ""}
                                            onChange={(value) => handleChange("letter_status", value)}
                                            allowClear={true}
                                            disabled={!isEditAccess}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                            }
                                            ref={(el) => {
                                                hideCursorRef.current = el;
                                            }}
                                            onSelect={() => {
                                                if (hideCursorRef.current) {
                                                    hideCursorRef.current.blur();
                                                }
                                            }}
                                        >
                                            {letterStatusEnum.map(option => {
                                                return (
                                                    <Select.Option key={option.value} value={option.value}>
                                                        {option.text}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="text-left">{renderUpload("uploaded_documents")}</div>
                            </div>
                            <div className='flex justify-end items-end' style={{ height: "8%" }}>
                                <MyButton htmlType="button" disabled={!isEditAccess} label="Clear" onClick={handleClear} outlined={true} paddingX={"0 2vw"} marginRight={"0.625vw"} />
                                <MyButton htmlType="submit" disabled={!isEditAccess} label={"Add"} loading={isLoading} paddingX={"0 2vw"} />
                            </div>

                        </Card>
                    </div>
                    {
                        (preview?.visible && preview?.content && preview?.type === 'image') ?
                            <Modal
                                open={preview.visible}
                                centered
                                className="custom-modal"
                                width={"50dvw"}
                                footer={null}
                                onCancel={handleCancelPreview}>
                                <img src={preview.content} alt="Preview" className="emp_img" />
                            </Modal>
                            : null
                    }
                </div>
            </Form>
            {handleTableComponent()}
            {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.record, deleteItem.letter_type_id)} /> : null}
            <div style={{ height: "8%", backgroundColor: "white", padding: "0.5vw", marginTop: "-0.2vw", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "80.7vw" }}>
                <MyButton htmlType="button" label="Back" onClick={() => OnBack("fromIssuedDoc")} outlined={true} paddingX={"0 2vw"} marginRight={"0.625vw"} />
                <MyButton htmlType="button" label={"Finish"} onClick={() => OnFinish("Finished")} loading={isLoading} paddingX={"0 2vw"} />
            </div>
        </div>
    )
};

export default EmployeeDocument;