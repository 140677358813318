import React, {useState, useRef} from 'react';
import { Avatar, Tooltip, Carousel } from 'antd';
import male from "assets/svg/Male.svg";
import { Modal, Card, Select, Input } from 'antd';
import MyButton from '../ui/Button/MyButton';
import { toIndianCurrency } from "utils/StringUtils";
import { extractFileExtension } from 'components/common/validation';
import { ImagePaths } from 'utils/ImagePath';

export const EmployeeDetails = ({ details, icon, isFromSubordinate }) => {
    let fullname =  `${details?.title ? details?.title : ""} ${details?.first_name ? details?.first_name : ""} ${details?.last_name ? details?.last_name : ""}`;
    let profile = details?.profile ? details?.profile : male;
    let designation = details?.designation_id?.designation_name ? details?.designation_id?.designation_name : "-";
    let employee_code = details?.id ? details?.id : "";
    const maxLength = isFromSubordinate ? 12 : 20;
    return (
        <div className='flex'>
            <Avatar shape="round" size={40} src={profile} />
            <div className='ml-1'>
                <div className = "flex justify-between items-center">
                    <p className={`common_employee_nameTxt ${isFromSubordinate ? 'common_subordi_employee_nameTxt' : ''}`}>
                        {fullname?.length > maxLength ?
                            <Tooltip title={fullname}>
                                <span
                                    className={`common_employee_nameTxt ${isFromSubordinate ? 'common_subordi_employee_nameTxt' : ''}`}>
                                    {fullname?.slice(0, maxLength) + "..."}</span>
                            </Tooltip>
                            : fullname ? fullname : "-"}
                    </p>
                    <span className='ml-2'>{icon}</span>
                </div>
                <p className={`common_employee_desTxt ${isFromSubordinate ? 'common_subordi_employee_desTxt' : ''}`}>
                    {designation?.length > maxLength ?
                        <Tooltip title={designation}>
                            <span className={`common_employee_desTxt ${isFromSubordinate ? 'common_subordi_employee_desTxt' : ''}`}
                            >{designation?.slice(0, maxLength) + "... "}</span>
                        </Tooltip>
                        : designation ? designation : "-"} {employee_code ? " | " : ""}
                    {employee_code?.length > 10 ?
                        <Tooltip title={employee_code}>
                            <span className={`common_employee_desTxt ${isFromSubordinate ? 'common_subordi_employee_desTxt' : ''}`}>
                                {employee_code?.slice(0, 10) + "... "}</span>
                        </Tooltip>
                        : employee_code ? employee_code : ""}
                </p>
            </div>
        </div>
    )
}

export const CommonCancelPopup = ({ handleCancelBtn, handleDraftBtn, testID }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const handleOk = () => {
        handleDraftBtn();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        handleCancelBtn();
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal title=""
                open={isModalOpen}
                centered
                onOk={handleOk}
                onCancel={handleCancel}
                width={"18vw"}
                footer={null}
                data-testid={`${testID}_cnlPopUp`}
            >
                <p id="common_cancelTxt" className='m-2'>Are you sure? Do you want to cancel this data?</p>
                <div style={{ textAlign: 'center', marginTop: "0.7vw" }}>
                    <MyButton key="cancel" testID = {`${testID}_cnl_cnlBtn`} label="Cancel" onClick={handleCancel} bgColor={"Red"} paddingX={"0 0.5vw"} marginRight={"0.625vw"} />
                    <MyButton key="Save Draft" testID = {`${testID}_cnl_savedft_btn`}  label={"Save Draft"} onClick={handleOk} outlined = {true} paddingX={"0 0.5vw"} />
                </div>
            </Modal>
        </>
    );
}

export const CommonDeletePopup = ({ handleCancelBtn, handleDeleteBtn, testID, sentence, okBtnLable, width }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const handleOk = () => {
        handleDeleteBtn();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        handleCancelBtn();
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal title=""
                open={isModalOpen}
                centered
                onOk={handleOk}
                onCancel={handleCancel}
                width={width ? width : "18vw"}
                footer={null}
                data-testid={`${testID}_dltPopUp`}
            >
                <p id="common_cancelTxt" className='m-2'>{sentence ? sentence : "Are You Sure ? Do you want to Delete?"}</p>
                <div style={{ textAlign: 'center', marginTop: "0.7vw" }}>
                    <MyButton key="delete" label={okBtnLable ? okBtnLable : "Delete"} testID = {`${testID}_dlt_dltBtn`} onClick={handleOk} bgColor={"#D94854"} paddingX={"0 0.5vw"} marginRight={"0.625vw"} />
                    <MyButton key="cancel" label="Cancel" testID = {`${testID}_dlt_cnlBtn`} onClick={handleCancel} outlined = {true} paddingX={"0 0.5vw"} className='text-black' />
                </div>
            </Modal>
        </>
    );
}

export const CommonClickableCards = ({label, count, onClick, testID}) => {
    return (
        <Card data-testid={`${testID}_clkCard`} className='clickableContainer' onClick={onClick}>
            <p className='clickable_countTxt'>{count ? count : 0}</p>
            <p className='clickable_lblTxt'>{label ? label : ""}</p>
        </Card>
    )
}

export const CommonAmountCard = ({ label, amount, isCount = false, testID }) => {
    return (
        <Card data-testid={`${testID}_clkCard`} className='amountContainer'>
            <p className='clickable_lblTxt'>{label ? label : ""}</p>
            <p className='amount_countTxt'>{(!isCount && (amount || amount === 0) ) ? toIndianCurrency(amount) : amount }</p>
        </Card>
    )
}

export const CommonGroupSelect = ({ groupedData, onChange, value, placeholder = "", valueKey, showKey, isRestricted = false, restrictArray = [] , disabled = false}) => {
    const refs = useRef(null);
    return (
        <Select
            style={{ width: '100%' }}
            value={value ? value : undefined}
            placeholder={placeholder ? placeholder : ""}
            disabled = {disabled}
            onChange={onChange}
            ref={refs.current}
            onSelect={() => {
                refs?.current?.blur();
            }}
        >
            {Object.keys(groupedData)?.map((group) => {
                const filteredOptions = groupedData[group]?.filter(option =>
                    ((valueKey === "id" && (option?.is_active === true || option?.id === value)) || valueKey !== "id") &&
                    !(isRestricted && restrictArray.includes(option[valueKey]))
                );

                return filteredOptions?.length > 0 ? (
                    <Select.OptGroup key={group} label={group}>
                        {filteredOptions?.map((item) => (
                            <Select.Option key={item[valueKey]} value={item[valueKey]}>
                                {item[showKey]}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                ) : null;
            })}
        </Select>
    );
}
export const ReasonPopup = (props) => {
    const [states, setStates] = useState({
        dialogBox: true,
        comments: "",
        ReasonError: false
    });

    const closePopup = () => {
        props?.AlertPopupClose();
        setStates({
            dialogBox: true,
            comments: "",
            ReasonError: false
        });
    };

    const handleCommentChange = (e) => {
        setStates((prevState) => ({
            ...prevState,
            comments: e.target.value,
            ReasonError: false
        }));
    };

    const handleSave = () => {
        if (states?.comments?.trim() !== "") {
            props?.sendCmt(states?.comments?.trim());
            closePopup();
        } else {
            setStates((prevState) => ({
                ...prevState,
                ReasonError: true
            }));
        }
    };


    return (
        <Modal
            open={states.dialogBox}
            width={"30dvw"}
            centered
            onOk={handleSave}
            onCancel={closePopup}
            title={""}
            footer={null}
        >
            <p id="common_cancelTxt" className='m-2'>{props?.title}</p>

            <Input.TextArea
                bordered
                className="mt-2 textarea"
                rows={4}
                autoSize={{
                    minRows: 3,
                    maxRows: 4,
                }}
                value={states?.comments ? states?.comments : ""}
                onChange={handleCommentChange}
                placeholder={props?.label}
                maxLength={props?.cmt_Char_limit ? props?.cmt_Char_limit : 150}
                    style={{ width: '100%'}}
            />
                {states.ReasonError && <div className='requiredTxt' style = {{fontSize: "0.85dvw"}}>Enter the Reason</div>}
                <div className = "text-center mt-5">
                    <MyButton key="cancel" label="Cancel" onClick={closePopup} outlined={true} paddingX={"0 0.5dvw"} marginRight={"0.625dvw"}/>
                <MyButton key="confirm" onClick={handleSave} label={"Confirm"} bgColor={"#D94853"} paddingX={"0 0.5dvw"} />
            </div>
        </Modal>
    );
};

export const CommonCarouselPopup = (props) => {
        return (
        <div className='carousel-wrapper'>
            <Carousel className="custom-carousel" dots={false} arrows infinite={false} effect="fade">
                {props?.documents?.map((file, index) => {
                    let imageSrc = null;
                    let pdfSrc = null;
                    if (file?.url) {
                        const fileExtension = extractFileExtension(file?.url) === "pdf" ? 'pdf' : 'image';
                        if (fileExtension === 'image') {
                            imageSrc = file?.url;
                            console.log('svg', imageSrc);
                        }else if(fileExtension === 'pdf'){
                            pdfSrc = file?.url;
                        }
                    } else if (file?.originFileObj?.type?.startsWith('image/') || file?.originFileObj?.type === 'image/svg+xml') {
                        imageSrc = URL.createObjectURL(file?.originFileObj);
                    } else if ( file?.originFileObj?.type === 'application/pdf'){
                        pdfSrc = URL.createObjectURL(file?.originFileObj);
                    }
                    else {
                        const fileExtension = extractFileExtension(file) === "pdf" ? 'pdf' : 'image';
                        if (fileExtension === 'image') {
                            imageSrc = file;
                        }else if(fileExtension === 'pdf'){
                            pdfSrc = file;
                        }
                    }
                            
                    return imageSrc ?
                        (
                            <div key={index} className="carousel-item">
                                <img src={imageSrc} alt={`carousel-item-${index}`} className="carousel-image" />
                            </div>
                        ) : pdfSrc ?
                            <div key={index} className="carousel-item">
                                <img src={ImagePaths.documentPreview.default} alt={`carousel-item-${index}`} className="carousel-pdf-image" />
                                <MyButton htmlType="button" label={(file?.url || !file?.originFileObj ) ? "Download PDF"  : "View PDF"} paddingX={"0 1.2vw"}
                                    onClick={() => {
                                        window.open(pdfSrc, '_blank');
                                    }} />
                            </div>
                            : null;
                })}
            </Carousel>
        </div>
    );
};


export const CommonTDSPopup = ({ handleCancelBtn, handleConfirmBtn, handleExrabtn, sentence, okBtnLable, extraBtnLable, width }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const handleOk = () => {
        handleConfirmBtn();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        handleCancelBtn();
        setIsModalOpen(false);
    };
    const handleExtraOk = () => {
        handleExrabtn();
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal title=""
                open={isModalOpen}
                centered
                onOk={handleOk}
                onCancel={handleCancel}
                width={width ? width : "30vw"}
                footer={null}
            >
                <p id="common_cancelTxt" className='m-2'>{sentence ? sentence : "Do you want upload this file for all employee?"}</p>
                <div style={{ textAlign: 'center', marginTop: "0.7vw" }}>
                    {/* <MyButton key="cancel" label="Cancel" onClick={handleCancel} outlined={true} paddingX={"0 0.5vw"} className='text-black' marginRight={"0.625vw"} /> */}
                    <MyButton key="confirm" label={okBtnLable ? okBtnLable : "Confirm"} onClick={handleOk} className='text-black' paddingX={"0 0.5vw"} marginRight={"1vw"} />
                    <MyButton key="only this employee" label={okBtnLable ? okBtnLable : "Only this Employee"} onClick={handleExtraOk} bgColor={"#04B7B1"} paddingX={"0 0.5vw"} />
                </div>
            </Modal>
        </>
    );
}
