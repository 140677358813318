import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, Select } from "antd";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';
import { disabledPastDate, handleOnKeyNumeric } from "components/common/validation";
import { ApprovedStatus } from "components/common/enum";
import { getEmployeeApprover } from "hooks/api/employeeApi/getEmployeeResignation";
import MyButton from "components/ui/Button/MyButton";

const ResignationSubmission = ({ formData, setFormData, triggerNext, triggerPrevious }) => {

    const [form] = Form.useForm();
    const dateFormat = "YYYY-MM-DD";

    const [approverlist, setApproverlist] = useState([]);
    const [final_approverlist, setFinal_approverlist] = useState([]);

    useEffect(() => {
        getapproverlist();
    }, [])

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            employee_name: formData?.employeeDetails?.employee_name ? formData?.employeeDetails?.employee_name : "",
            employee_id: formData?.employeeDetails?.employee_id ? formData?.employeeDetails?.employee_id : "",
            department: formData?.employeeDetails?.department?.name ? formData?.employeeDetails?.department?.name : "",
            empDesignation: formData?.employeeDetails?.designation?.name ? formData?.employeeDetails?.designation?.name : "",
            request_date: formData?.resignationData?.request_date ? dayjs(formData?.resignationData?.request_date, dateFormat) : undefined,
            reason: formData?.resignationData?.reason ? formData?.resignationData?.reason : "",
            approved_by: formData?.approverDetails?.approved_by_name ? formData?.approverDetails?.approved_by_name : "",
            approved_status: formData?.approverDetails?.approval_status,
            notice_period: formData?.approverDetails?.notice_period,
            last_working_day: formData?.approverDetails?.last_working_day,
            approval_comments: formData?.approverDetails?.approval_comments,
            approval_date: formData?.approverDetails?.approval_date ? dayjs(formData?.approverDetails?.approval_date.split("T")[0], dateFormat) : undefined,
            final_approved_by: formData?.finalApproverData?.approved_by_name,
            final_approval_date: formData?.finalApproverData?.approval_date ? dayjs(formData?.finalApproverData?.approval_date.split("T")[0], dateFormat) : undefined,
            final_approval_status: formData?.finalApproverData?.approval_status,
            final_notice_period: formData?.finalApproverData?.notice_period,
            final_last_working_day: formData?.finalApproverData?.last_working_day,
            final_approval_comments: formData?.finalApproverData?.approval_comments
        }));
        form.setFieldsValue({
            employee_name: formData?.employeeDetails?.employee_name ? formData?.employeeDetails?.employee_name : "",
            employee_id: formData?.employeeDetails?.employee_id ? formData?.employeeDetails?.employee_id : "",
            department: formData?.employeeDetails?.department?.name ? formData?.employeeDetails?.department?.name : "",
            empDesignation: formData?.employeeDetails?.designation?.name ? formData?.employeeDetails?.designation?.name : "",
            request_date: formData?.resignationData?.request_date ? dayjs(formData?.resignationData?.request_date, dateFormat) : undefined,
            reason: formData?.resignationData?.reason ? formData?.resignationData?.reason : "",
            approved_by: formData?.approverDetails?.approved_by_name ? formData?.approverDetails?.approved_by_name : "",
            approved_status: formData?.approverDetails?.approval_status,
            notice_period: formData?.approverDetails?.notice_period,
            last_working_day: formData?.approverDetails?.last_working_day,
            approval_comments: formData?.approverDetails?.approval_comments,
            approval_date: formData?.approverDetails?.approval_date ? dayjs(formData?.approverDetails?.approval_date.split("T")[0], dateFormat) : undefined,
            final_approved_by: formData?.finalApproverData?.approved_by_name,
            final_approval_date: formData?.finalApproverData?.approval_date ? dayjs(formData?.finalApproverData?.approval_date.split("T")[0], dateFormat) : undefined,
            final_approval_status: formData?.finalApproverData?.approval_status,
            final_notice_period: formData?.finalApproverData?.notice_period,
            final_last_working_day: formData?.finalApproverData?.last_working_day,
            final_approval_comments: formData?.finalApproverData?.approval_comments
        })
    }, [formData.employeeDetails])

    const getapproverlist = async () => {
        setApproverlist([])
        setFinal_approverlist([])
        try {
            const apiData = await getEmployeeApprover(`drop_down=True`);
            if (apiData.data && apiData.status === "success") {
                setApproverlist(apiData.data?.approver?.length > 0 ? [...apiData.data.approver] : [])
                setFinal_approverlist(apiData.data?.final_approver?.length > 0 ? [...apiData.data?.final_approver] : [])
            } else {
                setApproverlist([])
                setFinal_approverlist([])
            }
        } catch (error) {
            setApproverlist([])
            setFinal_approverlist([])
        }
    };

    const handleDatePickerChange = (date, name) => {
        const currentDate = dayjs();
        const noday = date.diff(currentDate, 'day')
        if (name === "last_working_day") {
            setFormData({
                ...formData,
                notice_period: noday,
                [name]: dayjs(date).format(dateFormat),
            });
            form.setFieldsValue({
                ...formData,
                notice_period: noday,
                [name]: dayjs(date).format(dateFormat),
            });
        }
        else if (name === "final_last_working_day") {
            setFormData({
                ...formData,
                notice_period: noday,
                [name]: dayjs(date).format(dateFormat),
            });
            form.setFieldsValue({
                ...formData,
                notice_period: noday,
                [name]: dayjs(date).format(dateFormat),
            });
        }
        else {
            setFormData({
                ...formData,
                [name]: dayjs(date).format(dateFormat),
            });
        }
    };

    const handleChange = (name, value) => {
        if (name === "notice_period") {
            const updatedDate = dayjs().add(value, 'day').format(dateFormat);
            setFormData({
                ...formData,
                last_working_day: updatedDate,
                [name]: value,
            });
            form.setFieldsValue({
                ...formData,
                last_working_day: updatedDate,
                [name]: value,
            });
        }
        else if (name === "final_notice_period") {
            const updatedDate = dayjs().add(value, 'day').format(dateFormat);
            setFormData({
                ...formData,
                final_last_working_day: updatedDate,
                [name]: value,
            });
            form.setFieldsValue({
                ...formData,
                final_last_working_day: updatedDate,
                [name]: value,
            });
        }
        else {
            setFormData({
                ...formData,
                [name]: value,
            });
            form.setFieldsValue({
                ...formData,
                [name]: value,
            });
            form.validateFields([[name]]);
        }
    };

    const renderInput = (statekey, maxLength = 150, isReadOnly = false) => {
        return (
            <Input
                autoComplete='off'
                type={"text"}
                style={styles.textField}
                name={statekey}
                value={formData[statekey]}
                maxLength={maxLength}
                disabled={isReadOnly}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                data-testid={statekey}
            />
        )
    }

    const renderDatePicker = (statekey, isReadOnly = false) => {
        return (<DatePicker
            allowClear
            inputReadOnly={true}
            locale={locale}
            value={formData[statekey] ? dayjs(formData[statekey], dateFormat) : dayjs()}
            format={"DD-MM-YYYY"}
            style={styles.textField}
            disabled={isReadOnly}
            onChange={(date) => handleDatePickerChange(date, statekey,)}
            placeholder=""
            disabledDate={disabledPastDate}
        />)
    }

    return (
        <div>
            <div className='h-5/6' style={{ margin: "16px 0px" }}>
                <Form
                    layout="inline"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    className='w-full'
                    name="basicform"
                    initialValues={formData}
                    onFinish={""}
                    onSubmit={(e) => e.preventDefault()}
                    data-testid='rg_form'
                >
                    <div className="w-full" style={{ height: "65vh", overflowY: "scroll" }}>
                        <div className="grid gap-4 grid-cols-3">
                            <Form.Item name="employee_name">
                                <div className='commonInputTlt'>Employee Name</div>
                                {renderInput("employee_name", 150, true)}
                            </Form.Item>
                            <Form.Item name="employee_id">
                                <div className='commonInputTlt'>Employee Id</div>
                                {renderInput("employee_id", 150, true)}
                            </Form.Item>
                            <Form.Item name="department">
                                <div className='commonInputTlt'>Department</div>
                                {renderInput("department", 150, true)}
                            </Form.Item>
                        </div>
                        <div className="grid gap-4 grid-cols-3 mt-5">
                            <Form.Item name="empDesignation">
                                <div className='commonInputTlt'>Designation</div>
                                {renderInput("empDesignation", 150, true)}
                            </Form.Item>
                            <Form.Item name="request_date">
                                <div className='commonInputTlt'>Requested Date</div>
                                {renderDatePicker("request_date", true)}
                            </Form.Item>
                        </div>
                        <div className='w-full grid my-5'>
                            <Form.Item
                                name="reason"
                            >
                                <div className='commonInputTlt'>Reason For Resignation<span className='requiredTxt'>*</span></div>
                                <Input.TextArea
                                    bordered="true"
                                    className="mt-2 textarea"
                                    rows={3}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 5,
                                    }}
                                    maxLength={150}
                                    value={formData.reason ? formData.reason : ""}
                                    placeholder="Reason Fro Resignation"
                                    name="reason"
                                    disabled={true}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    data-testid={'rg_reason'}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ borderTop: "1px solid #c5c4c4", width: "100%", marginLeft: "-1vw", marginBottom: "0.5vw" }}></div>
                        <div className='tbl_headertxt' style={{ fontWeight: "500", marginBottom: "0.5vw" }}>Approver Details</div>
                        <div className="grid gap-4 grid-cols-3">
                            <Form.Item name="approved_by">
                                <div className='commonInputTlt'>Approver</div>
                                {renderInput("approved_by", 150, true)}
                            </Form.Item>
                            <Form.Item name="approval_date">
                                <div className='commonInputTlt'>Approval Date</div>
                                {renderDatePicker("approval_date", true)}
                            </Form.Item>
                            <Form.Item name="approved_status">
                                <div className='commonInputTlt'>Status</div>
                                <Select
                                    style={styles.textField}
                                    value={formData.approved_status}
                                    name={"approved_status"}
                                    onChange={(value) => handleChange("approved_status", value)}
                                    data-testid={'rg_approver'}
                                    disabled={true}
                                >
                                    {ApprovedStatus.map((option) => (
                                        <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="notice_period"
                                rules={[{ required: true, message: "This field is required" }]}>
                                <div className='commonInputTlt'>Notice Period</div>
                                <Input
                                    autoComplete='off'
                                    type={"text"}
                                    style={styles.textField}
                                    name={"notice_period"}
                                    value={formData.notice_period}
                                    maxLength={3}
                                    onKeyDown={handleOnKeyNumeric}
                                    disabled={true}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    data-testid={"notice_period"}
                                />
                            </Form.Item>
                            <Form.Item
                                name="last_working_day"
                                rules={[{ required: true, message: "This field is required" }]}>
                                <div className='commonInputTlt'>Last Working Day</div>
                                {renderDatePicker("last_working_day", true)}
                            </Form.Item>
                        </div>
                        <div className='w-full grid my-5'>
                            <Form.Item
                                name="approval_comments"
                                rules={[{ required: true, message: "This field is required" }]}
                            >
                                <div className='commonInputTlt'>Approval Comments<span className='requiredTxt'>*</span></div>
                                <Input.TextArea
                                    bordered="true"
                                    className="mt-2 textarea"
                                    rows={3}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 5,
                                    }}
                                    maxLength={150}
                                    value={formData.approval_comments ? formData.approval_comments : ""}
                                    placeholder="Comments"
                                    name="approval_comments"
                                    disabled={true}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    data-testid={'rg_app_commments'}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ borderTop: "1px solid #c5c4c4", width: "100%", marginLeft: "-1vw", marginBottom: "0.5vw" }}></div>
                        <div>
                            <div className='tbl_headertxt' style={{ fontWeight: "500", marginBottom: "0.5vw" }}>Final Approver Details</div>
                            <div className="grid gap-4 grid-cols-3">
                                <Form.Item name="final_approved_by">
                                    <div className='commonInputTlt'>Final Approver</div>
                                    {renderInput("final_approved_by", 150, true)}
                                </Form.Item>
                                <Form.Item name="final_approval_date">
                                    <div className='commonInputTlt'>Final Approval Date</div>
                                    {renderDatePicker("final_approval_date", true)}
                                </Form.Item>
                                <Form.Item name="final_approval_status">
                                    <div className='commonInputTlt'>Status</div>
                                    <Select
                                        style={styles.textField}
                                        value={formData.final_approval_status}
                                        name={"final_approval_status"}
                                        onChange={(value) => handleChange("final_approval_status", value)}
                                        data-testid={'rg_approver'}
                                        disabled={true}
                                    >
                                        {ApprovedStatus.map((option) => (
                                            <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="final_notice_period"
                                    rules={[{ required: true, message: "This field is required" }]} >
                                    <div className='commonInputTlt'>Notice Period</div>
                                    <Input
                                        autoComplete='off'
                                        type={"text"}
                                        style={styles.textField}
                                        name={"final_notice_period"}
                                        value={formData.final_notice_period}
                                        maxLength={3}
                                        onKeyDown={handleOnKeyNumeric}
                                        disabled={true}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        data-testid={"final_notice_period"}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="final_last_working_day"
                                    rules={[{ required: true, message: "This field is required" }]}>
                                    <div className='commonInputTlt'>Last Working Day</div>
                                    {renderDatePicker("final_last_working_day", true)}
                                </Form.Item>
                            </div>
                            <div className='w-full grid my-5'>
                                <Form.Item
                                    name="final_approval_comments"
                                    rules={[{ required: true, message: "This field is required" }]}
                                >
                                    <div className='commonInputTlt'>Final Approval Comments<span className='requiredTxt'>*</span></div>
                                    <Input.TextArea
                                        bordered="true"
                                        className="mt-2 textarea"
                                        rows={3}
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 5,
                                        }}
                                        maxLength={150}
                                        value={formData.final_approval_comments ? formData.final_approval_comments : ""}
                                        placeholder="Comments"
                                        name="final_approval_comments"
                                        disabled={true}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        data-testid={'rg_finapp_commments'}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end items-end mr-6 mt-3 w-full'>
                        <MyButton htmlType="button" label="Back" onClick={() => triggerPrevious()} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                        <MyButton htmlType="button" label={"Next"} onClick={() => triggerNext()} marginRight={"0.625vw"} paddingX={"0 1.2vw"} testID="hs_save" />
                    </div>
                </Form>
            </div>
        </div>
    );
};

const styles = {
    textField: {
        width: "23vw"
    },
    formItem: {
        margin: "1vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    }
}

export default ResignationSubmission;