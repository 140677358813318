import { Button, Card, DatePicker, Upload } from "antd";
import React, { useRef, useEffect, useState } from "react";
import { Form, Input, Select, Spin, Modal } from "antd";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusCircleOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { eduDegreeEnum } from "components/common/enum";
import {
  BlankSpacesValidator,
  validateAadharNumber,
  validateDrivingLicenseNumber,
  validateEsic,
  validateUAN,
  validatePANCard,
  extractImageName,
  validatePassPortNumber,
  calculateExperience,
  allowAlphaCapsNum,
  extractFileExtension,
} from "components/common/validation";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { imgUploadSize } from 'components/common/enum';
import locale from 'antd/es/date-picker/locale/en_US';

export const ProfessionalInfo = ({
  formData,
  updateFormData,
  updateWholeFormData,
  onSave,
  OnBack,
  isLoading,
  isScrLoading
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const hideCursorRef = useRef([]);
  const [selectedLevels, setselectedLevels] = useState([]);
  const [preview, setPreview] = useState({
    visible: false,
    content: null,
    type: '', // 'image' or 'pdf'
    contentType: '',
  });
  const [existingFiles, setExistingFiles] = useState([]);

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const disabledEndDate = (startDate, nextStartDate) => (current) => {
    const today = dayjs().endOf('day');
    return current && (current < dayjs(startDate).endOf('day') || current > today || (nextStartDate && current >= dayjs(nextStartDate).endOf('day')));
  };

  const disabledStartDate = (prevEndDate) => (current) => {
    return current && current <= dayjs(prevEndDate).endOf('day');
  };

  const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));

  let isEditAccess = (is_super_admin || is_admin);

  useEffect(() => {
    if (Object.entries(formData).length > 0 &&
      formData !== undefined) {
      form.setFieldsValue({
        ...formData,
        professional_document: formData.professional_document?.length > 0 &&
          formData.professional_document.map(item => ({
            ...item,
            start_date: item.start_date ?item.start_date : undefined,
            end_date: item.end_date ?item.end_date : undefined,
          }))
      })
      setExistingFiles([
        (formData.employee_document.aadhar_document || []),
        (formData.employee_document.driving_licence_document || []),
        (formData.employee_document.pan_document || []),
        (formData.employee_document.uan_document || []),
        (formData.employee_document.esic_document || []),
        (formData.employee_document.passport_document || []),
        (formData.employee_document.insurance_document || []),
        ...formData.educational_document.flatMap(doc => doc.certificate_document || []),
        ...formData.professional_document.flatMap(doc => doc.document || [])
      ])
    }
  }, [form, formData]);

  const checkForEducationalDuplicates = (documents) => {
    const encounteredCertificateNos = new Set();
    const encounteredLevelsOfEducation = new Set();

    for (const doc of documents) {
        const { certificate_no, levels_of_education, department } = doc;
        
        // Check for duplicates for certificate_no
        if (certificate_no) {
            if (encounteredCertificateNos.has(certificate_no)) {
                return "Same Certificate Number Found";
            }
            encounteredCertificateNos.add(certificate_no);
        }
        
        // Check for duplicates for levels_of_education
        if (levels_of_education) {
            if (encounteredLevelsOfEducation.has(levels_of_education)) {
                return "Same Level of Education Found";
            }
            encounteredLevelsOfEducation.add(levels_of_education);
        }
    }
    
    return "";
};

  const checkForProfDuplicates = (documents) => {
    const encounteredValues = new Set();
    const encounteredStartDate = new Set();
    const encounteredEndDate = new Set();
    for (const item of documents) {
      const { company_name, start_date, end_date } = item;
      if (company_name) {
        if (encounteredValues.has(company_name)) {
          return "Same Company Name Found";
        }
        encounteredValues.add(company_name);
      }
      if (start_date) {
        const startDateObj = new Date(start_date);
        const startDateKey = startDateObj.toISOString().slice(0, 10);
        if (encounteredStartDate.has(startDateKey)) {
          return "Same Start Date Found";
        }
        encounteredStartDate.add(startDateKey);
      }
      if (end_date) {
        const endDateObj = new Date(end_date);
        const endDateKey = endDateObj.toISOString().slice(0, 10);
        if (encounteredEndDate.has(endDateKey)) {
          return "Same End Date Found";
        }
        encounteredEndDate.add(endDateKey);
      }
    }
    return "";
  };

  const handleAdd = () => {
    const allPreviousLevelsFilled = formData.educational_document.every((item) => {
      return item.levels_of_education && item.certificate_no && item.certificate_document.length > 0 && item.department && item.grade;
    });
    let isDuplicate = checkForEducationalDuplicates(formData.educational_document);
    if (allPreviousLevelsFilled && isDuplicate === "") {
      const newItems = [...formData.educational_document,
      { levels_of_education: "", certificate_no: "", certificate_document: [], department: "", grade: "" },];
      updateWholeFormData("educational_document", newItems);
    } else {
      ToastMsg("error", isDuplicate ? isDuplicate : "Please fill all previous levels before adding a new one.");
    }
  };

  const handleRemove = (index) => {
    const newItems = [...formData.educational_document];
    newItems.splice(index, 1);
    updateWholeFormData("educational_document", newItems);
  };

  const handleWEAdd = () => {
    const allPreviousLevelsFilled = formData.professional_document.every((item) => {
      return item.company_name && item.designation && item.start_date && item.end_date && item.experience;
    });
    let isDuplicate = checkForProfDuplicates(formData.professional_document);
    if (allPreviousLevelsFilled && isDuplicate === "") {
      const newItems = [...formData.professional_document,
      {
        company_name: "",
        designation: "",
        start_date: "",
        end_date: "",
        experience: "",
        document_name: "",
        document: []
      }];
      updateWholeFormData("professional_document", newItems);
    } else {
      ToastMsg("error", isDuplicate ? isDuplicate : "Please fill all previous levels before adding a new one.");
    }
  };

  const handleWERemove = (index) => {
    const newItems = [...formData.professional_document];
    newItems.splice(index, 1);
    updateWholeFormData("professional_document", newItems);
  };


  const handleInputChange = (statekey, name, value) => {
    form.setFields([
      {
        name: [statekey, name],
        value: value ? value : "",
      }
    ]);
    triggerFieldValidation(statekey, name);
    updateFormData(statekey, name, value);
  };

  const triggerFieldValidation = (statekey, fieldName) => {
    form.validateFields([[statekey, fieldName]]);
  };
 
  const extractFileDetails = (file) => {
    if (typeof file === "string") {
      // URL-based file
      const fileName = file.split('/').pop().split('?')[0];
      const format = fileName.split('.').pop().toLowerCase();
      return { name: fileName, format, size: null };
    } else if (file.originFileObj) {
      // Object-based file with originFileObj
      return { name: file.name, format: file.type.split('/').pop().toLowerCase(), size: file.size };
    }
    return { name: '', format: '', size: null };;
  };

  const checkForDuplicateFiles = (newFile) => {
    if (typeof newFile === "object" && newFile.originFileObj) {
      const newFileDetails = extractFileDetails(newFile);

      return existingFiles.some(existingFile => {
        if (existingFile) {
          const existingFileDetails = extractFileDetails(existingFile);
          return (
            newFileDetails.name === existingFileDetails.name &&
            newFileDetails.format === existingFileDetails.format &&
            newFileDetails.size === existingFileDetails.size
          );
        }
        return false;
      });
    }
    return false;
  };

  const handleFileChange = (statekey, fieldName, fileList) => {
    if (Array.isArray(fileList) && fileList.length > 0) {
      // Check for duplicates
      const isDuplicate = checkForDuplicateFiles(fileList[0]);

      if (isDuplicate) {
        ToastMsg("error", "Duplicate file detected. Please upload a different file.");
        return;
      }
    }

    // Proceed to update form fields and state if no duplicates
    form.setFields([
      {
        name: [statekey, fieldName],
        value: fileList ? fileList : "",
      }
    ]);
    updateFormData(statekey, fieldName, fileList);
  };

  useEffect(() => {
    if (preview.type === 'pdf' && preview.content) {
      window.open(preview.content, '_blank');
      setPreview({ visible: false, content: null, type: '' });
    }
  }, [preview]);

  const handlePreview = (fileList) => {
    const fileListType = (fileList && Array.isArray(fileList))
      ? "array"
      : (fileList && typeof fileList === "string")
        ? "string"
        : "unknown";

    if (fileListType === "array" && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      // Check if the file is an image or PDF
      if (file && file instanceof File) {
        const fileExtension = file.type.startsWith('image/') ? 'image' : 'pdf';
        setPreview({
          visible: fileExtension === 'image',
          content: URL.createObjectURL(file),
          type: fileExtension,
          contentType: fileListType,
        });
      }
    } else if (fileListType === "string" && fileList) {
      const fileExtension = extractFileExtension(fileList) === "pdf" ? 'pdf' : 'image';
      setPreview({
        visible: fileExtension === "image",
        content: fileList,
        type: fileExtension,
        contentType: fileListType,
      });
    }
  };

  const handleCancelPreview = () => {
    setPreview({
      visible: false,
      content: null,
      type: '',
    });
  };

  const handleEducationalChange = (name, index, value) => {
    let newItems = [...formData.educational_document];
    if (name === "grade") {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
        value = Math.min(Math.max(numericValue, 0), 100);
      }
    } else if (name === "certificate_document" && Array.isArray(value) && value.length > 0) {
      // Check for duplicates
      const isDuplicate = checkForDuplicateFiles(value[0]);

      if (isDuplicate) {
        ToastMsg("error", "Duplicate file detected. Please upload a different file.");
        return;
      }
    } 
    newItems[index][name] = value;
    updateWholeFormData("educational_document", newItems);
    form.setFields([
      {
        name: ['educational_document', index, name],
        value: value,
        errors: undefined,
      }
    ]);
  };

  const handleProfessionalChange = (name, index, value) => {
    let newItems = [...formData.professional_document];
    newItems[index][name] = value;
    if (name === "start_date" || name === "end_date") {
      const startDate = name === "start_date" && value ? dayjs(value).format("YYYY-MM-DD")
        : dayjs(newItems[index].start_date).format("YYYY-MM-DD");
      const endDate = name === "end_date" && value ? dayjs(value).format("YYYY-MM-DD")
        : dayjs(newItems[index].end_date).format("YYYY-MM-DD");
      let experience = calculateExperience(startDate, endDate);
      newItems[index]["experience"] = isNaN(experience) ? 0 : experience;
      form.setFields([
        {
          name: ['professional_document', index, 'experience'],
          value: experience,
          errors: undefined,
        }
      ]);
    } else if (name === "document" && Array.isArray(value) && value.length > 0) {
      // Check for duplicates
      const isDuplicate = checkForDuplicateFiles(value[0]);

      if (isDuplicate) {
        ToastMsg("error", "Duplicate file detected. Please upload a different file.");
        return;
      }
    } 
    updateWholeFormData("professional_document", newItems);
    form.setFields([
      {
        name: ['professional_document', index, name],
        value: value,
        errors: undefined,
      }
    ]);
  };

  const handleFinish = (values) => {
    const eduDuplicateMsg = values?.educational_document?.length >= 2 ? checkForEducationalDuplicates(values.educational_document) : "";
    const profDuplicateMsg = values?.professional_document?.length >= 2 ? checkForProfDuplicates(values.professional_document) : "";
    if (eduDuplicateMsg !== "") {
      ToastMsg("error", eduDuplicateMsg);
      return;
    } else if (profDuplicateMsg !== "") {
      ToastMsg("error", profDuplicateMsg);
      return;
    } else {
      onSave();
    }
  }
  
  const renderUpload = (statekey, fieldName) => {
    let ImageUrl = (!Array.isArray(formData[statekey][fieldName]) && (formData[statekey][fieldName] && typeof formData[statekey][fieldName] === "string"))
      ? extractImageName(formData[statekey][fieldName]) : "";
    let isDocAvail = formData[statekey][fieldName] && formData[statekey][fieldName]?.length > 0 && formData[statekey][fieldName][0]?.name;

    return (
      <Form.Item
        name={[statekey, fieldName]}
        style={{ width: "100%" }}
        fileList={(Array.isArray(formData[statekey][fieldName]) ? formData[statekey][fieldName] : [])}
      >
        <div className="commonInputTlt">Upload here</div>
          <Input
            disabled = {!isEditAccess ? true : false}
            readonly
            style={styles.uploadInput}
            value={ImageUrl ? ImageUrl : (isDocAvail) ? formData[statekey][fieldName][0]?.name : ""}
            onClick={() => {
              const uploadInput = document.getElementById(`uploadInput_${statekey}_${fieldName}`);
              if (uploadInput && isEditAccess) {
                  uploadInput.click();
              }
            }}
            suffix = { isEditAccess ? (ImageUrl ||isDocAvail) ?
              (
                <div className="flex justify-center items-center">
                <Button
                icon={<EyeOutlined style={styles.uploadIcon} />}
                onClick={() => handlePreview(formData[statekey][fieldName])}
                style={styles.deleteBtn}
                />
                <Button
                  icon={<DeleteOutlined style={styles.uploadIcon} />}
                  onClick={() => handleFileChange(statekey, fieldName, [] )}
                  style={styles.deleteBtn}
                />
                </div>
              ) :
              <Upload
                id={`uploadInput_${statekey}_${fieldName}`}
                maxCount={1}
                fileList={Array.isArray(formData[statekey][fieldName]) ? formData[statekey][fieldName] : []}
                onChange={({ fileList }) => handleFileChange(statekey, fieldName, fileList)}
                beforeUpload={(file) => {
                  const isImage = file.type.startsWith('image/');
                  const isPDF = file.type === 'application/pdf';
                  if (!isImage && !isPDF) {
                    ToastMsg("error", "You can only upload image or PDF files!");
                    return false;
                  }

                  const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;

                  if (!isWithinSizeLimit) {
                    ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                    return false;
                  }

                  return false;
                }}
                showUploadList={false}
                style={styles.uploadBtn}
                accept=".pdf,image/*"
              >
                <Button style={styles.deleteBtn} icon={<img src={ImagePaths.documentUpload.default} alt="documentUpload" style={styles.uploadIcon} />} />
              </Upload> : null}
          />
      </Form.Item>
    )
  }

  const handleKeyChange = (e) => {
    if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
      e.preventDefault();
    }
  }

  useEffect(() => {
    setselectedLevels(formData?.educational_document?.map(item => item.levels_of_education));
  }, [formData.educational_document]);

  return (
    <Form
      layout="vertical"
      name="basicform"
      ref={formRef}
      style={{ width: "100%", height: "100%" }}
      requiredMark={false}
      onFinish={handleFinish}
      initialValues={formData}
      form={form}
    >
      <div className="grid grid-cols-1" style={{ height: "92%", overflowY: "auto" }}>
        {isScrLoading && (
          <div className="loaderOverlay">
            <Spin />
          </div>
        )}
        <Card>
          <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Employee Documents</p>
          <div class="grid grid-cols-4 gap-x-5">
            <div className="text-left">
              <Form.Item
                name={["employee_document", "aadhar_no"]}
                rules={[
                  {
                    required: false,
                    validator: validateAadharNumber,
                  },
                ]}
              >
                <div className="commonInputTlt">Aadhar Card No</div>
                <Input
                  disabled = {!isEditAccess}
                  maxLength={12}
                  value={formData?.employee_document?.aadhar_no ? formData?.employee_document?.aadhar_no : ""}
                  onKeyDown={handleKeyChange}
                  onChange={(e) => handleInputChange("employee_document", "aadhar_no", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="text-left">{renderUpload("employee_document", "aadhar_document")}</div>
            <div class="text-left">
              <Form.Item
                name={["employee_document", "esic_no"]}
                rules={[
                  {required: false, message: ""},
                  {validator: validateEsic}
                ]}
              >
                <div className="commonInputTlt">ESIC No</div>
                <Input
                  disabled = {!isEditAccess}
                  maxLength={17}
                  name="esic_no"
                  value={formData?.employee_document?.esic_no ? formData?.employee_document?.esic_no : ""}
                  onKeyDown={handleKeyChange}
                  onChange={(e) => handleInputChange("employee_document", "esic_no", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="text-left">{renderUpload("employee_document", "esic_document")}</div>
            <div className="text-left">
              <Form.Item
                name={["employee_document", "driving_licence"]}
                rules={[
                  {
                    required: false,
                    validator: validateDrivingLicenseNumber,
                  },
                ]}
              >
                <div className="commonInputTlt">Driving License</div>
                <Input
                  disabled = {!isEditAccess}
                  maxLength={15}
                  name="driving_licence"
                  value={formData?.employee_document?.driving_licence ? formData?.employee_document?.driving_licence : ""}
                  onChange={(e) => handleInputChange("employee_document", "driving_licence", e.target.value)}
                  onKeyDown={(e) => allowAlphaCapsNum(e)}
                />
              </Form.Item>
            </div>
            <div className="text-left">{renderUpload("employee_document", "driving_licence_document")}</div>
            <div className="text-left">
              <Form.Item
                name={["employee_document", "passport_no"]}
                rules={[ {required: false},{validator: validatePassPortNumber}]}
              >
                <div className="commonInputTlt">Passport No</div>
                <Input
                  disabled = {!isEditAccess}
                  maxLength={8}
                  value={formData?.employee_document?.passport_no ? formData?.employee_document?.passport_no : ""}
                  onChange={(e) => handleInputChange("employee_document", "passport_no", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="text-left">{renderUpload("employee_document", "passport_document")}</div>
            <div className="text-left ">
              <Form.Item
                name={["employee_document", "pan"]}
                rules={[
                  {required: false, message: ""},
                  {validator: validatePANCard}
                ]}
              >
                <div className="commonInputTlt">PAN No</div>
                <Input
                  disabled = {!isEditAccess}
                  value={formData?.employee_document?.pan ? formData?.employee_document?.pan : ""}
                  style={{ width: "100%" }}
                  maxLength={10}
                  minLength={10}
                  onChange={(e) => handleInputChange("employee_document", "pan", e.target.value)}
                  onKeyDown={(e) => allowAlphaCapsNum(e)}
                />
              </Form.Item>
            </div>
            <div className="text-left">{renderUpload("employee_document", "pan_document")}</div>
            <div class="text-left">
              <Form.Item
                name={["employee_document", "insurance_company_name"]}
                rules={[
                  {
                    required: false,
                    message: "",
                    validator: BlankSpacesValidator,
                  },
                ]}
              >
                <div className="commonInputTlt">Insurance Company Name</div>
                <Input
                  disabled = {!isEditAccess}
                  name="insurance_company_name"
                  value={formData?.employee_document?.insurance_company_name ? formData?.employee_document?.insurance_company_name : ""}
                  onChange={(e) => handleInputChange("employee_document", "insurance_company_name", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="text-left">
              <Form.Item style={{ width: "100%" }} />
            </div>  
            <div class="text-left">
              <Form.Item
                name={["employee_document", "uan"]}
                rules={[
                  {
                    required: false,
                    validator: validateUAN,
                  },
                ]}
              >
                <div className="commonInputTlt">UAN</div>
                <Input
                  disabled = {!isEditAccess}
                  maxLength={12}
                  value={formData?.employee_document?.uan ? formData?.employee_document?.uan : ""}
                  onKeyDown={handleKeyChange}
                  onChange={(e) => handleInputChange("employee_document", "uan", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="text-left">{renderUpload("employee_document", "uan_document")}</div>
            <div class="text-left">
              <Form.Item
                name={["employee_document", "policy_no"]}
                rules={[
                  {
                    required: false,
                    message: "",
                    validator: BlankSpacesValidator,
                  },
                ]}
              >
                <div className="commonInputTlt">Policy Number</div>
                <Input
                  disabled = {!isEditAccess}
                  value={formData?.employee_document?.policy_no ? formData?.employee_document?.policy_no : ""}
                  onChange={(e) => handleInputChange("employee_document", "policy_no", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="text-left">{renderUpload("employee_document", "insurance_document")}</div>
          </div>
        </Card>
        <div className="pt-3">
          <Card>
            <p id="common_weekoffTxt" className="pb-4 text-left">Educational Information</p>
            <div className="">
              <div className="grid">
                {formData?.educational_document?.map((item, index) => {
                  let ImageUrl = (!Array.isArray(item?.certificate_document) && (item.certificate_document && typeof item.certificate_document === "string"))
                    ? extractImageName(item?.certificate_document) : "";
                  let isDocAvail = item?.certificate_document && item?.certificate_document?.length > 0 && item?.certificate_document[0]?.name;
                  return (
                  <div key={index} className="grid grid-cols-5 gap-x-5">
                    <div className="flex-initial ... text-left">
                      <Form.Item name={["educational_document", index, "levels_of_education"]} >
                      <div className="commonInputTlt"> Select Board</div>
                        <Select
                          disabled = {!isEditAccess}
                          showSearch
                          value={item.levels_of_education ? item.levels_of_education : ""}
                          onChange={(value) => handleEducationalChange("levels_of_education", index, value)}
                          allowClear={true}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          ref={(el) => {
                            hideCursorRef.current[index] = el;
                          }}
                          onSelect={() => {
                            if (hideCursorRef.current[index]) {
                              hideCursorRef.current[index].blur();
                            }
                          }}
                          >
                            {eduDegreeEnum.map(option => {
                              if (!selectedLevels.includes(option.value)) {
                                return (
                                  <Select.Option key={option.value} value={option.value}>
                                    {option.text}
                                  </Select.Option>
                                );
                              }
                              return null;
                            })}
                          </Select>
                      </Form.Item>
                    </div>
                    <div className="flex-initial ... text-left">
                      <Form.Item
                        name={["educational_document", index, "certificate_no"]}
                        rules={[
                          {
                            required: false,
                            message: "",
                            validator: BlankSpacesValidator,
                          },
                        ]}
                      >
                        <div className="commonInputTlt">Certificate No</div>
                        <Input
                          disabled = {!isEditAccess}
                          value={item.certificate_no ? item.certificate_no : ""}
                          maxLength={30}
                          onChange={(e) => handleEducationalChange("certificate_no", index, e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="flex-initial ... text-left">
                      <Form.Item
                        name={["educational_document", index, "certificate_document"]}
                        style={{ width: "100%" }}
                        fileList={(Array.isArray(item?.certificate_document)) ? item?.certificate_document : []}
                      >
                        <div className="commonInputTlt">Upload here</div>
                          <Input
                            disabled = {!isEditAccess ? true : false}
                            readonly
                            style={styles.uploadInput}
                            value={ImageUrl ? ImageUrl : (isDocAvail) ? item?.certificate_document[0]?.name : ""}
                            onClick={() => {
                              const uploadInput = document.getElementById(`educational_document${index}_certificate_document`);
                              if (uploadInput && isEditAccess) {
                                  uploadInput.click();
                              }
                            }}
                            suffix = {isEditAccess ? (ImageUrl || isDocAvail) ?
                              (
                                <div className="flex justify-center items-center">
                                  <Button
                                    icon={<EyeOutlined style={styles.uploadIcon} />}
                                    onClick={() => handlePreview(item?.certificate_document)}
                                    style={styles.deleteBtn} />
                                  <Button
                                    icon={<DeleteOutlined style={styles.uploadIcon} />}
                                    onClick={() => handleEducationalChange("certificate_document", index, [])}
                                    style={styles.deleteBtn}
                                  />
                                </div>
                              ) :
                            <Upload
                              id={`educational_document${index}_certificate_document`}
                              maxCount={1}
                              fileList={Array.isArray(item?.certificate_document) ? item?.certificate_document : []}
                              onChange={({ fileList }) => handleEducationalChange("certificate_document", index, fileList)}
                                  beforeUpload={(file) => {
                                    const isImage = file.type.startsWith('image/');
                                    const isPDF = file.type === 'application/pdf';
                                    if (!isImage && !isPDF) {
                                      ToastMsg("error", "You can only upload image or PDF files!");
                                      return false;
                                    }

                                    const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;

                                    if (!isWithinSizeLimit) {
                                      ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                                      return false;
                                    }

                                    return false;
                                  }}
                              showUploadList={false}
                              style={styles.uploadBtn}
                              accept=".pdf,image/*"
                            >
                              <Button style={styles.deleteBtn} icon={<img src={ImagePaths.documentUpload.default} alt="documentUpload" style={styles.uploadIcon} />} />
                            </Upload> : null}
                          />
                      </Form.Item>
                    </div>
                    <div className="flex-initial ... text-left">
                      <Form.Item name={["educational_document", index, "department"]}>
                      <div className="commonInputTlt">Enter Grp/Dept</div>
                        <Input
                          disabled = {!isEditAccess}
                          name="department"
                          type="text"
                          value={item.department ? item.department : ""}
                          onChange={(e) => handleEducationalChange("department", index, e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="flex-none ... text-left">
                      <Form.Item name={["educational_document", index, "grade"]}>
                      <div className="commonInputTlt">Grade/Percentage</div>
                        <Input
                          disabled = {!isEditAccess}
                          name="grade"
                          maxLength = {3}
                          onKeyDown={(e) => {
                            const allowedCharacters = /^[A-Z+\-0-9.]*$/;
                            if (!allowedCharacters.test(e.key) && e.key !== "Backspace" &&
                              e.key !== "Delete" && e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight" && e.key !== "Home" &&
                              e.key !== "End") {
                              e.preventDefault();
                            }
                          }}                    
                          value={item.grade ? item.grade : ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            const isNumeric = /^[0-9.]*$/.test(value);
                            if (isNumeric) {
                              const numValue = parseFloat(value);
                              if (numValue >= 0 && numValue <= 100) {
                                handleEducationalChange("grade", index, value);
                              } else if (value === "") {
                                handleEducationalChange("grade", index, value);
                              }
                            } else {
                              handleEducationalChange("grade", index, value);
                            }
                          }}
                        />
                      </Form.Item>
                      {(index > 2 && isEditAccess) ?  (
                        <div className="flex justify-end">
                          <Button
                            onClick={() => handleRemove(index)}
                            icon={<MinusCircleOutlined className="plusIcon"/>}
                            style={{ border: 0 }}
                            size="large"
                          ></Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )})}
                {isEditAccess ? <div className="flex flex-row justify-start">
                  <Button
                    onClick={handleAdd}
                    icon={<PlusCircleOutlined className="plusIcon"/>}
                    style={{ border: 0 }}
                    size="large"
                  ></Button>
                </div> : null}
              </div>
            </div>
          </Card>
        </div>
        <div className="pt-3">
          <Card>
            <p id="common_weekoffTxt" className="pb-4 text-left">Professional Documents</p>
            <div className="">
              <div className="grid">
                {formData?.professional_document?.map((item, index) => {
                  let ImageUrl = (!Array.isArray(item?.document) && (item.document && typeof item.document === "string")) ? extractImageName(item?.document) : "";
                  let isDocAvail = item?.document && item?.document?.length > 0 && item?.document[0]?.name;
                  const nextItem = formData.professional_document[index + 1];
                  const prevItem = formData.professional_document[index - 1];
                  return (
                  <div key={index} className="grid grid-cols-4 gap-x-5">
                    <div className="text-left">
                      <Form.Item name={["professional_document", index, "company_name"]}>
                      <div className="commonInputTlt">Previous Company Name</div>
                        <Input
                          disabled = {!isEditAccess}
                          name="company_name"
                          type="text"
                          value={item.company_name}
                          onChange={(e) => handleProfessionalChange("company_name", index, e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="text-left">
                      <Form.Item name={["professional_document", index, "designation"]}>
                      <div className="commonInputTlt">Designation</div>
                        <Input
                          disabled = {!isEditAccess}
                          name="designation"
                          type="text"
                          value={item.designation}
                          onChange={(e) => handleProfessionalChange("designation", index, e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="grid grid-cols-2 gap-x-2">
                      <Form.Item name={["professional_document", index, "start_date"]}>
                      <div className="commonInputTlt">Start Date</div>
                        <DatePicker
                          disabled = {!isEditAccess}
                          name="start_date"
                          format="DD-MM-YYYY"
                          locale={locale}
                          value={item.start_date ? dayjs(item.start_date) : undefined}
                          onChange={(date) => handleProfessionalChange("start_date", index, date)}
                          disabledDate={disabledStartDate(prevItem ? prevItem.end_date : null)}
                          inputReadOnly={true}
                          allowClear={true}
                          placeholder = ""
                        />
                      </Form.Item>
                      <Form.Item name={["professional_document", index, "end_date"]}>
                      <div className="commonInputTlt">End Date</div>
                        <DatePicker
                          disabled = {!isEditAccess}
                          name="end_date"
                          format="DD-MM-YYYY"
                          locale={locale}
                          value={item.end_date ? dayjs(item.end_date) : undefined}
                          onChange={(date) => handleProfessionalChange("end_date", index, date)}
                          disabledDate={disabledEndDate(item.start_date, nextItem ? nextItem.start_date : null)}
                          inputReadOnly={true}
                          allowClear={true}
                          placeholder = ""
                        />
                      </Form.Item>
                    </div>
                    <div className="text-left">
                      <Form.Item name={["professional_document", index, "experience"]}>
                      <div className="commonInputTlt">Years Of Experience</div>
                        <Input
                          name="experience"
                          type="text"
                          value={item.experience ? item.experience : ""}
                          disabled = {true}
                          onChange={(e) => handleProfessionalChange("experience", index, +e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="text-left">
                      <Form.Item name={["professional_document", index, "document_name"]}>
                      <div className="commonInputTlt">Document Name</div>
                        <Input
                          disabled = {!isEditAccess}
                          name="document_name"
                          type="text"
                          value={item.document_name ? item.document_name : ""}
                          onChange={(e) => handleProfessionalChange("document_name", index, e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="text-left">
                      <Form.Item
                        name={["professional_document", index, "document"]}
                        style={{ width: "100%" }}
                        fileList={(Array.isArray(item?.document)) ? item?.document : []}
                      >
                        <div className="commonInputTlt">Upload here</div>
                          <Input
                            disabled = {!isEditAccess ? true : false}
                            readonly
                            style={styles.uploadInput}
                            value={ImageUrl ? ImageUrl : (isDocAvail) ? item?.document[0]?.name : ""}
                            onClick={() => {
                              const uploadInput = document.getElementById(`professional_document${index}document`);
                              if (uploadInput && isEditAccess) {
                                  uploadInput.click();
                              }
                            }}
                            suffix=  {isEditAccess ? (ImageUrl || isDocAvail) ?
                              (
                                <div className="flex justify-center items-center">
                                  <Button
                                    icon={<EyeOutlined style={styles.uploadIcon} />}
                                    onClick={() => handlePreview(item?.document)}
                                    style={styles.deleteBtn} />
                                  <Button
                                    icon={<DeleteOutlined style={styles.uploadIcon} />}
                                    onClick={() => handleProfessionalChange("document", index, [])}
                                    style={styles.deleteBtn}
                                  />
                                </div>
                              ) :
                            <Upload
                              id = {`professional_document${index}document`}
                              maxCount={1}
                              fileList={Array.isArray(item?.document) ? item?.document : []}
                              onChange={({ fileList }) => handleProfessionalChange("document", index, fileList)}
                                  beforeUpload={(file) => {
                                    const isImage = file.type.startsWith('image/');
                                    const isPDF = file.type === 'application/pdf';
                                    if (!isImage && !isPDF) {
                                      ToastMsg("error", "You can only upload image or PDF files!");
                                      return false;
                                    }

                                    const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;

                                    if (!isWithinSizeLimit) {
                                      ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                                      return false;
                                    }

                                    return false;
                                  }}
                              showUploadList={false}
                              style={styles.uploadBtn}
                              accept=".pdf,image/*"
                            >
                              <Button style={styles.deleteBtn} icon={<img src={ImagePaths.documentUpload.default} alt="documentUpload" style={styles.uploadIcon} />} />
                            </Upload> : null}
                          />
                      </Form.Item>
                      {(index > 0 && isEditAccess) ? (
                        <div className="flex justify-end">
                          <Button
                            onClick={() => handleWERemove(index)}
                            icon={<MinusCircleOutlined className="plusIcon"/>}
                            style={{ border: 0 }}
                            size="large"
                          ></Button>
                        </div>) : null}
                    </div>
                  </div>
                )})}
                {isEditAccess ? <div className="flex flex-row justify-start">
                  <Button
                    onClick={handleWEAdd}
                    icon={<PlusCircleOutlined className="plusIcon"/>}
                    style={{ border: 0 }}
                    size="large"
                  ></Button>
                </div> : null}
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className='flex justify-center items-center' style={{ height: "8%", backgroundColor: "white" }}>
        <MyButton htmlType="button" label="Back" onClick={() => OnBack("fromProfessional")} outlined = {true} paddingX={"0 2vw"} marginRight={"0.625vw"} />
        <MyButton htmlType="submit" label={"Next"} loading={isLoading} paddingX={"0 2vw"} />
      </div>
      {/* Preview Modal */}
      {
        (preview?.visible && preview?.content && preview?.type === 'image') ?
          <Modal
            open={preview.visible}
            centered
            className="custom-modal"
            width={"50dvw"}
            footer={null}
            onCancel={handleCancelPreview}>
            <img src={preview.content} alt="Preview" className="emp_img" />
          </Modal>
          : null
      } 
    </Form>
  );
};

const styles = {
  uploadIcon: {
    width: "1vw",
    height: "1vw"
  },
  uploadInput: {
    flex: 1,
    padding: "0px !important",
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid #616161',
  },
  uploadBtn: {
    border: 'none',
    margin: "0 !important",
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px'
  },
  deleteBtn:{
    width: "2dvw",
    padding: "0px !important",
    border: "none"
  }
}

export default ProfessionalInfo;
