import { useEffect, useState, useRef, useCallback } from "react";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { Form, Input, Card, Checkbox, Select, Tooltip } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys } from 'components/common/validation';
import { EMPLOYEE_WEEK_OFF } from "constants";
import MyButton from "components/ui/Button/MyButton";

let weekoffList = [
  { week: 1, monday: 'working_day', tuesday: 'working_day', wednesday: 'working_day', thursday: 'working_day', friday: 'working_day', saturday: 'fullday', sunday: 'fullday' },
  { week: 2, monday: 'working_day', tuesday: 'working_day', wednesday: 'working_day', thursday: 'working_day', friday: 'working_day', saturday: 'fullday', sunday: 'fullday' },
  { week: 3, monday: 'working_day', tuesday: 'working_day', wednesday: 'working_day', thursday: 'working_day', friday: 'working_day', saturday: 'fullday', sunday: 'fullday' },
  { week: 4, monday: 'working_day', tuesday: 'working_day', wednesday: 'working_day', thursday: 'working_day', friday: 'working_day', saturday: 'fullday', sunday: 'fullday' },
  { week: 5, monday: 'working_day', tuesday: 'working_day', wednesday: 'working_day', thursday: 'working_day', friday: 'working_day', saturday: 'fullday', sunday: 'fullday' },
]
export const AddEditWeekOffs = () => {
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    code: "",
    weekoff_name: "",
    department: "",
    is_active: true,
    weekoff_rules: "",
    weekoff_list: weekoffList.map(obj => ({ ...obj })),
    notes: "",
    company: companyid,
  });
  const [eDept, setEDept] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const prevPropsRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const hideCursorRef = useRef(null);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const reorderKeys = (array, keyToMove) => {
    return array.map(item => {
      const { [keyToMove]: value, ...rest } = item;
      return { ...rest, [keyToMove]: value };
    });
  };

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails?.weekoff_id,
        code: editDetails.code ? editDetails.code : "",
        weekoff_name: editDetails?.weekoff_name,
        department: editDetails?.department_id,
        weekoff_rules: editDetails?.weekoff_rules,
        weekoff_list: editDetails?.weekoff_list.length > 0 ? reorderKeys(editDetails?.weekoff_list, "sunday") : weekoffList.map(obj => ({ ...obj })),
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
        company_id: editDetails.company_id
      }));
      form.setFieldsValue({
        code: editDetails.code ? editDetails.code : "",
        weekoff_name: editDetails?.weekoff_name,
        department: editDetails?.department_id,
        weekoff_rules: editDetails?.weekoff_rules,
        weekoff_list: editDetails?.weekoff_list.length > 0 ? reorderKeys(editDetails?.weekoff_list, "sunday") : weekoffList.map(obj => ({ ...obj })),
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData])

  const employeeDept = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEDept([...apiData.data])
      } else {
        setEDept([])
      }
    } catch (error) {
      setEDept([])
    }
  }

  useEffect(() => {
    employeeDept();
  }, []);

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    setLoading(false);
    navigate('/configuration/employeeweekoff');
  }, [navigate, form])

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    handleBackCancel();
  }

  const generateWeekOffRule = (weekList) => {
    let output = [];
    let allDays = {}; // Object to track if all days are working or full-day off
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    // Initialize allDays object
    days.forEach(day => {
      allDays[day] = true;
    });
    // Check if all days are full-day off throughout the weeks
    days.forEach(day => {
      weekList.forEach(week => {
        if (week[day] !== 'fullday') {
          allDays[day] = false;
        }
      });
    });
    // Generate the output string based on the patterns detected
    days.forEach(day => {
      if (allDays[day]) {
        output.push(`All ${day.charAt(0).toUpperCase() + day.slice(1)}`);
      }
    });
    // Check for alternate days in the week for full days (leave)
    days.forEach(day => {
      const isAlternate = weekList.every((week, index) => (index % 2 === 0 && week[day] === 'fullday') || (index % 2 !== 0 && week[day] === 'working_day'));
      if (isAlternate) {
        output.push(`Alternate ${day.charAt(0).toUpperCase() + day.slice(1)}`);
      }
    });
    return output.join(', ');
  }

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token");
    let weekoffRules = generateWeekOffRule(formData.weekoff_list);
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      updatedFormData.weekoff_rules = weekoffRules ? weekoffRules : "";
      if (isDraftClked) {
        updatedFormData = { ...updatedFormData, is_draft: true };
      } else {
        updatedFormData = { ...updatedFormData, is_draft: false };
      }
      fetch(EMPLOYEE_WEEK_OFF, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
              code: "",
              weekoff_name: "",
              department: "",
              is_active: true,
              weekoff_rules: "",
              weekoff_list: weekoffList.map(obj => ({ ...obj })),
              notes: "",
              company: companyid,
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      setIsCancelClk(false);
    }
  }, [formData, fromEdit, companyid, handleBackCancel]);

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    form.setFieldsValue({
      [name]: value,
    });
  };

  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input
        autoComplete='off'
        style={styles.textField}
        disabled = {statekey === "code"}
        type={"text"}
        name={statekey}
        value={formData[statekey] ? formData[statekey] : ""}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testid={testID}
      />
      </>  
    )
  }
  const handleCheckboxChange = (weekIndex, day) => {
    const updatedWeekOffList = [...formData.weekoff_list];
    const currentDayStatus = updatedWeekOffList[weekIndex][day];
    if (currentDayStatus === 'working_day') {
      updatedWeekOffList[weekIndex][day] = 'fullday';
    } else if (currentDayStatus === 'fullday') {
      updatedWeekOffList[weekIndex][day] = 'working_day';
    }
    setFormData({
      ...formData,
      weekoff_list: updatedWeekOffList,
    });
    form.setFieldsValue({
      ...formData,
      weekoff_list: updatedWeekOffList,
    });
  };

  const CheckboxGrid = () => {
    const firstRowLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return (
      <Form.Item
        style={styles.formItem}
        name="weekoff_list"
        rules={[{ required: true, message: 'Please select at least one checkbox' }]}
        initialValue={formData.weekoff_list}
      >
        <p id="common_weekoffTxt">Week Off Rules</p>
        <div style={{ border: "1px solid #cbcbcb", padding: "0.5vw" }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: '20px', marginBottom: '0.5vw' }}>
            <div id="common_weekoff_valTxt" style={styles.lblAlign}>Week</div>
            {firstRowLabels.map(day => (
              <div key={day} id="common_weekoff_valTxt" style={styles.lblAlign}>{day}</div>
            ))}
          </div>
          {formData.weekoff_list.map((week, weekIndex) => (
            <div key={week.week} style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: '20px', marginBottom: '0.5vw' }}>
              {Object.entries(week).map(([key, value]) => {
                if (key === 'week') {
                  return (
                    <div key={key} id="common_weekoff_valTxt" style={styles.lblAlign}>{value}</div>
                  );
                } else {
                  return (
                    <div key={key} style={styles.lblAlign}>
                      <Checkbox
                        className="custom-checkbox"
                        checked={value === 'working_day' ? true : false}
                        onChange={() => handleCheckboxChange(weekIndex, key)}
                        data-testid={'woff_rule'}
                      />
                    </div>
                  )
                }
              })}
            </div>
          ))}
        </div>
      </Form.Item >)
  };

  const keysToExclude = ['is_active', 'company', 'weekoff_list'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Weekoff" : "Add Weekoff"}</p>
        </div>
        <Checkbox
          disabled = {fromEdit ? !isActive : true }
          checked={formData.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
          data-testid={'woff_sts'}
        >
          Active
        </Checkbox>
      </div>
      <div className='h-5/6' style={{ width: "100%", margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          initialValues={formData}
          data-testid={'woff_form'}
        >
          <div className='grid grid-cols-3 gap-5 place-content-between'>
            {fromEdit ? <Form.Item
              style={styles.formItem}
              name="code"
              rules={[{ required: true, message: "This field is required" },
                { validator: noBlankSpacesValidator }
            ]}
            >
              {renderInput('Week-Off Code', "code", 'woff_code', 10, true)}
            </Form.Item> : null}
            <Form.Item
              style={styles.formItem}
              name="weekoff_name"
              rules={[{ required: true, message: "This field is required" },
                { validator: noBlankSpacesValidator }
            ]}
            >
              {renderInput('Week-Off Name', "weekoff_name", 'woff_name', 50, true)}
            </Form.Item>
            <Form.Item
              name="department"
              style={styles.formItem}
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Department <span className='requiredTxt'>*</span></div>
              <Select
                name="department"
                value={formData.department}
                onChange={(value) => handleChange("department", value)}
                style={styles.textField}
                data-testid={'woff_dep'}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                ref={hideCursorRef}
                onSelect={() => {
                  hideCursorRef.current.blur();
                }}
              >
                {eDept &&
                  eDept.map((item) => (
                    (item.id === formData.department || item.is_active) ?
                    <Select.Option key={item.id} value={item.id}>
                      {item.department_name}
                    </Select.Option>
                    : null
                  ))}
              </Select>
            </Form.Item>
            {!fromEdit ? <Form.Item name="notes" style={styles.formItem} className="col-span-1">
              {renderInput('Notes', "notes", 'woff_notes', 150)}
            </Form.Item> : null}
          {/* </div>
          <div className='grid grid-cols-3 gap-5 place-content-between'> */}
            <div className="col-span-2" style={{ width: "calc((100% / 3) * 2)" }}>
              <CheckboxGrid />
              <div className='w-full flex align-center ml-5'>
                <Checkbox
                  disabled={true}
                  style={{
                    "--disabled-background-color": "green",
                    "--disabled-border-color": "green",
                    "--width": "1.5vw",
                    "--height": "1.5vw",
                  }}
                >
                  <p id="common_weekoff_valTxt">Working Days</p>
                </Checkbox>
                <Checkbox
                  disabled={true}
                  style={{
                    "--disabled-background-color": "red",
                    "--disabled-border-color": "red",
                    "--width": "1.5vw",
                    "--height": "1.5vw",
                  }}
                >
                  <p id="common_weekoff_valTxt">Week-Off</p>
                </Checkbox>
              </div>
            </ div>
            {fromEdit ? <Form.Item name="notes" style={styles.formItem} className="col-span-1">
              {renderInput('Notes', "notes", 'woff_notes', 150)}
            </Form.Item> : null }
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="woff_cancel" />
                {fromEdit ? null
                  : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="woff_draft" />}
                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="woff_save" />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID='woff' /> : null}
    </Card>
  );
};
const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  lblAlign:{
    textAlign: "center",
  }
}