import React, { useState, useEffect } from 'react';
import { Input, Space, Typography, Dropdown, Checkbox, DatePicker, Tooltip } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import { SearchOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import {getPrintData, getCSVData} from "./CommonFuntion"; 

const CommonTblHeader = ({
    pageName, addVisible, editVisible, addComponent, pageInfo,
    handleSearch, selectedDetails, addClick, handleFilter,
    columns, handleDraft, onStateChange, isDraft, handleBackToHome,
    url, query, addBtnLabel, isAddRequired = true, isDraftRequired = true, 
    isDateRequired = false, handleDateChange, selectedDate,
    format, picker, testID, isBackRequired = false, isFltRequired = true,
    isPrintRequired = true, isExcelRequired = true, isContentRequired = false,
    contentToShow = "", isToDateRequired = false, handleToDateChange , selectedToDate,
    fromDateTlt = "", toDateTlt = "", disabledFromDate = null, disabledToDate = null ,
    btndisabled = false
}) => {
    const [searchText, setSearchText] = useState("");
    const [column, setColumn] = useState(columns);
    const [draggedItem, setDraggedItem] = useState(null);
    const [reorderedColumns, setReorderedColumns] = useState([]);

    useEffect(() => {
        onStateChange(searchText, reorderedColumns);
    }, [reorderedColumns, searchText, onStateChange]);

    // table option drag-drop and hide-show columns start
    const handleDragStart = (index) => {
        setDraggedItem(index);
    };

    const handleDragOver = (index) => {
        const draggedOverItem = index;
        if (draggedItem === draggedOverItem) {
            return;
        }
        const newItems = [...column];
        newItems.splice(draggedItem, 1);
        newItems.splice(draggedOverItem, 0, column[draggedItem]);
        let filteredColumn = newItems.filter(item => !item.hidden);
        setColumn(newItems);
        setReorderedColumns(filteredColumn);
        setDraggedItem(draggedOverItem);
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    const handleCheck = (value, index) => {
        let list = [...column];
        list[index]['hidden'] = !value;
        let filteredColumn = list.filter(item => !item.hidden);
        setColumn(list);
        setReorderedColumns(filteredColumn);
    };

    const items = column.map((item, index) => ({
        key: index,
        label: (
            <div
                style={{ fontSize: "0.85vw", fontFamily: "Urbanist" }}
                className="flex justify-between items-center"
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={() => handleDragOver(index)}
                onDragEnd={handleDragEnd}
            >
                <Checkbox
                    data-testid={`${testID}_${item?.title?.split(" ")[0]?.slice(0,4)}`}
                    checked={!item.hidden}
                    onChange={(e) => handleCheck(e.target.checked, index)}
                >{item.title}</Checkbox>
                <IconButton title="" icon={<img src={ImagePaths.dragIcon.default} alt="dragIcon" />}/>
            </div>
        ),
    }));
    // table option drag-drop and hide-show columns end

    return (
        <div className={"h-full"}>
            <div className="flex justify-between items-end" style={{ width: (addVisible || editVisible) ? "100%" : "" }}>
                {(addVisible || (editVisible && Object.keys(selectedDetails).length > 0)) ? (
                    addComponent && typeof addComponent === 'function' ? addComponent() : null
                )
                    : isDraft ?
                        <div className='flex justify-start items-center cursor-pointer'>
                            <Tooltip title={"Back"} placement="bottom"><img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackToHome}/></Tooltip>
                            <p className='common_addTltTxt'>{pageInfo?.totalCount ? `Draft (${pageInfo.totalCount})` : "Draft"}</p>
                        </div>
                        :
                        <div className='flex items-center'>
                            {isBackRequired ? <Tooltip title={"Back"} placement="bottom"><img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackToHome}/></Tooltip> : null}
                            <p className='common_tltTxt'>{pageInfo?.totalCount ? `${pageName} (${pageInfo.totalCount})` : pageName}</p>
                        </div>
                }

                <div className='flex justify-center items-center'>
                    {isDateRequired && <div className='flex flex-col justify-center items-start mt-2 mr-5'> 
                        {fromDateTlt ? <div className='commonInputTlt'>{fromDateTlt}</div> : null }
                        <DatePicker
                            bordered={true}
                            size='large'
                            data-testid={`${testID}_datepicker`}
                            onChange={handleDateChange}
                            value={selectedDate}
                            format={format ? format : "DD MMM YYYY"}
                            className={fromDateTlt ? "" : "mt-3"}
                            style={styles.dateInput}
                            {...(picker && { picker })}
                            allowClear={false}
                            disabledDate={disabledFromDate}
                        />
                    </div>}
                    {isToDateRequired && <div className='flex flex-col justify-center items-start mt-2 mr-5'>
                        {toDateTlt ? <div className='commonInputTlt'>{toDateTlt}</div> : null }
                        <DatePicker
                            bordered={true}
                            size='large'
                            data-testid={`${testID}_datepicker2`}
                            onChange={handleToDateChange}
                            value={selectedToDate}
                            format={format ? format : "DD MMM YYYY"}
                            className={toDateTlt ? "" : "mt-3"}
                            style={styles.dateInput}
                            {...(picker && { picker })}
                            allowClear={false}
                            disabledDate={disabledToDate}
                        />
                    </div>}
                    {isContentRequired && contentToShow.length > 0 &&
                        <div className="relative top-4 mr-3 commonTblContentTxt">
                            {contentToShow}
                        </div>
                    }

                {(addVisible || editVisible || isDraft) ? null :
                 <Space size={"middle"} className="relative top-6">
                    <div>
                    <Input
                        data-testid={`${testID}_srch`}
                        style={styles.searchInput}
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onPressEnter={(e)=>handleSearch(e)}
                        onKeyDown={(e) => {
                            if (!/^[a-zA-Z0-9\s]*$/.test(e.key) && e.key !== 'Enter') {
                              e.preventDefault();
                            }
                        }}
                        suffix={
                            <div style={{ borderLeft: "2px solid #183433" }}>
                                <div className="m-1">
                                    <SearchOutlined
                                        style={styles.searchIcon}
                                        className="site-form-item-icon p-1"
                                        onClick={(e)=>handleSearch(e)}
                                    />
                                </div>
                            </div>
                        }
                    />
                        {!isAddRequired ?
                            <Typography style={styles.countTxt}>
                                {`Showing ${pageInfo?.pageSize ? pageInfo?.pageSize : 0} out of ${pageInfo?.totalCount ? pageInfo?.totalCount : 0}`}
                            </Typography> : null}
                    </div>
                    {isAddRequired ?
                        <div className="mr-4">
                            <MyButton testID = {`${testID}_${addBtnLabel ? addBtnLabel : "Add"}`} onClick={addClick} label={addBtnLabel ? addBtnLabel : "Add"} disabled = {btndisabled} />
                            <Typography style={styles.countTxt}>{`Showing ${pageInfo?.pageSize ? pageInfo?.pageSize : 0} out of ${pageInfo?.totalCount ? pageInfo?.totalCount : 0}`}</Typography>
                        </div>
                        : null}
                </Space>}
                </div>
            </div>
            {isDraft ? null : <Space size={"middle"}>
                {isFltRequired ? <IconButton testID = {`${testID}_flt`} title="Filter" icon={<img src={ImagePaths.filter.default} alt="Filter" />} onClick={handleFilter} /> : null}
                <Dropdown data-testid={`${testID}_drop`} menu={{ items, }} trigger={['click']} placement="bottomLeft" overlayStyle={styles.dropOverLay}>
                    <IconButton title="Column Reorder" icon={<img src={ImagePaths.alignColumn.default} alt="Column Reorder" />} />
                </Dropdown>
                {isExcelRequired ? <IconButton testID = {`${testID}_download`} title="Download" icon={<img src={ImagePaths.export.default} alt="Download" />} onClick={() => getCSVData(url, query)} /> : null}
                {isPrintRequired ? <IconButton testID = {`${testID}_print`} title="Print" icon={<img src={ImagePaths.print.default} alt="Print" />} onClick={() => getPrintData(url, query)} /> : null }
                {isDraftRequired ? <IconButton testID = {`${testID}_draft`} title="Draft" icon={<img src={ImagePaths.draft.default} alt="Draft" />} onClick={handleDraft} /> : null}
            </Space>}
        </div>
    );
}

const styles = {
    searchInput: {
        width: "17vw",
        border: "1px solid #616161",
        borderRadius: "0.2vw",
        outline: "none",
        marginTop: "-2vw",
    },
    searchIcon: {
        color: "#616161",
        cursor: "pointer",
        border: "1px solid #183433",
        fontSize: 12,
        borderRadius: 4,
    },
    countTxt: {
        color: "#183433",
        fontSize: "0.75vw",
        fontWeight: 600
    },
    dropOverLay: {
        maxHeight: 250,
        overflowY: "auto"
    },
    dateInput: {
        width: "8vw",
        border: "1px solid #616161",
        borderRadius: "0.2vw",
        outline: "none",
        fontFamily: "Urbanist",
        fontWeight: 500,
        padding: "2px 8px",
    }
}

export default CommonTblHeader;