import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getLeavepermissionReports } from "hooks/api/reportsApi.js";
import { LEAVE_PERMISSION_REPORT } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import dayjs from "dayjs";
import { getEmployeeLeavePolicy } from "hooks/api/masterApi/employeeLeavePolicyApi";
import { disabledFutureDate } from "components/common/validation";

const initialData = {
    leave_from_date: new Date(),
    leave_to_date: new Date(),
    leave_employee_name: "",
    leave_type: "",
    approved_by: "",
    leaveTypeList: []
}

const LeavePermissionReport = () => {
    const authtoken = sessionStorage.getItem("token");
    const pageSize = 10;
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ ...initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const tableContainerHeight = "72vh";
    const dateFormat = "YYYY-MM-DD";
    const [dates, setDates] = useState({
        attendance_correction_from_date: dayjs(),
        attendance_correction_to_date: dayjs(),
    })
    const parsedFromDate = dates?.attendance_correction_from_date ? dayjs(dates?.attendance_correction_from_date) : null;
    const parsedToDate = dates?.attendance_correction_to_date ? dayjs(dates?.attendance_correction_to_date) : null;
    const [query, setQuery] = useState(`from_date=${parsedFromDate ? parsedFromDate.format(dateFormat) : ""}&to_date=${parsedToDate ? parsedToDate.format(dateFormat) : ""}&leave_type=${filterDatas.leave_type ? filterDatas.leave_type : ""}&approved_by=${filterDatas.approved_by ? filterDatas.approved_by : ""}&employee=${filterDatas.leave_employee_name ? filterDatas.leave_employee_name : ""}`);
    
    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);  
    
    const [data, pageInfo, loading, _message, refetch] = useApiData(getLeavepermissionReports, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        const parsedFromDate = dates.attendance_correction_from_date ? dayjs(dates.attendance_correction_from_date) : null;
    const parsedToDate = dates.attendance_correction_to_date ? dayjs(dates.attendance_correction_to_date) : null;
        const newQuery = `from_date=${parsedFromDate ? parsedFromDate.format(dateFormat) : ""}&to_date=${parsedToDate ? parsedToDate.format(dateFormat) : ""}&leave_type=${filterDatas.leave_type ? filterDatas.leave_type : ""}&approved_by=${filterDatas.approved_by ? filterDatas.approved_by : ""}&employee=${filterDatas.leave_employee_name ? filterDatas.leave_employee_name : ""}`;
        setQuery(newQuery);
      }, [filterDatas, dates]);

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    const getLeaveTypeList = async () => {
        try {
          const apiData = await getEmployeeLeavePolicy(`drop_down=True`);
          if (apiData && apiData.status === "success" && apiData.data) {
            setFilterDatas((prevFilterData) => ({
              ...prevFilterData,
              leaveTypeList: [...apiData.data]
            }))
          } else {
            setFilterDatas((prevFilterData) => ({
              ...prevFilterData,
              leaveTypeList: []
            }))
          }
        } catch (error) {
          setFilterDatas((prevFilterData) => ({
            ...prevFilterData,
            leaveTypeList: []
          }))
        }
      }

      useEffect(() => {
        getLeaveTypeList();
      }, []);

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `from_date=${parsedFromDate ? parsedFromDate.format(dateFormat) : ""}&to_date=${parsedToDate ? parsedToDate.format(dateFormat) : ""}&leave_type=${data.leave_type ? data.leave_type : ""}&approved_by=${data.approved_by ? data.approved_by : ""}&employee=${data.leave_employee_name ? data.leave_employee_name : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            leave_employee_name: "",
            leave_type: "",
            approved_by: "",
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleSearch = () => {
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "employee__employee_name",
            key: "employee__employee_name",
            width: "25%",
            sorter: true,
            fixed: 'left',
            render: (_, record) => {
                let empDetails = {
                    ...record.employee,
                    "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
                    "id": record?.employee.employee_id
                }
                return <EmployeeDetails details={empDetails} />;
            }
        },
        {
            title: "Leave Type",
            dataIndex: "leave_type",
            key: "leave_type",
            sorter: true,
            width: "17%",
            fixed: 'left',
            render: (_, record) =>
             <p style={{ color: "black" }}>{record?.leave_type ? record?.leave_type : "-"}</p>
        },
        {
            title: "Req Raised Date/Time",
            dataIndex: "request_raised_date",
            key: "request_raised_date",
            sorter: true,
            width: "17%",
            render: (_, record) => (<span>{record.request_raised_date ? dayjs(record.request_raised_date, "DD-MM-YYYY").format("DD-MM-YYYY") : "-"}</span>),
            align: 'center',
            fixed: 'left',
        },
        {
            title: "Requested Date/Time",
            dataIndex: "from_date",
            key: "from_date",
            sorter: true,
            width: "17%",
            render: (_, record) => (<div><p><b>From date :</b> {record.from_date ? dayjs(record.from_date, "DD-MM-YYYY").format("DD-MM-YYYY") : "-"}</p>
            <p><b>To date :</b> {record.to_date ? dayjs(record.to_date, "DD-MM-YYYY").format("DD-MM-YYYY") : "-"}</p></div>

        ),
            align: 'left',
            fixed: 'left',
        },
        {
            title: "Approved Date/Time",
            dataIndex: "approved_date",
            key: "approved_date",
            sorter: true,
            width: "17%",
            fixed: 'left',
            render: (_, record) =>
                (<span>{record.approved_date ? dayjs(record.approved_date, "DD-MM-YYYY").format("DD-MM-YYYY") : "-"}</span>),
            align: 'center',
        },
        {
            title: "Approved By",
            dataIndex: "approved_by",
            key: "approved_by",
            width: "17%",
            sorter: true,
            fixed: 'left',
            render: (_, record) =>
            (record?.approved_by?.length > 18 ?
                <Tooltip title={record?.approved_by}>{<span style={{ color: "black" }}>{record?.email_id?.slice(0, 15) + "..."}</span>}</Tooltip>
                : <p style={{ color: "black" }}>{record?.approved_by ? record?.approved_by : "-"}</p>)
        },
    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }
        // filter start
    const handleFromDateChange = (value) => {
        setDates((prevState) => ({
        ...prevState,
        attendance_correction_from_date: value,
        }));
    }

    const disabledDateFrom = (restrictionDate) => (current) => {
        if (restrictionDate) {
            const startDate = dayjs(restrictionDate, 'DD-MM-YYYY');
            return current && current < startDate.startOf('day');
        }
        return current && current > dayjs().endOf('day');
    };
    
    const handleToDateChange = (value) => {
        setDates((prevState) => ({
        ...prevState,
        attendance_correction_to_date: value,
        }));
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&from_date=" + (parsedFromDate ? parsedFromDate.format(dateFormat) : null) +
        "&to_date=" + (parsedToDate ? parsedToDate.format(dateFormat) : null) +
        "&leavetype=" + (filterDatas.leave_type ? filterDatas.leave_type : "") +
        "&approvedby=" + (filterDatas.leave_employee_name ? filterDatas.leave_employee_name : "") +
        "&employee=" + (filterDatas.approved_by ? filterDatas.approved_by : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"}>
                <CommonTblHeader pageName={"Leave Permission Report"}
                    pageInfo={pageInfo} handleSearch={handleSearch}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    url={LEAVE_PERMISSION_REPORT} query={queryString}
                    isAddRequired={false} isDraftRequired={false}
                    isContentRequired={true} isDateRequired={true} handleDateChange = {handleFromDateChange}
                    selectedDate={dates.attendance_correction_from_date} isToDateRequired = {true}
                    handleToDateChange = {handleToDateChange} selectedToDate={dates.attendance_correction_to_date}
                    fromDateTlt = {"Select From Date"} toDateTlt = {"Select To Date"} 
                    disabledFromDate = {disabledFutureDate} 
                    disabledToDate = { disabledDateFrom(dates.attendance_correction_from_date)}
                />
            </div>
            <div className="relative h-5/6 mt-1">
                {filterVisible ?
                    <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
                        <ReportsFilter
                            pageName={"Leave Permission Report"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={"mt-5 w-full"} style={styles.fltContainer}>
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading={loading}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={dataUpdated ? "updated" : "not-updated"}
                    />
                </div>
            </div>
        </Card>
    );
}

export default LeavePermissionReport