import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from 'components/ui/Button/MyButton';
import { Form, Input, Card, DatePicker, Select, Checkbox, Tooltip } from 'antd'
import { ASSET_REGISTRY } from "constants";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';
import { statusEnum, assetConditionEnum, assetRegistryStatusEnum } from "components/common/enum";
import { areValuesNotEmptyExceptKeys, disabledFutureDate} from 'components/common/validation';
import { differenceInDays } from 'date-fns';

const AddEditAssetRegistry = () => {
    const companyid = sessionStorage.getItem("userId")
    const [formData, setFormData] = useState({
        id: undefined,
        is_active: true,
        company_id: companyid
    });
    const [loadings, setLoadings] = useState(false);
    const prevPropsRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const { fromEdit, editDetails, locationDetails, filterDatas, isActive, isAutoGen } = location.state || {};
    const [form] = Form.useForm();
    const { assetCityList, assetBlockList, assetRoomList, assetFloorList, } = locationDetails;
    const { assetCategoryList, assetSubCategoryList, asseTypeList } = filterDatas;
    const dateFormat = 'YYYY-MM-DD';
    const [purchaseDateValue, setPurchaseDate] = useState(null);
    const [warrentyExpiredValue, setWarrentyExpiredDate] = useState(null);
    const [remainingPriodValue, setremainingPriodValue] = useState(null);
    const hideCursorRef = useRef({});

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                asset_id: editDetails.asset_id ? editDetails.asset_id : undefined,
                asset_name: editDetails.asset_name ? editDetails.asset_name : undefined,
                category: editDetails.category_id ? editDetails.category_id : undefined,
                sub_category: editDetails.sub_category_id ? editDetails.sub_category_id : undefined,
                asset_type: editDetails.asset_type_id ? editDetails.asset_type_id : undefined,
                condition: editDetails.condition ? editDetails.condition : undefined,
                asset_status: editDetails.asset_status ? editDetails.asset_status : undefined,
                asset_barcode_no: editDetails.asset_barcode_no ? editDetails.asset_barcode_no : undefined,
                model_no: editDetails.model_no ? editDetails.model_no : undefined,
                model_name: editDetails.model_name ? editDetails.model_name : undefined,
                branch: editDetails.branch ? editDetails.branch : undefined,
                city: editDetails.city_id ? editDetails.city_id : undefined,
                block: editDetails.block_id ? editDetails.block_id : undefined,
                floor: editDetails.floor_id ? editDetails.floor_id : undefined,
                room: editDetails.room_id ? editDetails.room_id : undefined,
                asset_specification: editDetails.asset_specification ? editDetails.asset_specification : undefined,
                purchase_date: editDetails.purchase_date ? dayjs(editDetails.purchase_date, dateFormat) : undefined,
                purchase_value: editDetails.purchase_value ? editDetails.purchase_value : undefined,
                installation_date: editDetails.installation_date ? dayjs(editDetails.installation_date, dateFormat) : undefined,
                warranty_expired_on: editDetails.warranty_expired_on ? dayjs(editDetails.warranty_expired_on, dateFormat) : undefined,
                equipment_ref_number: editDetails.equipment_ref_number ? editDetails.equipment_ref_number : undefined,
                remaining_period: editDetails.remaining_period ? calculateRemainingPeriod(dayjs(editDetails.purchase_date, dateFormat),dayjs(editDetails.warranty_expired_on, dateFormat)): undefined,
                depreciation_value: editDetails.depreciation_value ? editDetails.depreciation_value : undefined,
                last_service_date: editDetails.last_service_date ? dayjs(editDetails.last_service_date, dateFormat) : undefined,
                next_service_date: editDetails.next_service_date ? dayjs(editDetails.next_service_date, dateFormat) : undefined,
                insurance_policy_no: editDetails.insurance_policy_no ? editDetails.insurance_policy_no : undefined,
                insurance_name: editDetails.insurance_name ? editDetails.insurance_name : undefined,
                insurance_from_date: editDetails.insurance_from_date ? dayjs(editDetails.insurance_from_date, dateFormat) : undefined,
                insurance_to_date: editDetails.insurance_to_date ? dayjs(editDetails.insurance_to_date, dateFormat) : undefined,
                insurance_company_name: editDetails.insurance_company_name ? editDetails.insurance_company_name : undefined,
                insurance_value: editDetails.insurance_value ? editDetails.insurance_value : undefined,
                is_active: editDetails.is_active,
            }));
            setPurchaseDate(editDetails.purchase_date ? dayjs(editDetails.purchase_date, dateFormat) : null);
            setWarrentyExpiredDate(editDetails.warranty_expired_on ? dayjs(editDetails.warranty_expired_on, dateFormat) : null);
            form.setFieldsValue({
                asset_id: editDetails.asset_id ? editDetails.asset_id : undefined,
                asset_name: editDetails.asset_name ? editDetails.asset_name : undefined,
                category: editDetails.category_id ? editDetails.category_id : undefined,
                sub_category: editDetails.sub_category_id ? editDetails.sub_category_id : undefined,
                asset_type: editDetails.asset_type_id ? editDetails.asset_type_id : undefined,
                condition: editDetails.condition ? editDetails.condition : undefined,
                asset_status: editDetails.asset_status ? editDetails.asset_status : undefined,
                asset_barcode_no: editDetails.asset_barcode_no ? editDetails.asset_barcode_no : undefined,
                model_no: editDetails.model_no ? editDetails.model_no : undefined,
                model_name: editDetails.model_name ? editDetails.model_name : undefined,
                branch: editDetails.branch ? editDetails.branch : undefined,
                city: editDetails.city_id ? editDetails.city_id : undefined,
                block: editDetails.block_id ? editDetails.block_id : undefined,
                floor: editDetails.floor_id ? editDetails.floor_id : undefined,
                room: editDetails.room_id ? editDetails.room_id : undefined,
                asset_specification: editDetails.asset_specification ? editDetails.asset_specification : undefined,
                purchase_date: editDetails.purchase_date ? dayjs(editDetails.purchase_date, dateFormat) : undefined,
                purchase_value: editDetails.purchase_value ? editDetails.purchase_value : undefined,
                installation_date: editDetails.installation_date ? dayjs(editDetails.installation_date, dateFormat) : undefined,
                warranty_expired_on: editDetails.warranty_expired_on ? dayjs(editDetails.warranty_expired_on, dateFormat) : undefined,
                equipment_ref_number: editDetails.equipment_ref_number ? editDetails.equipment_ref_number : undefined,
                remaining_period: editDetails.remaining_period ? calculateRemainingPeriod(dayjs(editDetails.purchase_date, dateFormat), dayjs(editDetails.warranty_expired_on, dateFormat)) : undefined,
                depreciation_value: editDetails.depreciation_value ? editDetails.depreciation_value : undefined,
                last_service_date: editDetails.last_service_date ? dayjs(editDetails.last_service_date, dateFormat) : undefined,
                next_service_date: editDetails.next_service_date ? dayjs(editDetails.next_service_date, dateFormat) : undefined,
                insurance_policy_no: editDetails.insurance_policy_no ? editDetails.insurance_policy_no : undefined,
                insurance_name: editDetails.insurance_name ? editDetails.insurance_name : undefined,
                insurance_from_date: editDetails.insurance_from_date ? dayjs(editDetails.insurance_from_date, dateFormat) : undefined,
                insurance_to_date: editDetails.insurance_to_date ? dayjs(editDetails.insurance_to_date, dateFormat) : undefined,
                insurance_company_name: editDetails.insurance_company_name ? editDetails.insurance_company_name : undefined,
                insurance_value: editDetails.insurance_value ? editDetails.insurance_value : undefined,
                is_active: editDetails.is_active,
            });
            prevPropsRef.current = editDetails;
        }
    }, [editDetails, form, formData])

    const handleChange = (name, value) => {
        let newState = { ...formData };
        if (name === "category") {
            newState = {
                ...newState,
                category: value,
                sub_category: ""
            };
        }  
        newState[name] = value;  
        setFormData(newState);
        form.setFieldsValue(newState);
    };

    const handleBackCancel = useCallback(() => {
        navigate('/assetmanagement/assetregistry');
    }, [navigate])

    const handleSubmit = useCallback((isDraftClked) => {
        let methods = fromEdit ? "PUT" : "POST";
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoadings(true)

            let updatedFormData = { ...formData,
                purchase_date: formData.purchase_date ? dayjs(formData.purchase_date, 'DD-MM-YYYY').format(dateFormat) : null,
                installation_date: formData.installation_date ? dayjs(formData.installation_date, 'DD-MM-YYYY').format(dateFormat) : null,
                warranty_expired_on: formData.warranty_expired_on ? dayjs(formData.warranty_expired_on, 'DD-MM-YYYY').format(dateFormat) : null,
                last_service_date: formData.last_service_date ? dayjs(formData.last_service_date, 'DD-MM-YYYY').format(dateFormat) : null,
                next_service_date: formData.next_service_date ? dayjs(formData.next_service_date, 'DD-MM-YYYY').format(dateFormat) : null,
                insurance_from_date: formData.insurance_from_date ? dayjs(formData.insurance_from_date, 'DD-MM-YYYY').format(dateFormat) : null,
                insurance_to_date: formData.insurance_to_date ? dayjs(formData.insurance_to_date, 'DD-MM-YYYY').format(dateFormat) : null,
                remaining_period: formData.remaining_period ? remainingPriodValue : null
            };
            if (isDraftClked) {
                updatedFormData = { ...updatedFormData, is_draft: true };
            } else {
                updatedFormData = { ...updatedFormData, is_draft: false };
            }
            fetch(ASSET_REGISTRY, {
                method: methods,
                headers: {
                    'Authorization': `token ${authtoken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleBackCancel();
                        setLoadings(false);
                        setFormData({
                            asset_id: undefined,
                            asset_name: undefined,
                            category: undefined,
                            sub_category: undefined,
                            asset_type: undefined,
                            condition: undefined,
                            asset_status: undefined,
                            asset_barcode_no: undefined,
                            model_no: undefined,
                            model_name: undefined,
                            branch: undefined,
                            city: undefined,
                            block: undefined,
                            floor: undefined,
                            room: undefined,
                            asset_specification: undefined,
                            purchase_date: undefined,
                            purchase_value: undefined,
                            installation_date: undefined,
                            warranty_expired_on: undefined,
                            equipment_ref_number: undefined,
                            remaining_period: undefined,
                            depreciation_value: undefined,
                            last_service_date: undefined,
                            next_service_date: undefined,
                            insurance_policy_no: undefined,
                            insurance_name: undefined,
                            insurance_from_date: undefined,
                            insurance_to_date: undefined,
                            insurance_company_name: undefined,
                            insurance_value: undefined,
                            is_active: true,
                            company_id: companyid
                        })
                    }
                    else if (data.status === "fail") {
                        setLoadings(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoadings(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [formData, fromEdit, companyid, handleBackCancel]);
    
    const handleKeyChange = (e) => {
        // Allow only numbers, backspace, and delete keys
        if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
            e.preventDefault();
        }
    }

    const renderInput = (label, statekey, maxLength = 150, isReadOnly = false, inputType = 'text', testID = '', required = false) => {
        return (
            <div>
            <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span>: null}</div>
            <Input
                data-testid={testID}
                autoComplete='off'
                type={"text"}
                name={statekey}
                value={formData[statekey]}
                maxLength={maxLength}
                disabled={isReadOnly}
                onChange={(e) => handleChange(e.target.name,inputType === 'number' ?  +e.target.value : e.target.value)}
                onKeyDown={inputType === 'number' ? handleKeyChange : null}
                style={styles.textField}
            />
            </div>
        )
    }

    const onFromChange = (value) => {
        form.setFieldValue("remaining_period", "")
        setFormData(prevFormData => ({
            ...prevFormData,
            purchase_date: value
        }));
        form.setFieldValue("purchase_date", value);
        setPurchaseDate(value);
        if (warrentyExpiredValue && value) {
            calculateRemainingPeriod(value, warrentyExpiredValue);
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                remaining_period: ""
            }));
            form.setFieldValue("remaining_period", "")
        }
        if (!value) {
            setFormData(prevFormData => ({
                ...prevFormData,
                installation_date: "",
                warranty_expired_on: "",
                last_service_date: "",
                next_service_date: "",
                insurance_from_date: "",
                insurance_to_date: "",
            }));
        }
    };

    const onToChange = (value) => {
        form.setFieldValue("remaining_period", "")
        setFormData(prevFormData => ({
            ...prevFormData,
            warranty_expired_on: value
        }));
        form.setFieldValue("warranty_expired_on", value);
        setWarrentyExpiredDate(value);
        if (purchaseDateValue && value) {
            calculateRemainingPeriod(purchaseDateValue, value);
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                remaining_period: ""
            }));
            form.setFieldValue("remaining_period", "")
        }
    };

    const calculateRemainingPeriod = (start, end) => {
        if (dayjs(end).isAfter(dayjs(), 'day')) {
            let endDateTime = new Date(end);
            let currentDateTime = new Date();
    
            let yearsDiff = endDateTime.getFullYear() - currentDateTime.getFullYear();
            let monthsDiff = endDateTime.getMonth() - currentDateTime.getMonth();
            let daysDiff = endDateTime.getDate() - currentDateTime.getDate();
            
            const setDaysDif = differenceInDays(new Date(end), new Date());
            setremainingPriodValue(setDaysDif);
    
            if (daysDiff < 0) {
                let daysInMonth = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth() + 1, 0).getDate();
                daysDiff = daysInMonth - currentDateTime.getDate() + endDateTime.getDate();
                monthsDiff--;
            }
            if (monthsDiff < 0) {
                monthsDiff += 12;
                yearsDiff--;
            }
    
            let remainingPeriod = [];
            if (yearsDiff > 0) {
                remainingPeriod.push(`${yearsDiff} year${yearsDiff > 1 ? 's' : ''}`);
            }
            if (monthsDiff > 0) {
                remainingPeriod.push(`${monthsDiff} month${monthsDiff > 1 ? 's' : ''}`);
            }
            if (daysDiff > 0) {
                remainingPeriod.push(`${daysDiff} day${daysDiff > 1 ? 's' : ''}`);
            }
    
            let formattedRemainingPeriod = remainingPeriod.join(' ');
    
            setFormData(prevFormData => ({
                ...prevFormData,
                remaining_period: formattedRemainingPeriod
            }));
            form.setFieldValue("remaining_period", formattedRemainingPeriod);
    
            return formattedRemainingPeriod;
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                remaining_period: ""
            }));
            form.setFieldValue("remaining_period", "");
            return "";
        }
    };

    const disabledDateFrom = (restrictionDate) => (current) => {
        if (restrictionDate) {
            const startDate = dayjs(restrictionDate);
            return current && current < startDate.startOf('day');
        }
        return current && current > dayjs().endOf('day');
    };

    const renderDatePicker = (label, statekey, testID = "", required = false, valueOnChange) => {
        return (
            <div>
            <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span>: null}</div>
            <DatePicker
                data-testid={testID}
                style={styles.textField}
                name={statekey}
                value={formData[statekey] ? formData[statekey] : ""}
                format={"DD-MM-YYYY"}
                onChange={valueOnChange ? valueOnChange : (date) => handleChange(statekey, date)}
                allowClear={true}
                inputReadOnly={true}
                locale={locale}
                disabledDate={statekey === "next_service_date" ?
                    disabledDateFrom(formData.last_service_date) :
                    statekey === "purchase_date" ? disabledFutureDate :
                    disabledDateFrom(formData.purchase_date)}
                    disabled={statekey !== "purchase_date" && statekey !== "next_service_date" && statekey !== "insurance_to_date" ? formData.purchase_date ? false : true
                        : statekey === "next_service_date" ? formData.last_service_date ? false : true
                            : statekey === "insurance_to_date" ? formData.insurance_from_date ? false : true
                                : false}
                placeholder=""
                />
            </div>
        )
    }

    const renderSelect = (
        label,
        statekey,
        list = statusEnum,
        showKey = "text",
        valueKey = "value",
        testID = '',
        required = false,
        isDisabled = false
    ) => {
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div>
            <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span>: null}</div>
            <Select
                style={styles.textField}
                value={formData[statekey] ? formData[statekey] : undefined}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                data-testid={testID}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled = {isDisabled}
                allowClear
                ref={hideCursorRef.current[statekey]}
                onSelect={() => {
                    hideCursorRef.current[statekey].current.blur();
                }} 
            >
                {list.map((option) => {
                    const shouldRender =
                        (statekey === "sub_category" && option.asset_category_id === formData.category && (option.id === formData[statekey] || option.is_active)) ||
                        (statekey !== "sub_category" && valueKey === "id" && (option.id === formData[statekey] || option.is_active)) ||
                        (valueKey !== "id");
                    return shouldRender ? (
                        <Select.Option key={option[valueKey]} value={option[valueKey]}>
                            {option[showKey]}
                        </Select.Option>
                    ) : null;
                })}
            </Select>
            </div>
        );
    };

    const handleSave = useCallback(() => {
        handleSubmit(false);
    }, [handleSubmit]);

    const handleSaveAsDraft = useCallback(() => {
        form
            .validateFields(['category'])
            .then(() => {
                handleSubmit(true);
            })
            .catch(() => {
                ToastMsg("error", "Please select a category before saving as draft.");
            });
    }, [form, handleSubmit]);

    const keysToExclude = ['is_active', 'company_id'];
    const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

    return (
        <Card className='h-full overflow-y-auto'>
            <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
                <div className='flex items-center'>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <p className='common_addTltTxt' style={{ marginRight: "0.625vw" }}>{fromEdit ? "Edit Asset" : "Add Asset"} </p>
                </div>
                <Checkbox
                    checked={formData.is_active}
                    disabled = {fromEdit ? !isActive : true }
                    onChange={(e) => handleChange("is_active", e.target.checked)}
                >
                    Active
                </Checkbox>
            </div>
            <div className='h-5/6' style={{ margin: "1.8vw"}}>
                <Form
                    layout="inline"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    className='w-full'
                    name="basicform"
                    onFinish={handleSave}
                    onSubmit={(e) => e.preventDefault()}
                    data-testid={'astreg_form'}
                >
                    <div className={`w-full grid ${((fromEdit && isAutoGen) || !isAutoGen) ? 'grid-rows-6' : 'grid-rows-5'} grid-cols-3 gap-5 mb-6 pb-6 border-b`}>
                        {((fromEdit && isAutoGen) || !isAutoGen)?
                         <Form.Item
                            name="asset_id"
                            rules={[{ required: true, message: "This field is required" }]}>
                            {renderInput('Asset ID', "asset_id", 20, ((fromEdit && isAutoGen) || isAutoGen), 'text', 'astreg_in_astid', true)}
                        </Form.Item> : null}
                        <Form.Item
                            name="asset_name"
                            rules={[{ required: true, message: "This field is required" }]}>
                            {renderInput('Asset Name', "asset_name", 50, false, 'text', 'astreg_in_astname', true)}
                        </Form.Item>
                        <Form.Item
                            name="category"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Category", "category", assetCategoryList, "category_name", "id", 'astreg_in_cat', true)}
                        </Form.Item>
                        <Form.Item
                            name="sub_category"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Sub Category", "sub_category", assetSubCategoryList, "asset_sub_category_name", "id", 'astreg_in_subcat', true, formData.category ? false : true)}
                        </Form.Item>
                        <Form.Item
                            name="asset_type"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Asset Type", "asset_type", asseTypeList, "asset_type_name", "id", 'astreg_in_asttype', true)}
                        </Form.Item>
                        <Form.Item
                            name="condition"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Condition", "condition", assetConditionEnum, "text", "value", 'astreg_in_con', true)}
                        </Form.Item>
                        <Form.Item
                            name="asset_status"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Status", "asset_status", assetRegistryStatusEnum, "text", "value", 'astreg_in_status',true)}
                        </Form.Item>
                        <Form.Item
                            name="asset_barcode_no">
                            {renderInput('Asset Barcode/No', "asset_barcode_no", 20, false, 'text', 'astreg_in_barcode')}
                        </Form.Item>
                        <Form.Item
                            name="model_no">
                            {renderInput('Model No', "model_no", 30, false, 'text', "astreg_in_modno")}
                        </Form.Item>
                        <Form.Item
                            name="model_name"> {
                                renderInput('Model Name', "model_name", 30, false, 'text', "astreg_in_modname")}
                        </Form.Item>
                        <Form.Item
                            name="branch">
                            {renderInput('Branch', "branch", 30, false, 'text', 'astreg_in_branch')}
                        </Form.Item>
                        <Form.Item
                            name="city"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("City", "city", assetCityList, "city_name", "id", 'astreg_in_city', true)}
                        </Form.Item>
                        <Form.Item
                            name="block"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Block", "block", assetBlockList, "block", "id", 'astreg_in_block', true)}
                        </Form.Item>
                        <Form.Item
                            name="floor"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Floor", "floor", assetFloorList, "floor", "id", 'astreg_in_floor', true)}
                        </Form.Item>
                        <Form.Item
                            name="room"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {renderSelect("Room", "room", assetRoomList, "room", "id", 'astreg_in_room', true)}
                        </Form.Item>
                        <Form.Item
                            name="asset_specification">
                            {renderInput('Asset Specification', "asset_specification", 100, false, 'text', 'astreg_in_spec')}
                        </Form.Item>
                    </div>
                    <p id="common_admin_orgTxt" className='pb-3'>Purchase Details</p>
                    <div className='w-full grid grid-rows-3 grid-cols-3 gap-5 mb-6 pb-6 border-b'>
                        <Form.Item
                            name="purchase_date"
                        >
                            {renderDatePicker("Purchase Date", "purchase_date", 'astreg_in_purd', false, onFromChange)}
                        </Form.Item>
                        <Form.Item
                            name="purchase_value">
                            {renderInput('Purchace Value', "purchase_value", 7, false, 'number', 'astreg_in_purv')}
                        </Form.Item>
                        <Form.Item
                            name="installation_date"
                        >
                            {renderDatePicker("Installation Date", "installation_date", 'astreg_installd')}
                        </Form.Item>
                        <Form.Item
                            name="warranty_expired_on"
                        >
                            {renderDatePicker("Warranty Expired Date", "warranty_expired_on", 'astreg_in_wartyd', false, onToChange)}
                        </Form.Item>
                        <Form.Item
                            name="equipment_ref_number">
                            {renderInput('Equipment Reference No', "equipment_ref_number", 30, false, 'text', 'astreg_in_eqrefno')}
                        </Form.Item>
                        <Form.Item
                            name="remaining_period">
                            {renderInput('Warranty Remaining Period', "remaining_period", 9, true, 'text', 'astreg_in_rmprd')}
                        </Form.Item>
                        <Form.Item
                            name="depreciation_value">
                            {renderInput('Depreciation Value / Month', "depreciation_value", 9, false, 'number', 'astreg_in_depv')}
                        </Form.Item>
                        <Form.Item
                            name="last_service_date"
                        >
                            {renderDatePicker("Last Service Date", "last_service_date", 'astreg_lsr_servd')}
                        </Form.Item>
                        <Form.Item
                            name="next_service_date"
                        >
                            {renderDatePicker("Next Service Date", "next_service_date", 'astreg_nxt_servd')}
                        </Form.Item>
                    </div>
                    <p id="common_admin_orgTxt" className='pb-3'>Insurance Details</p>
                    <div className='w-full grid grid-rows-2 grid-cols-3 gap-5 mb-6 pb-6 border-b'>
                        <Form.Item
                            name="insurance_policy_no">
                            {renderInput('Policy No', "insurance_policy_no", 10, false, 'number', 'astreg_policy_no')}
                        </Form.Item>
                        <Form.Item
                            name="insurance_name">
                            {renderInput('Insured Name', "insurance_name", 50, false, 'text', 'astreg_ins_name')}
                        </Form.Item>
                        <Form.Item
                            name="insurance_from_date"
                        > {
                                renderDatePicker("Insurance From Date", "insurance_from_date", "astreg_ins_frmd")}
                        </Form.Item>
                        <Form.Item
                            name="insurance_to_date"
                        >
                            {renderDatePicker("Insurance To Date", "insurance_to_date", "astreg_ins_tod")}
                        </Form.Item>
                        <Form.Item
                            name="insurance_company_name">
                            {renderInput("Insurance Company Name", "insurance_company_name", 50, false, 'text', 'astreg_ins_cname')}
                        </Form.Item>
                        <Form.Item
                            name="insurance_value">
                            {renderInput('Insurance Value', "insurance_value", 7, false, 'number', 'astreg_insv')}
                        </Form.Item>
                    </div>
                    <div className='w-full mb-3'>
                        <Form.Item >
                            <div className='flex justify-end items-end mr-2'>
                                <MyButton htmlType="button" label="Cancel" onClick={handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                                {fromEdit ? null
                                    : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loadings} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='asgreg_btn_draft' />}
                                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} testID='asgreg_btn_save' />
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Card>
    );
}
const styles = {
    textField: {
        width: "23vw"
    },
}
export default AddEditAssetRegistry