import { DatePicker, Form, Input, Select, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/en_US';
import { getEmployeeApprover } from 'hooks/api/employeeApi/getEmployeeResignation';
import { getEmployeeDesignation } from 'hooks/api/masterApi/employeeDesignationApi';
import { getEmployee } from 'hooks/api/employeeApi/employeeInformationApi';
import MyButton from 'components/ui/Button/MyButton';
import { disabledPastDate } from 'components/common/validation';

const AssetsHandover = ({ formData, setFormData, triggerNext, triggerPrevious }) => {
    const dateFormat = "YYYY-MM-DD";
    const [Approver, setApprover] = useState([]);
    const [dropdownList, setDropDownList] = useState({
        designationList: [],
        employeeDetailsList: []
    });
    const [filteredHandovers, setFilteredHandovers] = useState([]);
    const [isMandatory, setIsMandatory] = useState(false);
    const dataSource = [...formData.assetDetailsList];
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const [empList, setempList] = useState([]);
    const hideCursorRef = useRef([]);

    useEffect(() => {
        clearState();
        getApproverList();
        getEmployeeDesignationList();
        getEmployeeNameList();
    }, []);

     //api calls starts
  const employee = async () => {
    setempList([])
    try {
      const apiData = await getEmployee(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setempList([...apiData.data])
      } else {
        setempList([])
      }
    } catch (error) {
      setempList([])
    }
    };
    useEffect(() => {
        employee();    
    }, []);

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            returnDate: formData?.handoverDetails?.return_date ? dayjs(formData?.handoverDetails?.return_date, dateFormat) : undefined,
            designation: formData?.handoverDetails?.designation,
            handoverTo: formData?.handoverDetails?.handover_to,
            handoverComments: formData?.handoverDetails?.comments,
            approvedDate: formData?.handoverDetails?.approved_date ? dayjs(formData?.handoverDetails?.approved_date, dateFormat) : undefined,
            approvedBy: formData?.handoverDetails?.approved_by,
            approverComments: formData?.handoverDetails?.approved_comments,
        }));
    }, [formData.handoverDetails]);

    useEffect(() => {
        if (Object.entries(formData).length > 0 && formData !== undefined) {
            form.setFieldsValue({
                ...formData,
                returnDate: formData?.returnDate,
                designation: formData?.designation ? formData?.designation : undefined,
                handoverTo: formData?.handoverTo ? formData?.handoverTo : undefined,
                handoverComments: formData?.handoverComments,
                approvedDate: formData?.approvedDate,
                approvedBy: formData?.approvedBy,
                approverComments: formData?.approverComments,
            })
        }
    }, [formData])

    const clearState = () => {
        setFormData({
            ...formData,
            selectedassetDetails: [],
            returnDate: undefined,
            designation: undefined,
            handoverTo: undefined,
            handoverComments: "",
            approvedDate: undefined,
            approvedBy: undefined,
            approverComments: "",
        });
    };

    const getEmployeeDesignationList = async () => {
        try {
            const apiData = await getEmployeeDesignation(`drop_down=True`);
            const designationList = apiData?.status === "success" && apiData?.data ? apiData.data : [];
            setDropDownList((prev) => ({ ...prev, designationList }));
        } catch (error) {
            setDropDownList((prev) => ({ ...prev, designationList: [] }));
        }
    };

    const getEmployeeNameList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            const activeEmployees = apiData?.status === "success" && apiData?.data
                ? apiData.data.filter((employee) => employee.is_active)
                : [];
            setDropDownList((prev) => ({ ...prev, employeeDetailsList: activeEmployees }));
            setFilteredHandovers(activeEmployees);
        } catch (error) {
            setDropDownList((prev) => ({ ...prev, employeeDetailsList: [] }));
            setFilteredHandovers([]);
        }
    };

    const getApproverList = async () => {
        setApprover([])
        try {
            const apiData = await getEmployeeApprover(`drop_down=True`);
            if (apiData.data && apiData.status === "success") {
                setApprover(apiData.data?.approver?.length > 0 ? [...apiData.data.approver] : [])
            } else {
                setApprover([])
            }
        } catch (error) {
            setApprover([])
        }
    };

    const handleChange = (name, value) => {
        const updatedFormData = {
            ...formData,
            [name]: value,
        };

        if (name === 'designation') {
            const relatedHandovers = dropdownList.employeeDetailsList.filter(handover =>
                handover.designation_id.some(desig => desig.id === value)
            );
            setFilteredHandovers(relatedHandovers);
            updatedFormData.handoverTo = "";
        } else if (name === 'handoverTo') {
            const selectedHandover = dropdownList.employeeDetailsList.find(handover => handover?.id === value);
            if (selectedHandover) {
                updatedFormData.designation = selectedHandover.designation_id[0]?.id;
            }
        }

        setFormData(updatedFormData);
        form.setFieldsValue({ updatedFormData });
    };

    const columns = [
        { title: 'Asset ID', dataIndex: 'asset_id', key: 'asset_id' },
        { title: 'Asset Name', dataIndex: 'asset_name', key: 'asset_name' },
        { title: 'Asset Issued Date', dataIndex: 'issued_date', key: 'issued_date' },
        // { title: 'Asset Return Date', dataIndex: 'return_date', key: 'return_date', render: (text) => text || '-' },
        { title: 'Asset Issued By', dataIndex: 'issued_by', key: 'issued_by' },
        // { title: 'HandOver To', dataIndex: 'handover_to_name', key: 'handover_to_name' },
        // { title: 'Approved By', dataIndex: 'approved_by_name', key: 'approved_by_name' },
    ];

    const rowSelection = {
        onSelect: (record, selected) => {
            let updatedAssets;
            if (selected) {
                updatedAssets = [
                    ...formData.selectedassetDetails,
                    { id: record.id, status: true },
                ];
                setIsMandatory(true);
            } else {
                updatedAssets = formData.selectedassetDetails.filter(
                    (item) => item.id !== record.id
                );
                if (updatedAssets.length === 0) {
                    setIsMandatory(false);
                }
            }
            setFormData({
                ...formData,
                selectedassetDetails: updatedAssets,
            });
        },

        onSelectAll: (selected, record) => {
            let updatedAssets;
            if (selected) {
                updatedAssets = record.map((item) => ({
                    id: item.id,
                    status: true,
                }));
                setIsMandatory(true);
            } else {
                updatedAssets = [];
                setIsMandatory(false);
            }
            setFormData({
                ...formData,
                selectedassetDetails: updatedAssets,
            });
        },


        getCheckboxProps: (record) => ({
            disabled: record.return_date !== null,
        }),
    };

    const handleFinish = () => {
        triggerNext()
    }

    return (
        <div className='h-5/6' style={{ margin: "16px 0px" }}>
            <Form
                form={form}
                ref={formRef}
                requiredMark={false}
                className='w-full'
                name="basicform"
                onFinish={handleFinish}
                onSubmit={(e) => e.preventDefault()}
                initialValues={true}
                data-testid='hs_form'
            >
                <div className="w-full" style={{ height: "65vh", overflowY: "scroll" }}>
                    <div style={{ border: "1px solid #cac9c9", width: "99%" }}>
                        <Table
                            rowKey={(record) => record?.id}
                            rowSelection={rowSelection}
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                        />
                    </div>
                    <div className='mt-2' style={styles.header}>Handing over Asset details</div>
                    <div style={styles.alignment}>
                        <Form.Item
                            style={styles.formItem}
                            name="returnDate"
                        >
                            <div className='commonInputTlt'>Return Date {isMandatory ? <span className='requiredTxt'>*</span> : null}</div>
                            <DatePicker
                                style={styles.textField}
                                name="returnDate"
                                locale={locale}
                                value={formData.returnDate || dayjs()}
                                format={"DD-MM-YYYY"}
                                allowClear
                                inputReadOnly
                                data-testid={'hs_return_date'}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            name="designation"
                            style={styles.formItem}
                            rules={[{ required: isMandatory, message: "This field is required" }]}
                            tooltip={{
                                title: "This field is required",
                                icon: <span style={{ color: "red" }}>*</span>,
                            }}
                        >
                            <div className='commonInputTlt'>Designation {isMandatory ? <span className='requiredTxt'>*</span> : null}</div>
                            <Select
                                style={styles.textField}
                                value={formData.designation}
                                name="designation"
                                onChange={(value) => handleChange("designation", value)}
                                data-testid={'hs_Designation'}
                            >
                                {dropdownList?.designationList.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>{option.designation_name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="handoverTo"
                            style={styles.formItem}
                            rules={[{ required: isMandatory, message: "This field is required" }]}
                            tooltip={{
                                title: "This field is required",
                                icon: <span style={{ color: "red" }}>*</span>,
                            }}
                        >
                            <div className='commonInputTlt'>Handover To{isMandatory ? <span className='requiredTxt'>*</span> : null}</div>
                            <Select
                                style={styles.textField}
                                value={formData.handoverTo}
                                name="handoverTo"
                                onChange={(value) => handleChange("handoverTo", value)}
                                data-testid={'hs_handover_to'}
                            >
                                {filteredHandovers.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {`${option.title || ""} ${option.first_name || ""} ${option.last_name || ""}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='w-full'>
                        <Form.Item
                            name="handoverComments"
                            style={styles.formItem}
                            rules={[{ required: isMandatory, message: "This field is required" }]}
                            tooltip={{
                                title: "This field is required",
                                icon: <span style={{ color: "red" }}>*</span>,
                            }}
                        >
                            <div className='commonInputTlt'>Comments{isMandatory ? <span className='requiredTxt'>*</span> : null}</div>
                            <Input.TextArea
                                bordered="true"
                                className="mt-2 textarea"
                                rows={3}
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 5,
                                }}
                                maxLength={150}
                                value={formData.handoverComments ? formData.handoverComments : ""}
                                placeholder="Enter Exit interview Comments"
                                name="handoverComments"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                data-testid={'hs_comments'}
                            />
                        </Form.Item>
                    </div>
                    <div className='mt-2' style={styles.header}>Approver Details</div>
                    <div style={styles.alignment}>
                        <Form.Item
                            name="approvedDate"
                            style={styles.formItem}
                            rules={[{ required: isMandatory, message: "This field is required" }]}
                            tooltip={{
                                title: "This field is required",
                                icon: <span style={{ color: "red" }}>*</span>,
                            }}
                        >
                            <div className='commonInputTlt'>Approved Date {isMandatory ? <span className='requiredTxt'>*</span> : null}</div>
                            <DatePicker
                                style={styles.textField}
                                name="approvedDate"
                                locale={locale}
                                disabledDate={disabledPastDate}
                                value={formData.approvedDate ? formData.approvedDate : ""}
                                onChange={(date) => handleChange("approvedDate", date)}
                                format={"DD-MM-YYYY"}
                                allowClear
                                data-testid={'hs_approved_date'}
                                placeholder=''
                            />
                        </Form.Item>
                        <Form.Item
                            name="approvedBy"
                            style={styles.formItem}
                            rules={[{ required: isMandatory, message: "This field is required" }]}
                            tooltip={{
                                title: "This field is required",
                                icon: <span style={{ color: "red" }}>*</span>,
                            }}
                        >
                            <div className='commonInputTlt'>Approved By {isMandatory ? <span className='requiredTxt'>*</span> : null}</div>
                            <Select
                                style={styles.textField}
                                value={formData.approvedBy}
                                name="approvedBy"
                                onChange={(value) => handleChange("approvedBy", value)}
                                data-testid={'hs_approved_by'}
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) => {
                                    const childrenText = option.children;
                                    return typeof childrenText === 'string' &&
                                        childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                  }}
                                  ref={(el) => {
                                    hideCursorRef.current = el;
                                  }}
                                  onSelect={() => {
                                    if (hideCursorRef.current) {
                                      hideCursorRef.current.blur();
                                    }
                                  }}  
                            >   
                                 {empList.map(option => {
                                    return (
                                        <Select.Option key={option.id} value={option.id}>
                                        {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='w-full'>
                        <Form.Item
                            name="approverComments"
                            style={styles.formItem}
                            rules={[{ required: isMandatory, message: "This field is required" }]}
                            tooltip={{
                                title: "This field is required",
                                icon: <span style={{ color: "red" }}>*</span>,
                            }}
                        >
                            <div className='commonInputTlt'>Approved Comments{isMandatory ? <span className='requiredTxt'>*</span> : null}</div>
                            <Input.TextArea
                                bordered="true"
                                className="mt-2 textarea"
                                rows={3}
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 5,
                                }}
                                maxLength={150}
                                value={formData.approverComments ? formData.approverComments : ""}
                                placeholder="Enter Exit interview Comments"
                                name="approverComments"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                data-testid={'rg_approved_comments'}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className='flex justify-end items-end mr-6 mt-3'>
                    <MyButton htmlType="button" label="Back" onClick={() => triggerPrevious()} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                    <MyButton htmlType="submit" label={"Next"} marginRight={"0.625vw"} paddingX={"0 1.2vw"} testID="hs_save" />
                </div>
            </Form>
        </div>
    );
};

const styles = {
    textField: {
        width: "23vw"
    },
    formItem: {
        margin: "0.75vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    },
    header: {
        fontSize: "14px",
        fontWeight: 500
    },
    alignment: {
        display: "flex",
        flexDirection: "row"
    }
}

export default AssetsHandover;
