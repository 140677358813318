import React, { useEffect, useState, useRef } from "react";
import { Card, Space, Table, Tooltip } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import AdminFilter from "../AdminFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getNoticeBoardPagination, deleteNoticeBoardById } from "hooks/api/adminApi/noticeBoardApi";
import { ADMIN_NOTICE_BOARD } from "constants";
import dayjs from 'dayjs';
import AddEditAdminNoticeBoard from "./AddEditNoticeBoard";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { CommonDeletePopup } from 'components/common/CommonComponent';
import { checkUserPermissionFunc } from "components/common/CommonFuntion";

const initialData = {
  from_date: undefined,
  to_date: undefined,
  status: "",
};

function AdminNoticeBoard() {
  const authtoken = sessionStorage.getItem("token");
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;
  const dateFormat = 'YYYY-MM-DD';
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [departmentsList, setEDept] = useState([]);
  const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
  const tableContainerHeight = isDraft ? "77.5vh" : "72vh";

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const userPermission = checkUserPermissionFunc("administrator", "Notice Board", "SubMenu");

  /*call departments*/
  const employeeDept = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEDept([...apiData.data])
      } else {
        setEDept([])
      }
    } catch (error) {
      setEDept([])
    }
  }

  useEffect(() => {
    employeeDept();
  }, [])

  const [data, pageInfo, loading, _message, refetch] = useApiData(
    getNoticeBoardPagination,
    {
      pageNo,
      pageSize,
      query,
      searchText,
      sorting,
    }
  );

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg("error", _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
      setAddVisible(false);
    }
  };

  const handleSave = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setIsDraft(false);
    refetch(pageNo, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
  };

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    let fromDate = data.from_date ? dayjs(data.from_date).format(dateFormat) : "";
    let toDate = data.to_date ? dayjs(data.to_date).format(dateFormat) : "";
    setPageNo(1);
    refetch(
      1,
      pageSize,
      `from_date=${fromDate}&to_date=${toDate}&status=${data.status !== undefined ? data.status : ""}`,
      searchText,
      sorting
    );
    setFilterVisible(false);
  };

  const ClosePopUp = () => {
    setFilterVisible(false);
  };

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
  };

  //filter end
  const handleSearch = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : "" }&`;
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}`: "";
    queryRef.current = isDraft ? `is_draft=True` : "";
    refetch(pagination.current, pageSize, queryRef.current, searchText, sortQuery);
    setSorting(sortQuery);
  };

  const handleDeleteClicked = (id) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: id,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: undefined,
      isDeleteClicked: false
    }));

  }

  const handleDeleteBtn = (id) => {
    deleteNoticeBoardById(id, isDraft)
      .then((data) => {
        if (data.status === "success") {
          ToastMsg("success", data.message);
          isDraft === true ? handleDraft() : handleBackToHome();
        } else if (data.status === "fail") {
          ToastMsg("error", data.message);
        }
      })
      .catch((error) => {
        ToastMsg("error", error.message);
      })
      .finally(() => {
        handleCancelBtn();
      })
  }

  const columns = [
    {
      title: "Notice Title",
      dataIndex: "notice_title",
      key: "notice_title",
      sorter: true,
      width: "17.05%",
      fixed: "left",
      render: (_, record) =>
        record?.notice_title?.length > 20 ? (
          <Tooltip title={record?.notice_title}>
            {
              <span style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                {record?.notice_title?.slice(0, 20) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
            {record?.notice_title ? record?.notice_title : "-"}
          </p>
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      width: "25%",
      fixed: "left",
      render: (_, record) =>
        record?.description?.length > 30 ? (
          <Tooltip title={record?.description}>
            {
              <span style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                {record?.description?.slice(0, 30) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
            {record?.description ? record?.description : "-"}
          </p>
        ),
    },
    {
      title: "Publish Date",
      dataIndex: "published_date",
      key: "published_date",
      sorter: true,
      width: "16.66%",
      fixed: "left",
      render: (_, record) =>
        <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
          {record?.published_date ? dayjs(record?.published_date).format('DD-MM-YYYY') : "-"}
        </p>,
      align: 'center',  
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: true,
      width: "14.25%",
      fixed: "left",
      render: (_, record) =>
        <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
          {record?.status ? record?.status : "-"}
        </p>,
      align: 'center',
    },
    {
      title: "Notice by",
      dataIndex: "notice_by_name",
      key: "notice_by_name",
      sorter: true,
      width: "17.05%",
      fixed: "left",
      render: (_, record) =>
        record?.notice_by?.length > 20 ? (
          <Tooltip title={record?.notice_by}>
            {
              <span style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                {record?.notice_by?.slice(0, 20) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
            {record?.notice_by ? record?.notice_by : "-"}
          </p>
        ),
    },
  ];
  if (userPermission?.edit) {
    columns.push({
      title: "Action",
      key: "action",
      align: "center",
      width: "10.6%",
      fixed: "left",
      render: (_, record) => (
        <div className="flex flex-row justify-between items-end">
          <Space size={"middle"}>
            <IconButton
              title="Edit"
              icon={<img src={ImagePaths.edit.default} alt="Edit" />}
              onClick={() => (loading ? "" : handleEditClick(record))}
              testID={'ug_btn_edit'}
            />
            <IconButton
              title="Delete"
              icon={<img src={ImagePaths.delete.default} alt="Delete" />}
              onClick={() => (loading ? "" : handleDeleteClicked(record?.id))}
              testID={'ug_btn_delete'}
            />
          </Space>
        </div>
      ),
    })
  }
  const addClick = () => {
    setAddVisible(true);
    setEditVisible(false);
    setSelectedDetails({});
  };

  const handleFilter = () => {
    setFilterVisible(true);
  };

  const handleDraft = () => {
    setIsDraft(true);
    setFilterVisible(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = `is_draft=True`
    refetch(1, pageSize, queryRef.current, searchText, "");
  };

  const handleBackToHome = () => {
    setIsDraft(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1, pageSize, queryRef.current, searchText, "");
  };

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search);
    setReorderedColumns(reorderedList);
  };

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&from_date=" + (filterDatas.from_date ? dayjs(filterDatas.from_date).format(dateFormat) : "") +
    "&to_date=" + (filterDatas.to_date ? dayjs(filterDatas.to_date).format(dateFormat) : "") +
    "&status=" + (filterDatas.status !== undefined ? filterDatas.status : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"} >
        <CommonTblHeader
          pageName={"Notice Board"}
          addVisible={addVisible}
          editVisible={editVisible}
          pageInfo={pageInfo}
          handleSearch={handleSearch}
          selectedDetails={selectedDetails}
          addClick={addClick}
          onStateChange={handleStateChanges}
          handleFilter={handleFilter}
          columns={columns}
          handleDraft={handleDraft}
          isDraft={isDraft}
          handleBackToHome={handleBackToHome}
          url={ADMIN_NOTICE_BOARD}
          query={queryString}
          isAddRequired = {userPermission?.add ? true : false}
          isDraftRequired = {userPermission?.draft ? true : false}
          testID={'nb_btn_add'}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center mt-1">
        {filterVisible ? (
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <AdminFilter
              pageName={"NoticeBoard"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter}
            />
          </div>
        ) : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns.filter(column => column.key !== 'status' || !isDraft)}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
        />
        </div>
      </div>
      {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
      {(addVisible || (editVisible && Object.keys(selectedDetails).length > 0)) ?
        <AddEditAdminNoticeBoard
          editDetails={selectedDetails}
          onHide={handleCancel}
          onSave={handleSave}
          fromEdit={editVisible}
          departmentsList={departmentsList?.length > 0 ? departmentsList : []}
          isActive = {userPermission?.active !== undefined ? userPermission?.active : true}
        />
        : null}
    </Card>
  );
}

export default AdminNoticeBoard;