import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from "components/ui/Button/MyButton";
import { Card, Form, Input, Checkbox, Tooltip, Upload, Button, DatePicker } from "antd";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { MYPLANS } from "constants";
import { noBlankSpacesValidator } from "components/common/validation";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/en_US';

export const UpdatePlan = () => {
  const [formData, setFormData] = useState({
    requested_date: dayjs(),
    current_plan: "",
    user_count: null,
    user_cost: null,
    requested_by: "",
    comments: "",
    billCycle: "",
  });

  const [loading, setLoading] = useState(false);
  const prevPropsRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { editDetails , billCycle , type , current_plan} = location.state || {};
  const [form] = Form.useForm();

  useEffect(() => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        current_plan: current_plan ? current_plan : "",
        user_cost: billCycle === "monthly" ? editDetails?.price_per_month : editDetails?.price_per_year ,  
        billCycle: billCycle,  
        requested_by : sessionStorage.getItem("employee_name"),
        requested_date: dayjs(),
      }));
      form.setFieldsValue({
        requested_date: dayjs(),
        current_plan: editDetails.current_plan ? editDetails.current_plan : "",
        user_cost: editDetails.user_cost ? editDetails.user_cost : "",  
        requested_by : sessionStorage.getItem("employee_name"),  
      });
      prevPropsRef.current = editDetails;
    
  }, [editDetails, form, formData])

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    form.setFieldsValue({
      ...formData,
      [name]: value,
    });
  };
  
  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false, disabled = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input
        autoComplete='off'
        style={styles.textField}
        disabled = {disabled}
        type={"text"}
        name={statekey}
        value={formData[statekey]}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testID={testID}
      />
      </>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/myplans');
  }, [navigate, form])

  const handleSubmit = useCallback(() => {
    let methods = (type === "upgrade") ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      updatedFormData.requested_date = updatedFormData.requested_date ? dayjs(updatedFormData.requested_date, "DD-MM-YYYY").format("YYYY-MM-DD") : null;
      fetch(MYPLANS, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
                requested_date: "",
                current_plan: "",
                user_count: null,
                user_cost:null,
                requested_by: "",
                comments: "",
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, handleBackCancel]);

  return (
    <Card className='h-full '>
        <div><label className="common_add_tltTxt m-3">{type === "upgrade" ? "Change Plan" : "Add User" }</label></div>
        <div className="grid grid-cols-3">
       <Card
            key={editDetails.packege_name}
            className= "shadow-lg normal-plan my-1"
            style={{width:"25dvw"}}>   
            <p className="header_txt">{editDetails.packege_name}</p>
            <p className="sub_txt">{editDetails.packege_description}</p>
            <p className="text-sm mt-8 mb-2"><span className="planrs_Txt">₹{billCycle === 'monthly' ? editDetails.price_per_month : editDetails.price_per_year}</span>  Per User / {billCycle === 'monthly' ? 'Month' : 'Year'}</p>
            <Button className="plan_btn" type="button">
            {(current_plan === current_plan.packege_name || type === "usercount") ? 'Current Plan' : (current_plan.packege_name === "Standard Package" && current_plan === "Basic Package" || (editDetails?.packege_name === "Premium Package" && ( current_plan === "Basic Package" || current_plan === "Standard Package"))) ? 'Upgrade Plan' : 'Change Plan'}
            </Button>
            <ul style={{overflowY:"auto",height:"51dvh"}}>
                {editDetails.modules.map((modules, index) => (
                    <li key={index} className="mb-2">
                        <span className="flex"> <img src={ImagePaths.tick_selected.default }/><label className="ml-3" style={{textTransform:"capitalize"}}>{modules === "administrator"  ?   "Admin" :
                         modules?.replace(/_/g, " ")}</label></span>
                    </li>
                ))}
            </ul>
        </Card>
      <div className='h-5/6' style={{ margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          initialValues={formData}
          onFinish={handleSubmit}
          onSubmit={(e) => e.preventDefault()}
          data-testid='hs_form'
        >
          <div style={{ height: "460px"}}>
          <Form.Item
              style={styles.formItem}
              name="requested_date"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Requested Date <span className='requiredTxt'>*</span></div>
              <DatePicker
                style={styles.textField}
                name="date"
                value={formData.requested_date ? formData.requested_date : undefined}
                format={"DD-MM-YYYY"}
                allowClear={true}
                inputReadOnly={true}
                locale={locale}
                data-testid={'sub_from'}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              style={styles.formItem}
              name="current_plan"              
            >
              {renderInput('Existing Plan', "current_plan", 'current_plan', 10,true, true)}
            </Form.Item>
           {type === "usercount" ?
            <Form.Item
              style={styles.formItem}
              name="user_count"      
              rules={[
                { required: true, message: "This field is required" },
                { 
                  validator: (_, value) => {
                    if (!value || Number(value) > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Value must be greater than 0"));
                  }
                }
              ]}
            >
              {renderInput('Requested User Count', "user_count", 'user_count', 10, true)}
            </Form.Item> : null }
            {type === "usercount" ?
             <Form.Item 
              style={styles.formItem}
              name="user_cost"              
            >
              {renderInput('User Cost', "user_cost", 'user_cost', 10, true, true)}
            </Form.Item> : null }
            <Form.Item
              style={styles.formItem}
              name="requested_by"
            >
              {renderInput('Requested By', "requested_by", 'requested_by', 50, true, true)}
            </Form.Item>
            <Form.Item name="comments" style={styles.formItem}
            rules={[{ required: true, message: "This field is required" },
                { validator: noBlankSpacesValidator }
            ]}>
              <Input.TextArea
              bordered={true}
              className="textarea"
              rows={4}
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
              maxLength = {250}
              style={{ border: "1px solid gray" }}
              placeholder="Enter Comments"
              name="Comments"
              value = {formData.comments ? formData.comments : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  comments: e.target.value,
                });
              }}
            />
            </Form.Item>       
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Back" onClick={handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="sub_cancel" />
                <MyButton htmlType="submit" label={"Send Mail"} loading={loading} paddingX={"0 1.2vw"} testID="sub_save" />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
      </div>
    </Card>
  );
};

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default UpdatePlan;
