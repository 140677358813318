import { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getDayWiseAttendance } from "hooks/api/attendanceApi/dayWiseAttendanceApi";
import { EMPLOYEE_ATTENDANCE_DAILY } from "constants";
import dayjs from "dayjs";
import { EmployeeDetails } from "components/common/CommonComponent";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import AttendanceFilter from 'pages/attendance/AttendanceFilters';
import EditDayWiseAttendance from "./EditDayWiseAttendance";
// import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import { formatTime12Hour, formatTimeSpan } from 'components/common/validation';

const initialData = {
    employee_name: "",
    designation_name: "",
    department_name: "",
    status: ""
}

function DaywiseAttendance() {
    const query = "";
    const pageSize = 10;
    const dateFormat = "YYYY-MM-DD";
    const authtoken = sessionStorage.getItem("token");
    const date = new Date().toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');

    const [editVisible, setEditVisible] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sorting, setSorting] = useState("");
    const [selectedDetails, setSelectedDetails] = useState({});
    const [dataUpdated, setDataUpdated] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs(date, dateFormat));
    const tableContainerHeight = "72vh";
    const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
    const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));
  
    let isEditAccess = (is_super_admin || is_admin);

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    // const userPermission = checkUserPermissionFunc("attendance", "Day wise Attendance", "SubMenu");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getDayWiseAttendance, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
        date
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    const handleEditClick = (details) => {
        setSelectedDetails(details);
        if (editVisible && Object.keys(selectedDetails).length > 0) {
            ToastMsg("warning", "Please cancel the existing edit operation!")
        }
        else if (!editVisible) {
            setEditVisible(true);
            setSelectedDetails(details);
        }
    };

    const handleSearch = () => {
        setEditVisible(false)
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting,
            dayjs(selectedDate).format(dateFormat))
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery,
            dayjs(selectedDate).format(dateFormat))
        setSorting(sortQuery)
    }
    
    // Date changes start 
    let dateString = date

    // Filter the data based on the selected date
    const handleDateChange = (date) => {
        setSelectedDate(date);
        dateString = dayjs(date).format(dateFormat)
        setDataUpdated(!dataUpdated);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting,
            dateString)
    };
    // Date changes end

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `employee_id=${data.employee_name ? data.employee_name : ""}&designation=${data.designation_name ? data.designation_name : ""}&department=${data.department_name ? data.department_name : ""}&status=${data.status ? data.status : ""}`,
            searchText,
            sorting,
            dayjs(selectedDate).format(dateFormat));
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas(initialData);
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting,
            dayjs(selectedDate).format(dateFormat));
    }
    //filter end

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "id",
            key: "name",
            width: "22%",
            sorter: true,
            fixed: "left",
            render: (_, record) => {
                let { employee_details } = record;
                let empDetails = {
                    ...record,
                    title: employee_details.title ? employee_details.title : "",
                    first_name: employee_details.employee_name ? employee_details.employee_name : "",
                    last_name: "",
                    designation_id: {
                        id: employee_details?.designation?.id ? employee_details?.designation?.id : "",
                        designation_name: employee_details?.designation?.name ? employee_details?.designation?.name : ""
                    },
                    id: employee_details.employee_id,
                    profile: employee_details.profile,
                }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            sorter: true,
            width: "13%",
            render: (_, record) => (<span>{record.date ? dayjs(record.date, "YYYY-MM-DD").format("DD-MM-YYYY") : "-"}</span>),
            align: "center",
            fixed: "left",
        },
        {
            title: "Shift",
            dataIndex: "shift",
            key: "shift",
            sorter: true,
            width: "13%",
            fixed: "left",
            render: (_, record) =>
            (record?.shift?.length > 15 ?
                <Tooltip title={record?.shift}>{<span>{record?.shift?.slice(0, 14) + "..."}</span>}</Tooltip>
                : <p>{record?.shift ? record?.shift : "-"}</p>)
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            sorter: true,
            width: "12.5%",
            fixed: "left",
            render: (_, record) =>
            (record?.location?.length > 15 ?
                <Tooltip title={record?.location}>{<span>{record?.location?.slice(0, 14) + "..."}</span>}</Tooltip>
                : <p>{record?.location ? record?.location : "-"}</p>)
        },
        {
            title: "Check In",
            dataIndex: "check_in",
            key: "check_in",
            sorter: true,
            width: "13.5%",
            fixed: "left",
            render: (_, record) => {
                return (<p>{(record?.check_in && record?.check_in !== "NaT") ? formatTime12Hour(record?.check_in) : "00:00"}</p>)
            }
        },
        {
            title: "Check Out",
            dataIndex: "check_out",
            key: "check_out",
            sorter: true,
            width: "13.5%",
            fixed: "left",
            render: (_, record) => {
                return (<p>{(record?.check_out && record?.check_out !== "NaT") ? formatTime12Hour(record?.check_out) : "00:00"}</p>)
            }
        },
        {
            title: "Overtime",
            dataIndex: "overtime",
            key: "overtime",
            sorter: true,
            width: "13.5%",
            fixed: "left",
            render: (_, record) => (<span>{(record.overtime && record?.overtime !== "NaT")  ? formatTimeSpan(record.overtime): "-"}</span>),
        },

        {
            title: "Break Hours",
            dataIndex: "break_hrs",
            key: "break_hrs",
            width: "13.5%",
            sorter: true,
            fixed: "left",
            render: (_, record) => (<span>{(record.break_hrs && record?.break_hrs !== "NaT") ? formatTimeSpan(record.break_hrs) : "-"}</span>),
        },
        {
            title: "Total Working Hours",
            dataIndex: "total_working_hours",
            key: "total_working_hours",
            sorter: true,
            width: "13.5%",
            fixed: "left",
            render: (_, record) => (
                <span>
                    {(record.total_working_hours && record?.total_working_hours !== "NaT" ) ? formatTimeSpan(record.total_working_hours) : "-"}</span>),
        },
    ];

    if (isEditAccess) {
        columns.push({
            title: "Action",
            key: "action",
            align: 'center',
            width: "11.5%",
            fixed: "left",
            render: (_, record) => (
                <IconButton
                    title="Edit"
                    icon={<img src={ImagePaths.edit.default} alt="Edit" />}
                    onClick={() => (loading ? "" : handleEditClick(record))}
                />
            ),
        })
    }

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleSave = () => {
        setEditVisible(false);
        setSelectedDetails({});
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting,
            dayjs(selectedDate).format(dateFormat));
        setDataUpdated(!dataUpdated);
    };

    const handleCancel = () => {
        setEditVisible(false);
        setSelectedDetails({});
    };

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&employee_id=" + (filterDatas.employee_name ? filterDatas.employee_name : "") +
        "&designation=" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
        "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&status=" + (filterDatas.status ? filterDatas.status : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "") +
        "&date=" + (selectedDate ? dayjs(selectedDate).format(dateFormat): "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Day-wise Attendance"}
                    pageInfo={pageInfo}
                    handleSearch={handleSearch}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    url={EMPLOYEE_ATTENDANCE_DAILY} query={queryString}
                    isAddRequired={false} isDraftRequired={false}
                    isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center  mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <AttendanceFilter
                            pageName={"DaywiseAttendance"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                <Table 
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 190px)` }}
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={dataUpdated ? "updated" : "not-updated"}
                />
                </div>
            </div>
            {(editVisible && Object.keys(selectedDetails).length > 0) ?
                <EditDayWiseAttendance
                    editDetails={selectedDetails}
                    onHide={handleCancel}
                    onSave={handleSave}
                    fromEdit={editVisible}
                />
                : null}
        </Card>
    );
}

export default DaywiseAttendance;
