import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import EmployeeFilter from "../EmployeeFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getSubordinateEmployeeList } from "hooks/api/employeeApi/employeeSubordinateApi";
import { EMPLOYEE_SUBORDINATE_EMPLOYEELIST } from "constants";
import { useNavigate } from "react-router-dom";
import { EmployeeDetails } from "components/common/CommonComponent";
import dayjs from 'dayjs';
import {getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeGroup } from "hooks/api/masterApi/employeeGroupApi";

const initialData = {
    emp_sub_id: "",
    emp_sub_designation: "",
    emp_sub_department: "",
    // emp_sub_reporting_mngr: "",
    emp_sub_group: "",
    emp_sub_is_active: "",
    emp_sub_designList: [],
    emp_sub_dptlist: [],
    emp_sub_repMgrList: [],
    emp_sub_GroupList: []
}

function SubordinateHome() {
    const authtoken = sessionStorage.getItem("token");
    let query = "";
    const pageSize = 10;
    const navigate = useNavigate();

    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ ...initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const tableContainerHeight = "72vh";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const [data, pageInfo, loading, _message, refetch] = useApiData(getSubordinateEmployeeList, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    // filter start

    const getEmployeeDesignationList = async () => {
        try {
            const apiData = await getEmployeeDesignation(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_designList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_designList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                emp_sub_designList: []
            }))
        }
    }

    /*call departments*/
    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_dptlist: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_dptlist: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                emp_sub_dptlist: []
            }))
        }
    }

    /*call employee group*/
    const getEmpGrpList = async () => {
        try {
            const apiData = await getEmployeeGroup(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_GroupList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_GroupList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                emp_sub_GroupList: []
            }))
        }
    }

    // call Reporting Manager
    const getReportingManagerList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_repMgrList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    emp_sub_repMgrList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                emp_sub_repMgrList: []
            }))
        }
    };

    useEffect(() => {
        getDepartmentList();
        getEmpGrpList();
        getEmployeeDesignationList();
        getReportingManagerList();
    }, []);

    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `id=${data.emp_sub_id ? data.emp_sub_id : ""}&designation=${data.emp_sub_designation ? data.emp_sub_designation : ""}&department=${data.emp_sub_department ? data.emp_sub_department : ""}&employee_group=${data.emp_sub_group ? data.emp_sub_group : ""}&status=${data.emp_sub_is_active !== undefined ? data.emp_sub_is_active : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            emp_sub_id: "",
            emp_sub_designation: "",
            emp_sub_department: "",
            emp_sub_reporting_mngr: "",
            emp_sub_group: "",
            emp_sub_is_active: "",
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleViewClick = (record) => {
        navigate('/employee/subordinates/employeedetails', { state: { employeeDetails: record } })
    }

    const handleSearch = () => {
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "employee",
            key: "employee__employee_name",
            width: "22%",
            sorter: true,
            render: (_, record) => {
                let empDetails = {
                    ...record.employee,
                    "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
                    "id": record?.employee.employee_id
                  }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Department",
            dataIndex: "designation",
            key: "designation",
            sorter: true,
            width: "15%",
            render: (_, record) =>
            (record?.designation?.length > 20 ?
                <Tooltip title={record?.designation}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.designation?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.designation ? record?.designation : "-"}</p>)
        },
        {
            title: "Date Of Joining",
            dataIndex: "date_of_joining",
            key: "date_of_joining",
            sorter: true,
            width: "15%",
            render: (_, record) =>
            (<p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.date_of_joining ? dayjs(record?.date_of_joining).format('DD-MM-YYYY') : "-"}</p>)
        },
        {
            title: "Employee Group",
            key: "employee_group",
            dataIndex: "employee_group",
            sorter: true,
            width: "15%",
            render: (_, record) =>
                (record?.employee_group?.length > 20 ?
                    <Tooltip title={record?.employee_group}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.employee_group?.slice(0, 20) + "..."}</span>}</Tooltip>
                    : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.employee_group ? record?.employee_group : "-"}</p>),
        },
        {
            title: "Email Id",
            key: "email_id",
            dataIndex: "email_id",
            sorter: true,
            width: "15%",
            render: (_, record) =>
                (record?.email_id?.length > 20 ?
                    <Tooltip title={record?.email_id}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.email_id?.slice(0, 20) + "..."}</span>}</Tooltip>
                    : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.email_id ? record?.email_id : "-"}</p>),
        },
        {
            title: "Status",
            key: "is_active",
            dataIndex: "is_active",
            sorter: true,
            width: "9%",
            render: (_, record) =>
                record.is_active === true ? (
                    <span style={{ color: "black" }}>Active</span>
                ) : (
                    <span style={{ color: "#CACACA" }}>Inactive</span>
                ),
            align: 'center',
        },
        {
            title: "Action",
            key: "action",
            width: "9%",
            render: (_, record) => (
                <IconButton
                    title="View"
                    icon={<img src={ImagePaths.view.default} alt="View" />}
                    onClick={() => (loading ? null : handleViewClick(record ? record : null))}
                    testID={'ug_btn_edit'}
                />),
            align: 'center',
        },
    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&id=" + (filterDatas.emp_sub_id ? filterDatas.emp_sub_id : "") +
        "&designation=" + (filterDatas.emp_sub_designation ? filterDatas.emp_sub_designation : "") +
        "&department=" + (filterDatas.emp_sub_department ? filterDatas.emp_sub_department : "") +
        // "&employee=" + (filterDatas.emp_sub_reporting_mngr ? filterDatas.emp_sub_reporting_mngr : "") +
        "&employee_group=" + (filterDatas.emp_sub_group ? filterDatas.emp_sub_group : "") +
        "&status=" + (filterDatas.emp_sub_is_active !== undefined ? filterDatas.emp_sub_is_active : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"}>
                <CommonTblHeader pageName={"Subordinates"}
                    pageInfo={pageInfo} handleSearch={handleSearch}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    url={EMPLOYEE_SUBORDINATE_EMPLOYEELIST} query={queryString}
                    isAddRequired={false} isDraftRequired={false}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <EmployeeFilter
                            pageName={"Subordinates"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading={loading}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={dataUpdated ? "updated" : "not-updated"}
                    />
                </div>
            </div>
        </Card>
    );
}

export default SubordinateHome;