import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getSalarySummaryReports } from "hooks/api/reportsApi.js";
import { SALARY_SUMMARY_REPORTS } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import { toIndianCurrency } from "utils/StringUtils";
import dayjs from "dayjs";
import { removeUnderscores } from "components/common/validation.js";

const initialData = {
  emp_salsummary_month_year: new Date(),
  emp_salsummary_designation: "",
  emp_salsummary_department: "",
  emp_salsummary_employee_name: ""
}

function EmployeeSalarySummary() {
  const authtoken = sessionStorage.getItem("token");
  const pageSize = 10;

  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ ...initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const parsedDate = selectedDate ? dayjs(selectedDate) : null;
  const [query, setQuery] = useState(`month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.emp_salsummary_designation ? filterDatas.emp_salsummary_designation : ""}&department=${filterDatas.emp_salsummary_department ? filterDatas.emp_salsummary_department : ""}&employee=${filterDatas.emp_salsummary_employee_name ? filterDatas.emp_salsummary_employee_name : ""}`);
  const tableContainerHeight = "72vh";

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getSalarySummaryReports, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  useEffect(() => {
    const parsedDate = selectedDate ? dayjs(selectedDate) : null;
    const newQuery = `month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.emp_salsummary_designation ? filterDatas.emp_salsummary_designation : ""}&department=${filterDatas.emp_salsummary_department ? filterDatas.emp_salsummary_department : ""}&employee=${filterDatas.emp_salsummary_employee_name ? filterDatas.emp_salsummary_employee_name : ""}`;
    setQuery(newQuery); 
  }, [filterDatas]);

  // filter start
  const renderFilter = (data) => {
    // const parsedDate = data.emp_salsummary_month_year ? dayjs(data.emp_salsummary_month_year) : null;
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   `month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${data.emp_salsummary_designation ? data.emp_salsummary_designation : ""}&department=${data.emp_salsummary_department ? data.emp_salsummary_department : ""}&employee=${data.emp_salsummary_employee_name ? data.emp_salsummary_employee_name : ""}`,
    //   searchText,
    //   sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas({...initialData});
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   query,
    //   searchText,
    //   sorting);
  }
  //filter end

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee__employee_name",
      key: "employee__employee_name",
      width: "22%",
      sorter: true,
      fixed: 'left',
      render: (_, record) => {
        let {employee} = record;
        let empDetails = {
          ...employee,
          "first_name": employee?.employee_name ? employee?.employee_name : "",
          "profile": employee?.profile ? employee?.profile : "",
          "designation_id": employee?.designation?.name ?
            { id: employee?.designation?.id, designation_name: employee?.designation?.name } : { id: 0, designation_name: "" },
          "id": employee?.employee_id ? employee?.employee_id : ""
        }
        return <EmployeeDetails details={empDetails} />;
      }
    },
    {
      title: "Salary For",
      dataIndex: "salary_for",
      key: "salary_for",
      sorter: true,
      width: "12%",
      fixed: 'left',
      render: (_, record) => 
        (record?.salary_for !== "None,None" && record?.salary_for?.length > 10 ?
        <Tooltip title={record?.salary_for}>{<span >{record?.salary_for?.slice(0, 10) + "..."}</span>}</Tooltip>
        : <p >{record?.salary_for && record?.salary_for !== "None,None"? record?.salary_for : "-"}</p>),
    },
    {
      title: "Earnings",
      dataIndex: 'earnings',
      key: 'earnings',
      width: "18%",
      fixed: 'left',
      render: (_, record) => {
        const earningsArray = record.earnings ? Object.entries(record.earnings): [];
        return (
          earningsArray?.length > 0 ?
            <table className="nestedTbl">
              <tbody>
                {earningsArray.map(([key, value]) => {
                  let titleTxt = key ? removeUnderscores(key) : "-";
                  return (
                    <tr key={key}>
                      <td className="nestedTblColumn" width={"50%"}>{titleTxt?.length > 6 ?
                        <Tooltip title={titleTxt}>{<span className="nestedCloumnTxt">{titleTxt?.slice(0, 5) + "..."}</span>}</Tooltip>
                        : <p className="nestedCloumnTxt">{titleTxt ? titleTxt : "-"}</p>}</td>
                    <td className="nestedTblColumn" width={"50%"}>{value ? toIndianCurrency(value).replace('₹', '') : 0}</td>
                  </tr>
                )})}
              </tbody>
            </table> : "-"
        );
      },
    },
    {
      title: "Credit",
      key: "credits",
      dataIndex: "credits",
      width: "18%",
      fixed: 'left',
      render: (_, record) => {
        const creditArray = record.credits ? Object.entries(record.credits) : [];
        return (
          creditArray?.length > 0 ?
            <table className="nestedTbl">
              <tbody>
                {creditArray.map(([key, value]) => {
                  let titleTxt = key ? removeUnderscores(key) : "-";
                  return (
                    <tr key={key}>
                      <td className="nestedTblColumn" width={"50%"}>{titleTxt?.length > 7 ?
                        <Tooltip title={titleTxt}>{<span className="nestedCloumnTxt">{titleTxt?.slice(0, 6) + "..."}</span>}</Tooltip>
                        : <p className="nestedCloumnTxt">{titleTxt ? titleTxt : "-"}</p>}</td>
                    <td className="nestedTblColumn" width={"50%"}>{value ? toIndianCurrency(value).replace('₹', '') : 0}</td>
                  </tr>
                )})}
              </tbody>
            </table> : "-"
        );
      },
    },
    {
      title: "Deductions",
      dataIndex: 'deductions',
      key: 'deductions',
      width: "18%",
      fixed: 'left',
      render: (_, record) => {
        const deductionArray = record.deductions ? Object.entries(record.deductions) : [];

        return (
          deductionArray?.length > 0 ?
            <table className="nestedTbl">
              <tbody>
                {deductionArray.map(([key, value]) => {
                  let titleTxt = key ? removeUnderscores(key) : "-";
                  return (
                    <tr key={key}>
                      <td className="nestedTblColumn" width={"50%"}>{titleTxt?.length > 7 ?
                        <Tooltip title={titleTxt}>{<span className="nestedCloumnTxt">{titleTxt?.slice(0, 6) + "..."}</span>}</Tooltip>
                        : <p className="nestedCloumnTxt">{titleTxt ? titleTxt : "-"}</p>}</td>
                    <td className="nestedTblColumn" width={"50%"}>{value ? toIndianCurrency(value).replace('₹', '') : 0}</td>
                  </tr>
                )})}
              </tbody>
            </table> : "-"
        );
      },
    },
    {
      title: "Debit",
      dataIndex: 'debit',
      key: 'debit',
      width: "18%",
      fixed: 'left',
      render: (_, record) => {
        const debitArray = record.debit ? Object.entries(record.debit) : [];

        return (
          debitArray?.length > 0 ?
            <table className="nestedTbl">
              <tbody>
                {debitArray.map(([key, value]) => {
                  let titleTxt = key ? removeUnderscores(key) : "-";
                  return (
                  <tr key={key}>
                      <td className="nestedTblColumn" width={"50%"}>{titleTxt?.length > 7 ?
                        <Tooltip title={titleTxt}>{<span className="nestedCloumnTxt">{titleTxt?.slice(0, 6) + "..."}</span>}</Tooltip>
                        : <p className="nestedCloumnTxt">{titleTxt ? titleTxt : "-"}</p>}</td>
                    <td className="nestedTblColumn" width={"50%"}>{value ? toIndianCurrency(value).replace('₹', '') : 0}</td>
                  </tr>
                )})}
              </tbody>
            </table> : "-"
        );
      },
    },
    {
      title: "Take Home",
      key: "paid_salary",
      dataIndex: "paid_salary",
      align: 'right',
      sorter: true,
      width: "12%",
      fixed: 'right',
      render: (_, record) => <p>{record.paid_salary ? toIndianCurrency(record.paid_salary).replace('₹', '') : 0}</p>,
    },
  ];

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&month=" + (parsedDate ? parsedDate.month() + 1 : "") +
    "&year=" + (parsedDate ? parsedDate.year() : "") +
    "&designation=" + (filterDatas.emp_salsummary_designation ? filterDatas.emp_salsummary_designation : "") +
    "&department=" + (filterDatas.emp_salsummary_department !== undefined ? filterDatas.emp_salsummary_department : "") +
    "&employee=" + (filterDatas.emp_salsummary_employee_name !== undefined ? filterDatas.emp_salsummary_employee_name : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

   // Date changes start - Filter the data based on the selected date
   const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilterDatas({ ...filterDatas, emp_salsummary_month_year: date });
    setDataUpdated(!dataUpdated);
  };// Date changes end

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Employee Salary Summary Report"}
          pageInfo={pageInfo} handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={SALARY_SUMMARY_REPORTS} query={queryString}
          isAddRequired={false} isDraftRequired={false}
          isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
          format="MMMM YYYY" picker="month"
          isExcelRequired={false} isPrintRequired={false}
        />
      </div>
      <div className="relative h-5/6 mt-1">
        {filterVisible ?
          <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
            <ReportsFilter
              pageName={"Employee Salary Summary Report"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={"mt-5 w-full"} style={styles.fltContainer}>
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={dataUpdated ? "updated" : "not-updated"}
          />
        </div>
      </div>
    </Card>
  );
}

export default EmployeeSalarySummary;