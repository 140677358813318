import React from "react";
import { Button } from "antd";
import { ImagePaths } from "utils/ImagePath";


const MyButton = ({
  label = "",
  onClick = () => { },
  className = "",
  icon = "",
  type = "primary",
  htmlType = "button",
  loading = "",
  value = "",
  disabled = false,
  bgColor = null,
  paddingX = "0 2vw",
  marginRight = 0,
  testID = "",
  outlined = false,
  color = "",
}) => (
  <Button
    disabled={disabled}
    style={{
      background: outlined ? "transparent" : bgColor ? bgColor : "#334B49",
      border: `1px solid ${(!outlined && bgColor) ?   bgColor : "#334B49"}`,
      padding: paddingX,
      borderRadius: "0.1vw",
      boxShadow: 0,
      fontFamily: "Urbanist",
      marginRight: marginRight,
      color: outlined ? "#334B49" : color ? color : "white",
      fontSize: "1vw",
      fontWeight: 500
    }}
    htmlType={htmlType}
    icon={<img src={icon} style ={{width: '1.5dvw',marginRight: icon ? "8px" : "0px" }}/>}
    type={type}
    className={className}
    onClick={onClick}
    loading={loading}
    value={value}
    data-testid={testID}
  >
    {label}
  </Button>
);

export default MyButton;
