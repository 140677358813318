import React, { useState } from "react";
import { Card, Col, Form, Input, Row, Space, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Pattern from "components/ui/Images/Pattern";
import ChangePasswordApi from "./apihook/ChangePasswordApi";
import MySecondaryLargeButton from "components/ui/Button/MySecondaryLargeButton";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState(false);
  const { state } = useLocation();
  const authtoken = sessionStorage.getItem("token");
  const id = state ? parseInt(state.id) : 0;
  const user_name = state ? state.user_name:"";

  const onFinish = (values) => {
    setLoadings(true);
    ChangePasswordApi(
      values,
      authtoken,
      id,
      (data) => {
        //onSuccess
        setLoadings(false);
        ToastMsg("success", data.message);
        navigate('/PasswordSuccess', { state: {user_name : data.user_name } });
      },
      (error) => {
        //onError
        setLoadings(false);
        ToastMsg("error", error);
      }
    );
  }

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Pattern className="h-screen w-full pattern-text"></Pattern>
        </Col>
        <Col flex={4}>
          <div
            className="flex flex-col"
            style={{ padding: "1.875vw", height: "100vh" }}
          >
          <img src={ImagePaths.loginLogo.default} className = "login_Logo" alt={"App Logo"} />
            <div className="flex items-center justify-center my-20">
              <Form
                layout="vertical"
                name="basicform"
                onFinish={onFinish}
                onSubmit={(e) => e.preventDefault()}
                colon={false}
                requiredMark={false}
                initialValues={{ remember: true }}
              >
                <Card
                  className=" drop-shadow-lg items-center justify-center px-5"
                  bordered={false}
                  style={{ width: 400,}}
                >
                  <div className="grid grid-cols-9">
                    <div className="col-span-9">
                      <p className = "login_tlt pb-2"> Reset Your Password </p>
                      <p className="userPermission-checkbox-subTlt">Enter new password and confirm password here.</p>
                    </div>
                  </div>
                  <label className="block mt-6">
                    <Form.Item
                      name="user_name"
                      rules={[{ required: true, message: "" }]}
                      tooltip={{
                        title: "This field is required",
                        icon: <span style={{ color: "red" }}>*</span>,
                      }}
                      initialValue={user_name ? user_name:""}
                    >
                      <Input placeholder="Username"
                      readOnly
                      size = "large"
                        style={{
                          border: 0,
                          borderRadius: 0,
                          borderBottom: "1px solid",
                          paddingLeft: "0",
                        }}
                      />
                    </Form.Item>
                  </label>
                  <label className="block mt-6">
                    <Form.Item
                      name="newPassword"
                      rules={[{ required: true, message: "" }]}
                      tooltip={{
                        title: "This field is required",
                        icon: <span style={{ color: "red" }}>*</span>,
                      }}
                    >
                      <Input.Password placeholder="New Password"
                        size = "large"
                        style={{
                          border: 0,
                          borderRadius: 0,
                          borderBottom: "1px solid",
                          paddingLeft: "0",
                        }}
                      />
                    </Form.Item>
                  </label>
                  <label className="block mt-6">
                    <Form.Item
                      name="confirmPassword"
                      rules={[{ required: true, message: "" }]}
                      tooltip={{
                        title: "This field is required",
                        icon: <span style={{ color: "red" }}>*</span>,
                      }}
                    >
                      <Input.Password placeholder="Re-enter password"
                        size = "large"
                        style={{
                          border: 0,
                          borderRadius: 0,
                          borderBottom: "1px solid",
                          paddingLeft: "0",
                        }}
                      />
                    </Form.Item>
                  </label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="items-center justify-center my-2">
                      <Form.Item>
                        <MySecondaryLargeButton
                          type="primary"
                          label={loadings?"RESET PASSWORD...":"RESET PASSWORD"}
                          loading={loadings}
                          className="mt-2 items-center"
                          htmlType="submit"
                        />
                      </Form.Item>
                    </div>
                  </Space>
                </Card>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
