import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip, Space } from "antd";
import { useNavigate } from "react-router-dom";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { ROSTERING_GET } from "constants";
import {checkUserPermissionFunc} from "components/common/CommonFuntion";
import { CommonDeletePopup } from 'components/common/CommonComponent';
import dayjs from "dayjs";
import { getRosterPagination, deleteRosterById } from "hooks/api/rosterApi/rosterHomeApis";
import AssetManagementFilter from "pages/assetManagement/AssetManagementFilter";

const initialData = {
  roster_from_date: "",
  roster_to_date: "",
  roster_status: "",
}

const RosterHome = () => {
  const authtoken = sessionStorage.getItem("token");
  let query = "";
  const pageSize = 10;

  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ ...initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
  const tableContainerHeight = "72dvh";
  const navigate = useNavigate();

    useEffect(() => {
      if (!authtoken) {
        window.location.href = "/";
      }
    }, [authtoken]);

  const userPermission = checkUserPermissionFunc("Roster", null, "Menu");

  const [data, pageInfo, loading, _message, refetch] = useApiData(getRosterPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const handleAddEditClick = async (details = {}, isEditable = true) => {
    navigate('/roster/add', {
      state: {
        roster_id: details?.roster_id,
        roster_from_date: details?.from_date,
        roster_to_date: details?.to_date,
        editAccess: isEditable
      }
    });
  };

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `from_date=${data.roster_from_date ? data.roster_from_date : ""}&to_date=${data.roster_to_date ? data.roster_to_date : ""}&publish=${data.roster_status !== undefined ? data.roster_status : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas({...initialData});
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
  }
  //filter end

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const columns = [
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
      sorter: true,
      width: "18.05%",
      align: 'center',
      render: (_, record) => 
        (<span style={{ color: record.is_active ? "black" : "#CACACA" }}>
          {record?.from_date ? dayjs(record.from_date, "YYYY-MM-DD").format("DD-MM-YYYY") : "-"}</span>),
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      key: "to_date",
      sorter: true,
      width: "18.05%",
      align: 'center',
      render: (_, record) => 
        (<span style={{ color: record.is_active ? "black" : "#CACACA" }}>
          {record?.to_date ? dayjs(record.to_date, "YYYY-MM-DD").format("DD-MM-YYYY") : "-"}</span>),
    },
    {
      title: "Total Shift",
      dataIndex: "total_shift",
      key: "total_shift",
      sorter: true,
      width: "18.05%",
      align: 'center',
      render: (_, record) =>
      (record?.total_shift?.length > 20 ?
        <Tooltip title={record?.total_shift}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.total_shift?.slice(0, 20) + "..."}</span>}</Tooltip>
        : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.total_shift ? record?.total_shift : "-"}</p>)
    },
    // {
    //   title: "Department",
    //   key: "department",
    //   dataIndex: "department",
    //   sorter: true,
    //   width: "20%",
    //   render: (_, record) => {
    //       let departments = record?.department?.length > 0 ? record?.department?.filter(Boolean).join(", ") : ""
    //       return (departments?.length > 28 ?
    //       <Tooltip title={departments}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{departments?.slice(0, 25) + "..."}</span>}</Tooltip>
    //       : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{departments ? departments : "-"}</p>)}
    // },
    {
        title: "Total Employees",
        key: "total_employees",
        dataIndex: "total_employees",
        sorter: true,
        width: "16.25%",
        render: (_, record) =>
          (<p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.total_employees ? record?.total_employees : "-"}</p>),
        align: 'center',
      },
      {
        title: "Status",
        key: "is_published",
        dataIndex: "is_published",
        sorter: true,
        width: "16.45%",
        render: (_, record) => (<span style={{ color: "black" }}>{record?.is_published ? "Published" : "Saved"} </span>) ,
        align: 'center',
        fixed: "left",
      },
      {
        title: "Action",
        key: "action",
        align: 'center',
        width: "12.35%",
        render: (_, record) => {
          const tooltipContent = (
            <div className="mr-5">
              <p style ={{fontSize: "0.85dvw", fontWeight: "bold"}}>Modification Reason:</p>
              <p style ={{fontSize: "0.85dvw"}}>{record?.remarks}</p>
            </div>
          );
          return (
          <div className="flex justify-center items-center">
            <Space size={"middle"}>
              <IconButton
                title="Edit"
                icon={<img src={userPermission?.edit ? ImagePaths.edit.default : ImagePaths.view.default}
                alt={userPermission?.edit ? "Edit" : "View"} />}
                onClick={() => (loading ? "" : handleAddEditClick(record, userPermission?.edit))}
              />
              { (userPermission?.edit && !record?.is_published) ? 
                <IconButton
                  title="Delete"
                  icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                  onClick={() => (loading ? "" : handleDeleteClicked(record?.roster_id))}
                /> : 
                (record?.is_published && record?.remarks) ?
                  <IconButton
                    title={tooltipContent}
                    tooltipPostion = {"bottomRight"}
                    icon={<img src={ImagePaths.reasonIcon.default} alt="Reason" />}
                  /> : <IconButton title="" icon={<></>} />
              }
            </Space>
          </div>
        )},
      }
  ];

  //delete start
  const handleDeleteClicked = (id) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: id,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: undefined,
      isDeleteClicked: false
    }));
  }

  const handleDeleteBtn = async (id) => {
    try {
      const apiData = await deleteRosterById(id);
      if (apiData && apiData.status === "success") {
        ToastMsg("success", apiData.message);
        setPageNo(1);
        refetch(1,
          pageSize,
          query,
          searchText,
          sorting);
      } else {
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      handleCancelBtn();
    }
  } 
  //delete end

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&from_date=" + (filterDatas.roster_from_date ? filterDatas.roster_from_date : "") +
    "&to_date=" + (filterDatas.roster_to_date ? filterDatas.roster_to_date : "") +
    "&publish=" + (filterDatas.roster_status !== undefined ? filterDatas.roster_status : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Roster"}
          pageInfo={pageInfo} handleSearch={handleSearch}
          addClick={handleAddEditClick} onStateChange={handleStateChanges}
          handleFilter={handleFilter} columns={columns}
          url={ROSTERING_GET} query={queryString}
          addBtnLabel = {"New Roster"} 
          isAddRequired = {userPermission?.add ? true : false}
          isDraftRequired = {false}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <AssetManagementFilter
              pageName={"RosterHome"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table 
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={dataUpdated ? "updated" : "not-updated"}
        />
        </div>
      </div>
      {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
    </Card>
  );
}

export default RosterHome;
