import React, { useState, useCallback } from 'react';
import { Modal, DatePicker, Form } from "antd";
import MyButton from 'components/ui/Button/MyButton';
import { disabledPastDate } from "components/common/validation";
import ToastMsg from "components/common/ToastMsg";
import { useNavigate } from "react-router-dom";
import { PAYROLL_PUBLISH } from "constants";
import moment from 'moment';

const PublishPopUp = ({ payroll_id, handleCloseBack, handleSuccess }) => {
  const authtoken = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [published_date, setPublished_date] = useState(null);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const handleClose = () => {
    setPublished_date(null);
    handleCloseBack();
  }

  const handleSubmit = useCallback((values) => {
    if (payroll_id) {
      try {
        setLoading(true)
        let updatedFormData = {
          "payroll_id": parseInt(payroll_id),
          "published_date": values?.published_date ? moment(values?.published_date).format("YYYY-MM-DD HH:mm:ss") : null
        }
        fetch(PAYROLL_PUBLISH, {
          method: "PUT",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${authtoken}`
          },
          body: JSON.stringify(updatedFormData)
        })
          .then((response) => response.json())
          .then(async data => {
            if (data.status === "success") {
              setLoading(false);
              handleClose();
              handleSuccess();
              ToastMsg("success", data.message);
              navigate('/payroll/payrolldashboard');
            }
            else if (data.status === "fail") {
              ToastMsg("error", data.message);
              setLoading(false);
            }
          })
          .catch(error => {
            error.json().then(data => {
              setLoading(false);
              ToastMsg("error", data);
            });
          });
      } catch (error) {
        ToastMsg("error", error.message);
      }
    }
  }, [authtoken, handleClose, payroll_id]);

  return (
    <Modal
      centered
      open={true}
      onCancel={handleClose}
      footer={null}
      width={"20vw"}
      className="custom-modal"
    >
      <p id="common_weekoffTxt">Publish Payslip</p>
      <Form
        layout="vertical"
        form={form}
        name="basicform"
        onFinish={handleSubmit}
        onSubmit={(e) => e.preventDefault()}
        colon={false}
        requiredMark={false}
        style={{ width: "100%", height: "30vh" }}
        initialValues={true}
      >
        <Form.Item
          label="Send Payslip On"
          name="published_date"
          rules={[{ required: true, message: "This field is required" }]}
          tooltip={{
            title: "This field is required",
            icon: <span style={{ color: "red" }}>*</span>,
          }}
          className='mb-20'
        >
          <DatePicker
            allowClear
            inputReadOnly={true}
            style={{ width: "90%" }}
            name={"published_date"}
            showTime
            format={"DD-MM-YYYY HH:mm"}
            value={published_date ? published_date : null}
            onChange={(date, dateString) => setPublished_date(dateString)}
            disabledDate={disabledPastDate}
          />
        </Form.Item>
        <Form.Item >
          <div className='flex justify-end items-center  mt-20'>
            <MyButton htmlType="button" label="Back" onClick={handleClose} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
            <MyButton htmlType="submit" label="Publish" loading={loading} paddingX={"0 1.2vw"} />
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PublishPopUp