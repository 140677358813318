import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, Table, Tooltip, Space, } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import ConfigurationFilter from "../ConfigurationFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeeShiftPagination } from "hooks/api/configurationApi/employeeShiftApi";
import { EMPLOYEE_SHIFT } from 'constants';
import {checkUserPermissionFunc, deleteDraftItemsById} from "components/common/CommonFuntion";
import { CommonDeletePopup } from 'components/common/CommonComponent';

const initialData = {
  shift_code: "",
  shift_name: "",
  shift_is_active: "",
}

function EmployeeShift() {
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;
  const authtoken = sessionStorage.getItem("token");

  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
  const tableContainerHeight = isDraft ? "77.5vh" : "72vh";

  const navigate = useNavigate();

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const userPermission = checkUserPermissionFunc("configuration", "Shift Configuration", "SubMenu");

  const [data, pageInfo, loading, _message, refetch] = useApiData(getEmployeeShiftPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message, _message.errorMsgText]);

  const addClick = () => {
    setEditVisible(false);
    setSelectedDetails({});
    navigate('/configuration/employeeshift/add', { state: { fromEdit: false, editDetails: {}, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!")
    }
    else if (!editVisible) {
      setEditVisible(true);
      navigate('/configuration/employeeshift/add', { state: { fromEdit: true, editDetails: details, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
      setSelectedDetails(details);
    }
  };

  const handleSearch = () => {
    setEditVisible(false)
    setSelectedDetails({});
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    queryRef.current = isDraft ? `is_draft=True` : "";
    refetch(pagination.current,
      pageSize,
      queryRef.current,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `code=${data.shift_code ? data.shift_code : ""}&shift_name=${data.shift_name ? data.shift_name : ""}&is_active=${data.shift_is_active !== undefined ? data.shift_is_active : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
  }
  //filter end

  const columns = [
    {
      title: "Shift Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      width: "12.14%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        return (record.code?.length > 20 ?
          <Tooltip title={record.code}>{<span style={{ color: colors }}>{record.code?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{record.code ? record.code : "-"}</p>)
      }
    },
    {
      title: "Shift Name",
      dataIndex: "shift_name",
      key: "shift_name",
      sorter: true,
      width: "14.14%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        return (record.shift_name?.length > 20 ?
          <Tooltip title={record.shift_name}>{<span style={{ color: colors }}>{record.shift_name?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{record.shift_name ? record.shift_name : "-"}</p>)
      }
    },
    {
      title: "Start Timing",
      dataIndex: "shift_timings_from",
      key: "shift_timings_from",
      sorter: true,
      width: "11.14%",
      fixed: "left",
      render: (_, record) =>
        <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record.shift_timings_from ? record.shift_timings_from : "-"}</p>
    },
    {
      title: "End Timing",
      dataIndex: "shift_timings_to",
      key: "shift_timings_to",
      sorter: true,
      width: "11.14%",
      fixed: "left",
      render: (_, record) =>
        <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record.shift_timings_to ? record.shift_timings_to : "-"}</p>
    },
    {
      title: "Total Break Hrs",
      dataIndex: "total_break_hrs",
      key: "total_break_hrs",
      width: "12.14%",
      fixed: "left",
      sorter: true,
      fixed: 'left',
      render: (_, record) =>
        <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record.total_break_hrs ? record.total_break_hrs : "-"}</p>
    },
    {
      title: "Total Shift Hrs",
      dataIndex: "total_shift_hrs",
      key: "total_shift_hrs",
      width: "12.14%",
      fixed: "left",
      sorter: true,
      fixed: 'left',
      render: (_, record) =>
        <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record.total_shift_hrs ? record.total_shift_hrs : "-"}</p>
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: "11.16%",
      fixed: "left",
      sorter: true,
      fixed: 'left',
      render: (_, record) =>
      (record?.notes?.length > 28 ?
        <Tooltip title={record?.notes}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.notes?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.notes ? record?.notes : "-"}</p>)
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      sorter: true,
      width: "9%",
      render: (_, record) =>
        record.is_active === true ? (
          <span style={{ color: "black" }}>Active</span>
        ) : (
          <span style={{ color: "#CACACA" }}>Inactive</span>
        ),
      align: 'center',
      fixed: "left",
    },
  ];

  if (userPermission?.edit) {
    columns.push({
      title: "Action",
      key: "action",
      align: 'center',
      width: "7%",
      fixed: "left",
      render: (_, record) => (
        <div className="flex justify-center items-center">
          <Space size={"middle"}>
            <IconButton
              title="Edit"
              icon={<img src={ImagePaths.edit.default} alt="Edit" />}
              onClick={() => (loading ? "" : handleEditClick(record))}
              testID={'ug_btn_edit'}
            />
            {record.is_draft === true ?
              <IconButton
                title="Delete"
                icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                onClick={() => (loading ? "" : handleDeleteClicked(record?.id))}
                testID={'ug_btn_delete'}
              /> : null}
          </Space>
        </div>
      ),
    })
  }

  //draft delete start
  const handleDeleteClicked = (id) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: id,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: undefined,
      isDeleteClicked: false
    }));
  }

  const handleDeleteBtn = async (id) => {
    try {
      const apiData = await deleteDraftItemsById(id, EMPLOYEE_SHIFT);
      if (apiData && apiData.status === "success") {
        ToastMsg("success", apiData.message);
        handleDraft();
      } else {
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      handleCancelBtn();
    }
  }//draft delete end

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleDraft = () => {
    setIsDraft(true);
    setFilterVisible(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = `is_draft=True`
    refetch(1,
      pageSize,
      queryRef.current,
      searchText,
      "");
  }

  const handleBackToHome = () => {
    setIsDraft(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1,
      pageSize,
      queryRef.current,
      searchText,
      "");
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&code=" + (filterDatas.shift_code ? filterDatas.shift_code : "") +
    "&shift_name=" + (filterDatas.shift_name ? filterDatas.shift_name : "") +
    "&is_active=" + (filterDatas.shift_is_active !== undefined ? filterDatas.shift_is_active : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
      fltContainer: {
        border: "1px solid #cbcbcb",
        height: tableContainerHeight,
      },
    }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Shift"}
          pageInfo={pageInfo}
          handleSearch={handleSearch} selectedDetails={selectedDetails} addClick={addClick}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          handleDraft={handleDraft} isDraft={isDraft} handleBackToHome={handleBackToHome}
          url={EMPLOYEE_SHIFT} query={queryString}
          isAddRequired = {userPermission?.add ? true : false}
          isDraftRequired = {userPermission?.draft ? true : false}
          testID = {'sft'}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <ConfigurationFilter
              pageName={"Shift"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table 
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
        />
        </div>
      </div>
      {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
    </Card>
  );
}

export default EmployeeShift;
