import { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getPayslipPagination } from "hooks/api/payrollApi/payslipApi";
import { PAYROLL_PAY_SLIP } from "constants";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi.js";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import PayrollFilter from "./PayRollFilters";
import { EmployeeDetails } from "components/common/CommonComponent";
import { toIndianCurrency } from "utils/StringUtils";
import { downloadPDF } from "components/common/CommonFuntion";
import dayjs from "dayjs";

const initialData = {
    designation_name: "",
    department_name: "",
    employee: "",
    designationList: [],
    departmentList: [],
    employeeList: [],
    max_amount: "",
    min_amount: ""
}

function Payslip() {
    const query = "";
    const pageSize = 10;
    const authtoken = sessionStorage.getItem("token");
    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sorting, setSorting] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const tableContainerHeight = "72vh";
    const date = new Date().toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');
    const dateFormat = "YYYY-MM-DD";
    const [selectedDate, setSelectedDate] = useState(dayjs(date, dateFormat));

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const [data, pageInfo, loading, _message, refetch] = useApiData(getPayslipPagination, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
        date
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    /*call designation*/
    const getEmployeeDesignationList = async () => {
        try {
            const apiData = await getEmployeeDesignation(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                designationList: []
            }))
        }
    }

    /*call departments*/
    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    departmentList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    departmentList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                departmentList: []
            }))
        }
    }

    /*call employee api*/
    const getEmp = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    employeeList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    employeeList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                employeeList: []
            }))
        }
    }

    useEffect(() => {
        getEmployeeDesignationList();
        getDepartmentList();
        getEmp();
    }, []);

    const handleSearch = () => {
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting,
            dayjs(selectedDate).format(dateFormat));
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery,
            dayjs(selectedDate).format(dateFormat)
        );
        setSorting(sortQuery)
    }

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `employee=${data.employee ? data.employee : ""}&department=${data.department_name ? data.department_name : ""}&designation=${data.designation_name ? data.designation_name : ""}&max_amount=${data.max_amount ? data.max_amount : ""}&min_amount=${data.min_amount ? data.min_amount : ""}`,
            searchText,
            sorting,
            dayjs(selectedDate).format(dateFormat));
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            designation_name: "",
            department_name: "",
            employee: "",
            max_amount: "",
            min_amount: ""
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting,
            dayjs(selectedDate).format(dateFormat)
        );
    }
    //filter end

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "emp_detail",
            key: "emp_detail",
            width: "13%",
            sorter: true,
            fixed: "left",
            render: (_, record) => {
                let empDetails = {
                    ...record.employee,
                    "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
                    "id": record?.employee.employee_id
                }
                return (
                    <span style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}>
                        <EmployeeDetails details={empDetails} />
                    </span>
                )
            }
        },
        {
            title: "CTC",
            dataIndex: "ctc",
            key: "ctc",
            sorter: true,
            width: "8.66%",
            render: (_, record) =>
                record?.ctc?.length > 7 ? (
                    <Tooltip title={record?.ctc}>
                        {
                            <span>{record?.ctc?.slice(0, 20) + "..."}</span>
                        }
                    </Tooltip>
                ) : (
                    <p>{record?.ctc ? toIndianCurrency(record?.ctc) : "-"}</p>
                ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Earning",
            dataIndex: "earning",
            key: "earning",
            sorter: true,
            width: "8.66%",
            render: (_, record) => record?.earnings?.length > 7 ? (
                <Tooltip title={record?.earnings}>
                    {
                        <span>{record?.earnings?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.earnings ? toIndianCurrency(record?.earnings) : "-"}</p>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Deductions",
            dataIndex: "deductions",
            key: "deductions",
            sorter: true,
            width: "8.66%",
            render: (_, record) => record?.deductions?.length > 7 ? (
                <Tooltip title={record?.deductions}>
                    {
                        <span>{record?.deductions?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.deductions ? toIndianCurrency(record?.deductions) : "-"}</p>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Net Pay",
            dataIndex: "net_pay",
            key: "net_pay",
            sorter: true,
            width: "8.66%",
            render: (_, record) => record?.net_pay?.length > 7 ? (
                <Tooltip title={record?.net_pay}>
                    {
                        <span>{record?.net_pay?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.net_pay ? toIndianCurrency(record?.net_pay) : "-"}</p>
            ),
            align: "right",
        },
        {
            title: "LOP",
            dataIndex: "lop",
            key: "lop",
            sorter: true,
            width: "8.66%",
            render: (_, record) => record?.lop_amount?.length > 7 ? (
                <Tooltip title={record?.lop_amount}>
                    {
                        <span>{record?.lop_amount?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.lop_amount ? toIndianCurrency(record?.lop_amount) : "-"}</p>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Reimbursements",
            dataIndex: "reimbursements",
            key: "reimbursements_total",
            width: "10.70%",
            sorter: true,
            render: (_, record) => record?.reimbursements_total?.length > 7 ? (
                <Tooltip title={record?.reimbursements_total}>
                    {
                        <span>{record?.reimbursements_total?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.reimbursements_total ? toIndianCurrency(record?.reimbursements_total) : "-"}</p>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Credit",
            dataIndex: "credits",
            key: "credits",
            width: "8.2%",
            sorter: true,
            render: (_, record) => record?.credits?.length > 7 ? (
                <Tooltip title={record?.credits}>
                    {
                        <span>{record?.credits?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.credits ? toIndianCurrency(record?.credits) : "-"}</p>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Debit",
            dataIndex: "debit",
            key: "debit",
            width: "8.2%",
            sorter: true,
            render: (_, record) => record?.debit?.length > 7 ? (
                <Tooltip title={record?.debit}>
                    {
                        <span>{record?.debit?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.debit ? toIndianCurrency(record?.debit) : "-"}</p>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Paid Salary",
            dataIndex: "paid_salary",
            key: "paid_salary",
            width: "10.4%",
            sorter: true,
            render: (_, record) => record?.paid_salary?.length > 7 ? (
                <Tooltip title={record?.paid_salary}>
                    {
                        <span>{record?.paid_salary?.slice(0, 20) + "..."}</span>
                    }
                </Tooltip>
            ) : (
                <p>{record?.paid_salary ? toIndianCurrency(record?.paid_salary) : "-"}</p>
            ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Action",
            key: "action",
            width: "6.2%",
            render: (_, record) => (
                <IconButton
                    title="Download"
                    icon={<img src={ImagePaths.downloadPdf.default} alt="download pdf" />}
                    onClick={() => record.payslip ? downloadPDF(record.payslip) : {}}
                />
            ),
            align: "center",
            fixed: "center",
        },

    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    // Date changes start 
    let dateString = date

    // Filter the data based on the selected date
    const handleDateChange = (date) => {
        setSelectedDate(date);
        dateString = dayjs(date).format("YYYY-MM-DD");
        setDataUpdated(!dataUpdated);
        refetch(1, pageSize, query, searchText, sorting, dateString);
    };

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&employee=" + (filterDatas.employee ? filterDatas.employee : "") +
        "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&designation" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
        "&max_amount" + (filterDatas.max_amount ? filterDatas.max_amount : "") +
        "&min_amount" + (filterDatas.min_amount ? filterDatas.min_amount : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "") +
        "&payroll_month=" + (dayjs(selectedDate).format('M')) +
        "&payroll_year=" + (dayjs(selectedDate).format('YYYY'));

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Payslip"}
                    pageInfo={pageInfo}
                    handleSearch={handleSearch} url={PAYROLL_PAY_SLIP} query={queryString}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    isAddRequired={false} isDraftRequired={false}
                    isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
                    format="MMMM YYYY" picker="month"
                />
            </div>
            <div className="h-5/6 flex justify-center items-center  mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <PayrollFilter
                            pageName={"Payslip"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4 custom-table" : "mt-5 w-full custom-table"} style={styles.fltContainer}>
                <Table 
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }} 
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={dataUpdated ? "updated" : "not-updated"}
                    bordered
                />
                </div>
            </div>
        </Card>
    );
}

export default Payslip;