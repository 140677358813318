import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip, Space, DatePicker, Input, Modal, Typography } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getAdvanceSalaryApprovalPagination, handleApprovedStatusById } from "hooks/api/payrollApi/advanceSalaryApprovalApi";
import { PAYROLL_ADVANCE_SALARY_APPROVAL } from "constants";
import PayrollFilter from 'pages/payroll/PayRollFilters';
import EditPayrollAdvanceSalary from './EditPayrollAdvanceSalary';
import { useNavigate } from "react-router-dom";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import dayjs from 'dayjs';
import { EmployeeDetails } from "components/common/CommonComponent";
import { toIndianCurrency } from "utils/StringUtils";
import { disabledPastDate } from "components/common/validation";
import MyButton from "components/ui/Button/MyButton";
import locale from "antd/es/date-picker/locale/en_US";

const initialData = {
    requested_date: "",
    employee: "",
}

function PayrollAdvanceSalary() {
    const authtoken = sessionStorage.getItem("token");
    const query = "";
    const pageSize = 10;
    const [editVisible, setEditVisible] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [isReasonPopup, setReasonPopup] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState(null);
    const [isError, setError] = useState(false);
    const tableContainerHeight = "72vh";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const navigate = useNavigate();

    const userPermission = checkUserPermissionFunc("employee", "Approval Inbox", "SubMenu");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getAdvanceSalaryApprovalPagination, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `employee=${data.employee ? data.employee : ""}&date=${data.requested_date ? data.requested_date : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas(initialData);
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleSearch = () => {
        setEditVisible(false)
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const handleEditClick = (details) => {
        setEditVisible(true)
        setSelectedDetails(details);
    };

    const handleApproveStatus = (record, status) => {
        let updatedStatus = {
            id: record.advance_salary_id,
            approval_status: status,
            approval_notes: "",
            advance_requested: record?.advance_requested,
            approved_amount: record?.advance_requested,
            requested_emi_period: record?.emi_period,
            debit_from: null,
            request_type: record?.request_type
        };
        if (status === "rejected") {
            handleApprovedStatusById(updatedStatus).then((data) => {
                if (data.status === "success") {
                    ToastMsg("success", data.message);
                    handleSave();
                } else if (data.status === "fail") {
                    ToastMsg("error", data.message);
                    handleSave();
                }
            })
        }
        else {
            setReasonPopup(true)
            setUpdatedStatus(updatedStatus)
        }
    }

    const handleSubmit = () => {
        let FormData = { 
            ...updatedStatus,
            debit_from: updatedStatus.debit_from ? dayjs(updatedStatus.debit_from, 'DD-MM-YYYY').format('YYYY-MM-DD') : null, 
        };
        if (!FormData.debit_from) {
            setError(true)
        } else {
            setError(false)
            handleApprovedStatusById(FormData).then((data) => {
                if (data.status === "success") {
                    ToastMsg("success", data.message);
                    handleSave();
                } else if (data.status === "fail") {
                    ToastMsg("error", data.message);
                    handleSave();
                }
            })
        }
    }

    const handleDescriptionPopup = () => {
        return (
            <Modal
                centered
                open={isReasonPopup}
                onCancel={handleCancel}
                footer={null}
                width={"30vw"}
            >
                <div>
                    <Typography.Title className="mt-5" level={5}>Debit From<span className="ml-1" style={{ color: "red" }}>*</span></Typography.Title>
                    <DatePicker
                        allowClear
                        inputReadOnly={true}
                        name={"debit_from"}
                        value={updatedStatus.debit_from ? updatedStatus.debit_from : ""}
                        format={"DD-MM-YYYY"}
                        style={{ width: "100%" }}
                        locale={locale}
                        onChange={(date) => {
                            setUpdatedStatus({
                                ...updatedStatus,
                                debit_from: date,
                            });
                            setError(false)
                        }}
                        disabledDate={disabledPastDate}
                        placeholder=""
                    />
                    {isError ? <div style={{ fontSize: "0.85vw", color: "red" }}>This field is required</div> : null}

                    <Input.TextArea
                        autoComplete='off'
                        bordered="true"
                        className="mt-7 textarea"
                        rows={4}
                        autoSize={{
                            minRows: 3,
                            maxRows: 4,
                        }}
                        maxLength={150}
                        placeholder="Enter reason for Approval Notes for a leave Approval"
                        name="approval_notes"
                        onChange={(e) => {
                            setUpdatedStatus({
                                ...updatedStatus,
                                approval_notes: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className='flex justify-end items-end mt-3'>
                    <MyButton htmlType="button" label={"Cancel"} onClick={handleCancel} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='asgreg_btn_draft' />
                    <MyButton htmlType="button" label={updatedStatus?.approval_status === "rejected" ? "Reject" : "Approve"} onClick={handleSubmit} loading={loading} paddingX={"0 1.2vw"} bgColor={updatedStatus?.approval_status === "rejected" ? "#D94854" : "#0E862A"} />
                </div>
            </Modal>
        )
    }

    const columns = [
        {
            title: "Approval Request Date",
            dataIndex: "request_date",
            key: "date",
            sorter: true,
            width: "17%",
            render: (_, record) =>
                <p>
                    {record?.date ? dayjs(record?.date).format('DD/MM/YYYY') : "-"}
                </p>,
            align: "center",
            fixed: "left",
        },
        {
            title: "Approval Sent from",
            dataIndex: "sent_from",
            key: "employee__employee_name",
            sorter: true,
            width: "20%",
            fixed: "left",
            render: (_, record) => {
                let empDetails = {
                    ...record.employee,
                    "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
                    "id": record?.employee.employee_id
                }
                return (
                    <span style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}>
                        <EmployeeDetails details={empDetails} />
                    </span>
                )
            }
        },
        {
            title: "Request Type",
            dataIndex: "request_type",
            key: "request_type_name",
            sorter: true,
            width: "15%",
            fixed: "left",
            render: (_, record) =>
            (record?.notes?.length > 20 ?
                <Tooltip style={{textTransform:"capitalize"}} title={record?.request_type}>{<span>{record?.request_type?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p style={{textTransform:"capitalize"}}>{record?.request_type ? record?.request_type : "-"}</p>)
        },
        {
            title: "Request Notes",
            dataIndex: "request_notes",
            key: "reason",
            sorter: true,
            width: "15%",
            fixed: "left",
            render: (_, record) =>
            (record?.reason?.length > 28 ?
                <Tooltip title={record?.reason}>{<span>{record?.reason?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p>{record?.reason ? record?.reason : "-"}</p>)
        },
        {
            title: "Request Amount",
            dataIndex: "request_amount",
            key: "advance_requested",
            sorter: true,
            width: "20%",
            render: (_, record) =>
                record?.advance_requested?.length > 7 ? (
                    <Tooltip title={record?.advance_requested}>
                        {
                            <span>{record?.advance_requested?.slice(0, 20) + "..."}</span>
                        }
                    </Tooltip>
                ) : (
                    <p>{record?.advance_requested ? toIndianCurrency(record?.advance_requested) : "-"}</p>
                ),
            align: "right",
            fixed: "left",
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            width: "10%",
            fixed: "left",
            render: (_, record) => (
                <div className="flex flex-row justify-center items-center">
                    <IconButton
                        title="Request Details"
                        icon={<img src={ImagePaths.ApproveIcon.default} alt="Request Details" />}
                        onClick={() => (loading ? "" : handleEditClick(record))}
                    />
                    {/* <Space size={"middle"}> */}
                        {/* To handle the approval, we added a necessary option for approval fields, such that the reason it was hidden */}
                        {/* {userPermission?.edit ?
                            <>
                                <IconButton
                                    title="Approve"
                                    icon={<img src={ImagePaths.circleRight.default} alt="Approve" />}
                                    onClick={() => (loading ? "" : handleApproveStatus(record, (record?.request_type === "advance request" ? "approved" : "cancelled")))}
                                />
                                <IconButton
                                    title="Reject"
                                    icon={<img src={ImagePaths.circleWrong.default} alt="Reject" />}
                                    onClick={() => (loading ? "" : handleApproveStatus(record, 'rejected'))}
                                />
                            </> : null} */}
                    {/* </Space> */}
                </div>
            ),
        },
    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleBackToHome = () => {
        navigate('/employee/approvalinbox')
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting);
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }
    const handleSave = () => {
        setEditVisible(false);
        setSelectedDetails({});
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
        setReasonPopup(false)
    };

    const handleCancel = () => {
        setEditVisible(false);
        setSelectedDetails({});
        setReasonPopup(false)
    };

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&employee=" + (filterDatas.employee ? filterDatas.employee : "") +
        "&date=" + (filterDatas.requested_date ? filterDatas.requested_date : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");
        
    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Advance Salary"}
                    isAddRequired={false} isDraftRequired={false}
                    pageInfo={pageInfo} handleSearch={handleSearch} selectedDetails={selectedDetails}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    handleBackToHome={handleBackToHome} url={PAYROLL_ADVANCE_SALARY_APPROVAL} query={queryString} isBackRequired={true}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <PayrollFilter
                            pageName={"AdvanceSalary"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                <Table 
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={dataUpdated ? "updated" : "not-updated"}
                />
                </div>
            </div>
            {isReasonPopup ? handleDescriptionPopup() : null}
            {(editVisible && Object.keys(selectedDetails).length > 0) ?
                <EditPayrollAdvanceSalary
                    editDetails={selectedDetails}
                    onHide={handleCancel}
                    onSave={handleSave}
                    fromEdit={editVisible}
                    isEdit = {userPermission?.edit}
                />
                : null}
        </Card>
    );
}

export default PayrollAdvanceSalary;