import React, { useState, useEffect, useRef } from 'react';
import { Input, Select, Tooltip, DatePicker } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from 'components/ui/Button/MyButton';
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeGroup } from "hooks/api/masterApi/employeeGroupApi";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { disabledFutureDate } from "components/common/validation";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/en_US';

const ReportsFilter = ({ pageName, filterDatas, closeFilter, filteredData, clearData }) => {
    const [filterData, setFilterData] = useState({
        day_wise_date: filterDatas.day_wise_date,
        day_wise_designation: filterDatas.day_wise_designation,
        day_wise_department: filterDatas.day_wise_department,
        day_wise_employee_grp: filterDatas.day_wise_employee_grp,
        salary_process_month_year: filterDatas.salary_process_month_year,
        payroll_detail_date: filterDatas.payroll_detail_date,
        payroll_detail_designation: filterDatas.payroll_detail_designation,
        payroll_detail_department: filterDatas.payroll_detail_department,
        payroll_detail_employee_name: filterDatas.payroll_detail_employee_name,
        esi_month_year: filterDatas.esi_month_year,
        esi_designation: filterDatas.esi_designation,
        esi_department: filterDatas.esi_department,
        esi_employee_name: filterDatas.esi_employee_name,
        pf_month_year: filterDatas.pf_month_year,
        pf_designation: filterDatas.pf_designation,
        pf_department: filterDatas.pf_department,
        pf_employee_name: filterDatas.pf_employee_name,
        month_wise_month_year: filterDatas.month_wise_month_year,
        month_wise_designation: filterDatas.month_wise_designation,
        month_wise_department: filterDatas.month_wise_department,
        month_wise_employee_grp: filterDatas.month_wise_employee_grp,
        emp_salsummary_month_year: filterDatas.emp_salsummary_month_year,
        emp_salsummary_designation: filterDatas.emp_salsummary_designation,
        emp_salsummary_department: filterDatas.emp_salsummary_department,
        emp_salsummary_employee_name: filterDatas.emp_salsummary_employee_name,
        muster_month_year: filterDatas.muster_month_year,
        muster_designation: filterDatas.muster_designation,
        muster_department: filterDatas.muster_department,
        muster_employee_name: filterDatas.muster_employee_name,
        earnings_month_year: filterDatas.earnings_month_year,
        earnings_designation: filterDatas.earnings_designation,
        earnings_department: filterDatas.earnings_department,
        earnings_employee_name: filterDatas.earnings_employee_name,
        // attendance_correction_from_date: filterDatas.attendance_correction_from_date,
        // attendance_correction_to_date: filterDatas.attendance_correction_to_date,
        attendance_correction_designation: filterDatas.attendance_correction_designation,
        attendance_correction_department: filterDatas.attendance_correction_department,
        attendance_correction_employee_name: filterDatas.attendance_correction_employee_name,
        subordinate_designation: filterDatas.subordinate_designation,
        subordinate_department: filterDatas.subordinate_department,
        subordinate_employee_name: filterDatas.subordinate_employee_name,
        salary_process_designation: filterDatas.salary_process_designation,
        salary_process_employee_name: filterDatas.salary_process_employee_name,
        roster_employee_name: filterDatas.roster_employee_name,
        roster_department: filterDatas.roster_department,
        roster_team: filterDatas.roster_team,
        leave_type:filterDatas.leave_type,
        leave_employee_name:filterDatas.leave_employee_name,
        approved_by:filterDatas.approved_by,
    });
    const [dropdownList, setDropDownList] = useState({
        designationList: [],
        departmentlist: [],
        employeeDetailsList: [],
        employeeGroupList: [],
        cardTltList: filterDatas?.cardTltList ? filterDatas?.cardTltList : [],
        leaveTypeList: filterDatas.leaveTypeList,
    })
    const hideCursorRef = useRef({});

    const getEmployeeDesignationList = async () => {
        try {
            const apiData = await getEmployeeDesignation(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                designationList: []
            }))
        }
    }

    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                departmentlist: []
            }))
        }
    }

    const getEmpGrpList = async () => {
        try {
            const apiData = await getEmployeeGroup(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeGroupList: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeGroupList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                employeeGroupList: []
            }))
        }
    }

    const getEmployeeNameList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeDetailsList: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeDetailsList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                employeeDetailsList: []
            }))
        }
    };

    useEffect(() => {
        getDepartmentList();
        getEmpGrpList();
        getEmployeeDesignationList();
        getEmployeeNameList();
    }, [])

    const handleChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value,
        });
    };

    const handleClear = () => {
        setFilterData((prevdata) => ({
            ...prevdata,
            day_wise_date: new Date(),
            day_wise_designation: "",
            day_wise_department: "",
            day_wise_employee_grp: "",
            salary_process_month_year: new Date(),
            payroll_detail_date: new Date(),
            payroll_detail_designation: "",
            payroll_detail_department: "",
            payroll_detail_employee_name: "",
            esi_month_year: new Date(),
            esi_designation: "",
            esi_department: "",
            esi_employee_name: "",
            pf_month_year: new Date(),
            pf_designation: "",
            pf_department: "",
            pf_employee_name: "",
            month_wise_month_year: new Date(),
            month_wise_designation: "",
            month_wise_department: "",
            month_wise_employee_grp: "",
            emp_salsummary_month_year: new Date(),
            emp_salsummary_designation: "",
            emp_salsummary_department: "",
            emp_salsummary_employee_name: "",
            muster_month_year: new Date(),
            muster_designation: "",
            muster_department: "",
            muster_employee_name: "",
            earnings_month_year: new Date(),
            earnings_designation: "",
            earnings_department: "",
            earnings_employee_name: "",
            // attendance_correction_from_date: new Date(),
            // attendance_correction_to_date: new Date(),
            attendance_correction_designation: "",
            attendance_correction_department: "",
            attendance_correction_employee_name: "",
            subordinate_designation: "",
            subordinate_department: "",
            subordinate_employee_name: "",
            salary_process_designation: "",
            salary_process_employee_name: "",
            roster_employee_name: "",
            roster_department: "",
            roster_team: "",
            leave_type:"",
            approved_by:"",
        }))
        clearData();
    }

    const renderInput = (label, statekey) => {
        return (
            <div className="fltTextFieldDiv">
                <div className='commonInputTlt'>{label}</div>
                <Input
                    autoComplete='off'
                    className="fltTextField"
                    type="text"
                    name={statekey}
                    value={filterData[statekey] ? filterData[statekey] : ""}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
            </div>
        )
    }

    const renderSelect = (label, statekey, list = [], showKey = "text", valueKey = "value", extrakey = "employee_id" , isExtraDetail = false) => {
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div className="fltTextFieldDiv">
                <div className='commonInputTlt'>{label}</div>
                <Select
                    showSearch
                    className="fltTextField"
                    value={filterData[statekey] ? filterData[statekey] : undefined}
                    name={statekey}
                    onChange={(value) => handleChange(statekey, value)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    ref={hideCursorRef.current[statekey]}
                    onSelect={() => {
                        hideCursorRef.current[statekey].current.blur();
                    }}
                >
                    {list.map((option) => (
                        ((valueKey === "id" && option.is_active === true) || valueKey !== "id") ?
                            <Select.Option key={option[valueKey]} value={option[valueKey]}>
                                {isExtraDetail ? option[showKey] + (option[extrakey] ? " - " + option[extrakey] : "") : option[showKey]}
                            </Select.Option> : null
                    ))}
                </Select>
            </div>
        )
    }

    const renderDatePicker = (label, statekey, picker = '') => {
        return (
            <div className="fltTextFieldDiv">
                <div className='commonInputTlt'>{label}</div>
                <DatePicker
                    className="fltTextField"
                    disabledDate={disabledFutureDate}
                    placeholder = ""
                    name={statekey}
                    value={filterData[statekey] ? dayjs(filterData[statekey]) : ""}
                    onChange={(date) => handleChange(statekey, date)}
                    allowClear={false}
                    inputReadOnly={true}
                    format={picker === "month" ? 'MMMM YYYY' : 'DD-MM-YYYY'}
                    picker={picker}
                    locale={locale}
                />
            </div>
        )
    }

    return (
        <div className='h-full w-full'>
            <div className="flex justify-between items-center filterTltDiv">
                <p className="common_addTltTxt">{"Filter"}</p>
                <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter} /></Tooltip>
            </div>
            <div className="flex flex-col items-center fltContentDiv" >
                {pageName === "Day-Wise Attendance Report" ?
                    <>
                        {/* {renderDatePicker("Select Date", "day_wise_date")} */}
                        {renderSelect("Select Designation", "day_wise_designation", dropdownList.designationList, "designation_name", "id")}
                        {renderSelect("Select Department", "day_wise_department", dropdownList.departmentlist, "department_name", "id")}
                        {renderSelect("Select Employee Group", "day_wise_employee_grp", dropdownList.employeeGroupList, "employee_group_name", "id")}
                    </>
                    : pageName === "Month-Wise Attendance Report" ?
                        <>
                            {/* {renderDatePicker("Select Month & Year", "month_wise_month_year", "month")} */}
                            {renderSelect("Select Designation", "month_wise_designation", dropdownList.designationList, "designation_name", "id")}
                            {renderSelect("Select Department", "month_wise_department", dropdownList.departmentlist, "department_name", "id")}
                            {renderSelect("Select Employee Group", "month_wise_employee_grp", dropdownList.employeeGroupList, "employee_group_name", "id")}
                        </>
                        : pageName === "Salary Process Bank Report" ?
                            <>
                                {/* {renderDatePicker("Select Month & Year", "salary_process_month_year", "month")} */}
                                {renderSelect("Select Designation", "salary_process_designation", dropdownList.designationList, "designation_name", "id")}
                                {renderSelect("Select Employee Name", "salary_process_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                            </>
                            : pageName === "Employee Salary Summary Report" ?
                                <>
                                    {/* {renderDatePicker("Select Month & Year", "emp_salsummary_month_year", "month")} */}
                                    {renderSelect("Select Designation", "emp_salsummary_designation", dropdownList.designationList, "designation_name", "id")}
                                    {renderSelect("Select Department", "emp_salsummary_department", dropdownList.departmentlist, "department_name", "id")}
                                    {renderSelect("Select Employee Name", "emp_salsummary_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                </>
                                : pageName === "Payroll Detailed Summary" ?
                                    <>
                                        {/* {renderDatePicker("Select Month & Year", "payroll_detail_date", "month")} */}
                                        {renderSelect("Select Designation", "payroll_detail_designation", dropdownList.designationList, "designation_name", "id")}
                                        {renderSelect("Select Department", "payroll_detail_department", dropdownList.departmentlist, "department_name", "id")}
                                        {renderSelect("Select Employee Name", "payroll_detail_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                    </>
                                    : pageName === "Employee ESI Statements" ?
                                        <>
                                            {/* {renderDatePicker("Select Month & Year", "esi_month_year", "month")} */}
                                            {renderSelect("Select Designation", "esi_designation", dropdownList.designationList, "designation_name", "id")}
                                            {renderSelect("Select Department", "esi_department", dropdownList.departmentlist, "department_name", "id")}
                                            {renderSelect("Select Employee Name", "esi_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                        </>
                                        : pageName === "Employee PF Statements" ?
                                            <>
                                                {/* {renderDatePicker("Select Month & Year", "pf_month_year", "month")} */}
                                                {renderSelect("Select Designation", "pf_designation", dropdownList.designationList, "designation_name", "id")}
                                                {renderSelect("Select Department", "pf_department", dropdownList.departmentlist, "department_name", "id")}
                                                {renderSelect("Select Employee Name", "pf_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                            </>
                                            : pageName === "Muster Roll" ?
                                                <>
                                                    {/* {renderDatePicker("Select Month & Year", "muster_month_year", "month")} */}
                                                    {renderSelect("Select Designation", "muster_designation", dropdownList.designationList, "designation_name", "id")}
                                                    {renderSelect("Select Department", "muster_department", dropdownList.departmentlist, "department_name", "id")}
                                                    {renderSelect("Select Employee Name", "muster_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                                </>
                                                : pageName === "Employee Credits & Debits" ?
                                                    <>
                                                        {/* {renderDatePicker("Select Month & Year", "earnings_month_year", "month")} */}
                                                        {renderSelect("Select Designation", "earnings_designation", dropdownList.designationList, "designation_name", "id")}
                                                        {renderSelect("Select Department", "earnings_department", dropdownList.departmentlist, "department_name", "id")}
                                                        {renderSelect("Select Employee Name", "earnings_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                                    </>
                                                    : pageName === "Attendance Correction" ?
                                                        <>
                                                            {/* {renderDatePicker("Select From date", "attendance_correction_from_date")}
                                                            {renderDatePicker("Select To Date", "attendance_correction_to_date")} */}
                                                            {renderSelect("Select Designation", "attendance_correction_designation", dropdownList.designationList, "designation_name", "id")}
                                                            {renderSelect("Select Department", "attendance_correction_department", dropdownList.departmentlist, "department_name", "id")}
                                                            {renderSelect("Select Employee Name", "attendance_correction_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                                        </>
                                                        : pageName === "Subordinates" ?
                                                            <>
                                                                {renderSelect("Select Designation", "subordinate_designation", dropdownList.designationList, "designation_name", "id")}
                                                                {renderSelect("Select Department", "subordinate_department", dropdownList.departmentlist, "department_name", "id")}
                                                                {renderSelect("Select Employee Name", "subordinate_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                                            </>
                                                            : pageName === "Roster" ?
                                                            <>
                                                                {renderSelect("Select Employee Name", "roster_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                                                {renderSelect("Select Department", "roster_department", dropdownList.departmentlist, "department_name", "id")}
                                                                {renderSelect("Select Team", "roster_team", dropdownList.cardTltList, "title", "id")}
                                                            </>
                                                            : pageName === "Leave Permission Report" ?
                                                            <>
                                                                {renderSelect("Select Employee Name", "leave_employee_name", dropdownList.employeeDetailsList, "first_name", "id", 'employee_id', true)}
                                                                {renderSelect("Select Leave Type", "leave_type", dropdownList.leaveTypeList, "leave_name", "id")}
                                                                {renderSelect("Select Approved By", "approved_by", dropdownList.employeeDetailsList, "first_name", "id", "employee_id", true)}
                                                            </>
                                                            : null}
            </div>
            <div className='flex justify-center items-end'>
                <MyButton label="Clear" onClick={handleClear} outlined={true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
                <MyButton label={"Apply"} onClick={() => filteredData(filterData)} paddingX={"0 0.5vw"} />
            </div>
        </div>
    )
}

export default ReportsFilter;