import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmpEsiReportsPagination } from "hooks/api/reportsApi.js";
import { EMPLOYEE_ESI_REPORTS } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import { toIndianCurrency } from "utils/StringUtils";
import dayjs from "dayjs";

const initialData = {
  esi_month_year: new Date(),
  esi_designation: "",
  esi_department: "",
  esi_employee_name: "",
}

const EmployeeESIStatement = () => {
  const authtoken = sessionStorage.getItem("token");
  const pageSize = 10;

  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ ...initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const parsedDate = selectedDate ? dayjs(selectedDate) : null;
  const [query, setQuery] = useState(`month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.esi_designation ? filterDatas.esi_designation : ""}&department=${filterDatas.esi_department ? filterDatas.esi_department : ""}&employee=${filterDatas.esi_employee_name ? filterDatas.esi_employee_name : ""}`);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getEmpEsiReportsPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  useEffect(() => {
    const parsedDate = selectedDate ? dayjs(selectedDate) : null;
    const newQuery = `month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.esi_designation ? filterDatas.esi_designation : ""}&department=${filterDatas.esi_department ? filterDatas.esi_department : ""}&employee=${filterDatas.esi_employee_name ? filterDatas.esi_employee_name : ""}`;
    setQuery(newQuery);
  }, [filterDatas]);

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   `year=${data.esi_month_year ? dayjs(data.esi_month_year).format('YYYY') : ""}&month=${data.esi_month_year ? dayjs(data.esi_month_year).format('MM') : ""}&designation=${data.esi_designation ? data.esi_designation : ""}&department=${data.esi_department ? data.esi_department : ""}&employee=${data.esi_employee_name ? data.esi_employee_name : ""}`,
    //   searchText,
    //   sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas({ ...initialData });
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   query,
    //   searchText,
    //   sorting);
  }
  //filter end

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const columns = [
    {
      title: "Employee Details",
      dataIndex: "employee__employee_name",
      key: "employee__employee_name",
      width: "25%",
      sorter: true,
      fixed: 'left',
      render: (_, record) => {
        let empDetails = {
          ...record.employee,
          "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
          "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
          "id": record?.employee.employee_id
        }
        return <EmployeeDetails details={empDetails} />;
      }
    },
    {
      title: "ESI No",
      dataIndex: "ESI_no",
      key: "ESI_no",
      sorter: true,
      width: "18.75%",
      render: (_, record) =>
      (record?.ESI_no?.length > 28 ?
        <Tooltip title={record?.ESI_no}>{<span style={{ color: "black" }}>{record?.ESI_no?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p style={{ color: "black" }}>{record?.ESI_no ? record?.ESI_no : "-"}</p>),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Salary Days",
      dataIndex: "salary_days",
      key: "salary_days",
      sorter: true,
      width: "18.75%",
      render: (_, record) =>
      (record?.salary_days?.length > 28 ?
        <Tooltip title={record?.salary_days}>{<span style={{ color: "black" }}>{record?.salary_days?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p style={{ color: "black" }}>{record?.salary_days ? record?.salary_days : "-"}</p>),
      align: "right",
      fixed: 'right',
    },
    {
      title: "ESI Wages",
      dataIndex: "ESI_Wages",
      key: "ESI_Wages",
      sorter: true,
      width: "18.75%",
      render: (_, record) =>
        record?.ESI_Wages?.length > 7 ? (
          <Tooltip title={record?.ESI_Wages}>
            {
              <span>{record?.ESI_Wages?.slice(0, 7) + "..."}</span>
            }
          </Tooltip>
        ) : (
          <p>{record?.ESI_Wages ? toIndianCurrency(record?.ESI_Wages) : "-"}</p>
        ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "ESI Contributions",
      dataIndex: "ESI_Contirbutions",
      key: "ESI_Contirbutions",
      width: "18.75%",
      sorter: true,
      render: (_, record) =>
        record?.ESI_Contirbutions?.length > 7 ? (
          <Tooltip title={record?.ESI_Contirbutions}>
            {
              <span>{record?.ESI_Contirbutions?.slice(0, 7) + "..."}</span>
            }
          </Tooltip>
        ) : (
          <p>{record?.ESI_Contirbutions ? toIndianCurrency(record?.ESI_Contirbutions) : "-"}</p>
        ),
      align: "right",
      fixed: 'right',
    },
  ];

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&month=" + (parsedDate ? parsedDate.month() + 1 : "") +
    "&year=" + (parsedDate ? parsedDate.year() : "") +
    "&designation=" + (filterDatas.esi_designation ? filterDatas.esi_designation : "") +
    "&department=" + (filterDatas.esi_department ? filterDatas.esi_department : "") +
    "&employee=" + (filterDatas.esi_employee_name ? filterDatas.esi_employee_name : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  // Date changes start - Filter the data based on the selected date
   const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilterDatas({ ...filterDatas, esi_month_year: date });
    setDataUpdated(!dataUpdated);
  };// Date changes end

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Employee ESI Statements"}
          pageInfo={pageInfo} handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={EMPLOYEE_ESI_REPORTS} query={queryString}
          isAddRequired={false} isDraftRequired={false}
          isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
          format="MMMM YYYY" picker="month"
        />
      </div>
      <div className="relative h-5/6 mt-1">
        {filterVisible ?
          <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
            <ReportsFilter
              pageName={"Employee ESI Statements"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={"mt-5 w-full custom-table"} style={styles.fltContainer}>
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={dataUpdated ? "updated" : "not-updated"}
          />
        </div>
      </div>
    </Card>
  );
}

export default EmployeeESIStatement