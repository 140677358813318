import React, { useState, useEffect, useCallback } from 'react';
import { Card, Dropdown, Space, Table, Tooltip, } from "antd";
import { CommonClickableCards, CommonDeletePopup } from "components/common/CommonComponent";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import { useGetApprovalInbox } from "hooks/api/payrollApi/approvalInboxApi";
import { PAYROLL_APPROVAL_INBOX } from "constants";
import PayrollFilter from 'pages/payroll/PayRollFilters';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { EmployeeDetails } from "components/common/CommonComponent";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import ApprovalLogPopUp from 'pages/employee/ApprovalLogPopUp';
import { PAYROLL_LEAVE_APPROVAL } from 'constants';
import { PAYROLL_REIMBURSEMENT_APPROVAL } from 'constants';
import { PAYROLL_ADVANCE_SALARY_APPROVAL } from 'constants';

const initialData = {
    approval_date: "",
    approval_type: undefined,
    approval_employee: undefined,
}

const ApprovalInbox = () => {
    const authtoken = sessionStorage.getItem("token");
    const query = "";
    const pageSize = 10;
    const dateFormat = "YYYY-MM-DD";

    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [isApprovalLog, setIsApprovalLog] = useState(false);
    const [approvalData, setApprovalData] = useState([]);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [cancelData, setCancelData] = useState([]);
    const companyid = sessionStorage.getItem("userId");
    const tableContainerHeight = "56vh";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const navigate = useNavigate();

    const userPermission = checkUserPermissionFunc("employee", "Approval Inbox", "SubMenu");

    const [data, cardData, pageInfo, loading, _message, refetch] = useGetApprovalInbox(
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    );

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `date=${data.approval_date ? dayjs(data.approval_date).format(dateFormat) : ""}&approval_type=${data.approval_type ? data.approval_type : ""}&employee=${data.approval_employee ? data.approval_employee : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas(initialData);
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handlePopClose = () => {
        setConfirmPopup(false);
    }

    const handleCancelPost = useCallback(() => {
        const authtoken = sessionStorage.getItem("token")
        var API = null
        var data = cancelData ? cancelData : null
        try {
            let updatedFormData = {
                approval_notes: "",
                approval_status: "cancelled",
                id: data?.id,
                request_type: "cancel request"
            }
            if (data?.approval_type === "Leave") {
                API = PAYROLL_LEAVE_APPROVAL
                updatedFormData = {
                    ...updatedFormData,
                    company_id: companyid,
                    from_date: dayjs(data.from_date).format(dateFormat),
                    to_date: dayjs(data.to_date).format(dateFormat),
                    leave_id: data?.id,
                    leave_type: data?.leave_type_id,
                    reason: data?.reason,
                    uploaded_documents: []
                };
            } else if (data?.approval_type === "Reimbursements") {
                API = PAYROLL_REIMBURSEMENT_APPROVAL
                updatedFormData = {
                    ...updatedFormData,
                    approved_amount: data?.approval_amount,
                    reimbursement_type: data?.reimbursement_type_id,
                    requested_amount: data?.requested_amount
                }
            } else {
                API = PAYROLL_ADVANCE_SALARY_APPROVAL
                updatedFormData = {
                    ...updatedFormData,
                    advance_requested: data?.requested_amount,
                    approved_amount: data?.approval_amount,
                    requested_emi_period: data?.emi_period,
                    debit_from: data?.debit_from,
                }
            }
            fetch(API, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        setPageNo(1);
                        refetch(1,
                            pageSize,
                            query,
                            searchText,
                            sorting);
                        setDataUpdated(!dataUpdated);
                        handlePopClose();
                    }
                    else if (data.status === "fail") {
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    });

    const handleLeaveApproval = () => { navigate('/employee/approvalinbox/leaveapproval') };
    const handleAdvanceSalary = () => { navigate('/employee/approvalinbox/advancesalary') };
    const handleReimbursements = () => { navigate('/employee/approvalinbox/reimnbursement') };
    const handleResignation = () => { navigate('/employee/approvalinbox/resignation') };

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const handleApprovalLog = (record) => {
        // let data = record?.approval_log?.length > 0 ? record?.approval_log : [];
        setApprovalData(record);
        setIsApprovalLog(true)
    }

    const handleApprovalLogCls = () => {
        setIsApprovalLog(false)
        setApprovalData([]);
    }

    const handleCancelPopup = (record) => {
        setConfirmPopup(true)
        setCancelData(record);
    }

    const columns = [
        {
            title: "Approved Date",
            dataIndex: "approved_date",
            key: "approved_date",
            sorter: true,
            width: "12%",
            render: (_, record) => (<p>{record?.approved_date ? dayjs(record?.approved_date).format('DD/MM/YYYY') : "-"}</p>),
            align: "center",
            fixed: "left",
        },
        {
            title: "Approval Type",
            dataIndex: "approval_type",
            key: "approval_type",
            sorter: true,
            width: "14%",
            fixed: "left",
            render: (_, record) =>
            (record?.approval_type?.length > 15 ?
                <Tooltip title={record?.approval_type}>{<span className='capitalize'>{record?.approval_type?.slice(0, 15) + "..."}</span>}</Tooltip>
                : <p className='capitalize'>{record?.approval_type ? record?.approval_type : "-"}</p>)
        },
        {
            title: "Approval Send From",
            dataIndex: "request_from",
            key: "request_from",
            sorter: true,
            width: "22%",
            fixed: "left",
            render: (_, record) => {
                let { request_from } = record;
                let empDetails = {
                    ...record,
                    title: request_from.title ? request_from.title : "",
                    first_name: request_from.employee_name ? request_from.employee_name : "",
                    last_name: "",
                    designation_id: {
                        id: request_from?.designation?.id ? request_from?.designation?.id : "",
                        designation_name: request_from?.designation?.name ? request_from?.designation?.name : ""
                    },
                    id: request_from.employee_id,
                    profile: request_from.profile,
                }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Approved By",
            key: "is_active",
            dataIndex: "is_active",
            sorter: true,
            width: "22%",
            fixed: "left",
            render: (_, record) => {
                let { approved_by } = record;
                let empDetails = {
                    ...record,
                    title: approved_by.title ? approved_by.title : "",
                    first_name: approved_by.employee_name ? approved_by.employee_name : "",
                    last_name: "",
                    designation_id: {
                        id: approved_by?.designation?.id ? approved_by?.designation?.id : "",
                        designation_name: approved_by?.designation?.name ? approved_by?.designation?.name : ""
                    },
                    id: approved_by.employee_id,
                    profile: approved_by.profile,
                }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Notes",
            key: "approval_notes",
            dataIndex: "approval_notes",
            sorter: true,
            width: "14%",
            fixed: "left",
            render: (_, record) =>
            (record?.approval_notes?.length > 15 ?
                <Tooltip title={record?.approval_notes}>{<span>{record?.approval_notes?.slice(0, 15) + "..."}</span>}</Tooltip>
                : <p>{record?.approval_notes ? record?.approval_notes : "-"}</p>)
        },
        {
            title: "Status",
            key: "approval_status",
            dataIndex: "approval_status",
            sorter: true,
            width: "10%",
            fixed: "center",
            render: (_, record) =>
                record?.approval_status?.length > 15 ? (
                    <Tooltip title={<span style={{textTransform:"capitalize"}}>{record?.approval_status}</span>}>
                        <span className="capitalize">
                            {record?.approval_status?.slice(0, 15) + "..."}
                        </span>
                    </Tooltip>
                ) : (
                    <p className="capitalize">
                        {record?.approval_status ? record?.approval_status : "-"}
                    </p>
                ),
            align: 'center',
            fixed: "left",
        },
    ];

    if (userPermission?.edit) {
        columns.push({
            title: "Action",
            key: "action",
            align: 'center',
            width: "6%",
            fixed: "left",
            render: (_, record) => {
                const items = [{
                    key: "1",
                    label: (<div className="empApproveTxt">View Log</div>)
                }, {
                    key: "2",
                    label: (<div className="empApproveTxt">Cancel</div>),
                    disabled : !record?.enable_cancel_request || record?.approval_status === "cancelled" || record?.approval_status === "rejected"
                }];
                return (
                    <div className="flex justify-center items-center">
                        <Space size={"middle"}>
                            <Dropdown menu={{
                                items,
                                onClick: ({ key }) => {
                                    if (key === '1') {
                                        handleApprovalLog(record);
                                    } else {
                                        handleCancelPopup(record)
                                    }
                                }
                            }} trigger={['click']} placement="bottomLeft" overlayStyle={styles.dropOverLay}>
                                <IconButton title="" icon={<img src={ImagePaths.moreIcon.default} alt="more" />} />
                            </Dropdown>
                        </Space>
                    </div>
                )
            }
        })
    }

    const handleSearch = (event) => {
        if (event.key === 'Enter' || event.type === 'click') {
            setPageNo(1);
            refetch(1,
                pageSize,
                query,
                searchText,
                sorting);
            setDataUpdated(!dataUpdated);
        }
    }
    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&date=" + (filterDatas.approval_date ? dayjs(filterDatas.approval_date).format(dateFormat) : "") +
        "&approval_type=" + (filterDatas.approval_type ? filterDatas.approval_type : "") +
        "&employee=" + (filterDatas.approval_employee ? filterDatas.approval_employee : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <div className="h-full">
            <div className="flex justify-start" style ={{height: "15%",marginBottom: "10px"}}>
                <CommonClickableCards label="Leave Approval" count={cardData?.total_leave_approvals} testID={"aprovInbox_leave"} onClick={userPermission?.permission ? handleLeaveApproval : undefined} />
                <CommonClickableCards label="Advance Salary" count={cardData?.total_advance_salary} testID={"aprovInbox_salary"} onClick={userPermission?.permission ? handleAdvanceSalary : undefined} />
                <CommonClickableCards label="Reimbursement" count={cardData?.total_reimbursements} testID={"aprovInbox_reimburs"} onClick={userPermission?.permission ? handleReimbursements : undefined} />
                <CommonClickableCards label="Resignation" count={cardData?.total_resignations} testID={"aprovInbox_resignation"} onClick={ handleResignation} />
            </div>
            <Card style ={{height: "83%"}}>
                <div className={"h-1/6"}>
                    <CommonTblHeader pageName={"Approval Inbox"}
                        pageInfo={pageInfo} handleSearch={handleSearch}
                        onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                        url={PAYROLL_APPROVAL_INBOX} query={queryString}
                        isAddRequired={false}
                        isDraftRequired={false} testID={"approve_box"}
                    />
                </div>
                <div className="h-5/6 flex justify-center items-center mt-1">
                    {filterVisible ?
                        <div className="w-1/4 mt-5" style={styles.fltContainer}>
                            <PayrollFilter
                                pageName={"ApprovalInbox"}
                                filterDatas={filterDatas}
                                closeFilter={ClosePopUp}
                                filteredData={renderFilter}
                                clearData={clearFilter} />
                        </div>
                        : null}
                    <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                    <Table 
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading = {loading}
                        style = {{"--table-container-height": tableContainerHeight}}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={dataUpdated ? "updated" : "not-updated"}
                    />
                    </div>
                </div>
                {confirmPopup && (
                    <CommonDeletePopup
                        handleCancelBtn={handlePopClose}
                        handleDeleteBtn={handleCancelPost}
                        sentence={"Are You Sure ? Do you want to cancel?"}
                        okBtnLable={"Yes"}
                    />)}
                { isApprovalLog ? <ApprovalLogPopUp TblData = {approvalData} onClose = {handleApprovalLogCls}/>: null}
            </Card>
        </div>
    )
}

export default ApprovalInbox;