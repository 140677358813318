import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Modal, DatePicker, Select, Button, Tooltip, Upload } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys, extractImageName, extractFileExtension} from "components/common/validation";
import { EMPLOYEE_LEAVE, APPROVAL_LEAVE_LIST, } from "constants";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import { getMethod } from "components/common/CommonFuntion";
import { imgUploadSize } from "components/common/enum";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { CommonCarouselPopup } from "components/common/CommonComponent";
import Item from "antd/es/list/Item";

dayjs.extend(isBetween);

function AddEditLeaveRequest({ fromEdit, editDetails, onHide, onSave, leaveTypeList, available_leave }) {
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const prevPropsRef = useRef();
  const dateFormat = "YYYY-MM-DD";

  const [formData, setFormData] = useState({
    leave_type: "",
    from_date: undefined,
    to_date: undefined,
    rejoining_date: undefined,
    reason: "",
    company_id: parseInt(companyid),
    is_active: true,
    uploaded_documents: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const hideCursorRef = useRef(null);
  const [leaveApprovedList, setLeaveApprovedList] = useState([]);
  const [isImgOpen, setImgOpen] = useState(false);

  const getApprovedLeaveList = async () => {
    try {
      const apiData = await getMethod(APPROVAL_LEAVE_LIST);
      if (apiData && apiData.status === "success" && apiData.data) {
        setLeaveApprovedList([...apiData.data]);
      } else {
        setLeaveApprovedList([]);
      }
    } catch (error) {
      setLeaveApprovedList([]);
    }
  }
  

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
    getApprovedLeaveList();
  }, [authtoken]);

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        employee: editDetails.employee_id,
        leave_type: editDetails.leave_type_id ? editDetails.leave_type_id : "",
        from_date: editDetails.from_date ? dayjs(editDetails.from_date, dateFormat) : undefined,
        to_date: editDetails.to_date ? dayjs(editDetails.to_date, dateFormat) : undefined,
        rejoining_date: editDetails.rejoining_date ? dayjs(editDetails.rejoining_date, dateFormat) : undefined,
        reason: editDetails.reason ? editDetails.reason : "",
        is_active: editDetails.is_active,
        company: parseInt(editDetails.company_id),
        uploaded_documents: editDetails.uploaded_documents?.length > 0 ?
          editDetails.uploaded_documents.map(url => ({
            name: extractImageName(url),
            url: url
          })) : [],
      }));
      form.setFieldsValue({
        leave_type: editDetails.leave_type_id ? editDetails.leave_type_id : "",
        from_date: editDetails.from_date ? dayjs(editDetails.from_date, dateFormat) : undefined,
        to_date: editDetails.to_date ? dayjs(editDetails.to_date, dateFormat) : undefined,
        rejoining_date: editDetails.rejoining_date ? dayjs(editDetails.rejoining_date, dateFormat) : undefined,
        reason: editDetails.reason ? editDetails.reason : "",
        is_active: editDetails.is_active,
        uploaded_documents: editDetails.uploaded_documents?.length > 0 ?
        editDetails.uploaded_documents.map(url => ({
          name: extractImageName(url),
          url: url
        })) : [],
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails])

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    onHide();
    setFormData({
      leave_type: "",
      from_date: undefined,
      to_date: undefined,
      rejoining_date: undefined,
      reason: "",
      company_id: parseInt(companyid),
      uploaded_documents: [],
    });
  };

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true);
      const dataForm = new FormData();
      dataForm.append("leave_type", formData.leave_type ? formData.leave_type : "");
      dataForm.append("from_date", formData.from_date ? dayjs(formData.from_date, "DD-MM-YYYY").format(dateFormat) : "");
      dataForm.append("to_date", formData.to_date ? dayjs(formData.to_date, "DD-MM-YYYY").format(dateFormat) : "");
      dataForm.append("rejoining_date", formData.rejoining_date ? dayjs(formData.rejoining_date, "DD-MM-YYYY").format(dateFormat) : "");
      dataForm.append("reason", formData.reason ? formData.reason : "");
      dataForm.append("is_active", formData.is_active ? formData.is_active : "");
      dataForm.append("company", formData.company_id ? formData.company_id : "");
      dataForm.append("employee", formData.employee ? formData.employee : "");
      dataForm.append("request_type", "leave request");
      dataForm.append("approval_status", "pending")
      formData.uploaded_documents?.length > 0 ?
        formData.uploaded_documents.forEach((image, index) => {
          if (image?.url) {
            dataForm.append(`uploaded_documents_${index + 1}`, image.url);
          } else {
            dataForm.append(`uploaded_documents_${index + 1}`, image.originFileObj);
          }
        }) : dataForm.append("uploaded_documents", []);

      if (isDraftClked) {
        dataForm.append("is_draft", true);
      } else {
        dataForm.append("is_draft", false);
      }
      if(editDetails?.is_draft){
        dataForm.append("approval_status", "pending")
      }
      if (fromEdit) {
        dataForm.append("id", formData.id);
      }

      fetch(EMPLOYEE_LEAVE, {
        method: methods,
        headers: {
          //'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: dataForm
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            onSave();
            handleCancel();
            setLoading(false);
            setFormData({
              code: "",
              Deduction_name: "",
              deduct_from: "",
              fixed: false,
              is_active: true,
              share: "",
              deduction_value: 0,
              notes: "",
              company_id: companyid,
              uploaded_documents: [],
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, fromEdit, companyid, handleCancel, onSave]);

  const disabledDateFrom = (restrictionDate) => (current) => {
    if (restrictionDate) {
      const startDate = dayjs(restrictionDate, 'DD-MM-YYYY');
      return current && current < startDate.startOf('day');
    }
    return current && current > dayjs().endOf('day');
  };

  const disabledApprovedLeaveDates = (current) => {
    const dateRanges = leaveApprovedList?.map((leave) => ({
      from: dayjs(leave.from_date),
      to: dayjs(leave.to_date),
    }));
    return dayjs(current).isBefore(dayjs(), 'day') ||
      dateRanges?.some((range) =>
        current.isBetween(range.from, range.to, 'day', '[]')
      );
  };

  const renderDatePicker = (statekey) => {
    return (<DatePicker
      allowClear
      inputReadOnly={true}
      value={formData[statekey] ? formData[statekey] : undefined}
      format={"DD-MM-YYYY"}
      style={{ width: "100%" }}
      onChange={(date, dateString) => {
        const newFormData = {
          ...formData,
          [statekey]: date,
        };
        if (statekey === "from_date") {
          newFormData.to_date = undefined;
        }
        setFormData(newFormData);
        form.setFieldsValue(newFormData);
      }}
      disabled={statekey === "rejoining_date" ? !(formData.from_date && formData.to_date) : statekey === "to_date" ? !(formData.from_date) : false}
      disabledDate={statekey === 'from_date' ? disabledApprovedLeaveDates : statekey === "to_date" ? (current) => disabledApprovedLeaveDates(current) || disabledDateFrom(formData.from_date)(current) : disabledDateFrom(formData.to_date)}
      placeholder=""
    />)
  }

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const keysToExclude = ['is_active', 'company_id'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const generateLeaveSentence = (data) => {
    let sentenceParts = [];
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let readableKey = key.replace(/([A-Z])/g, ' $1').toLowerCase();
        sentenceParts.push(`${data[key]} ${readableKey}`);
      }
    }
    let sentence = sentenceParts.join(', ').replace(/,([^,]*)$/, ' and$1');

    return `You have ${sentence}.`;
  }

  let leaveInfo = available_leave !== null ? generateLeaveSentence(available_leave) : "";

  const handleUploadChange = ({ fileList }) => {
    const filteredFileList = fileList.filter((file) => {
      const fileSizeMB = file.size ? file.size / (1024 * 1024) : true;
      const isImageOrPdf = file.type ? file.type.startsWith('image/') || file.type === 'application/pdf' : true;

      if (!isImageOrPdf) {
        ToastMsg("error", "Unsupported file type");
        return false;
      } else if (fileSizeMB > imgUploadSize) {
        ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
        return false;
      } else {
        return true;
      }
    });
    setFormData({
      ...formData,
      uploaded_documents: filteredFileList,
    });
  };

  const beforeUpload = (file) => {
    const fileSizeMB = file.size / (1024 * 1024);
    const isImageOrPdf = file.type.startsWith('image/') || file.type === 'application/pdf';
    if (!isImageOrPdf) {
      return false;
    } else if (fileSizeMB > imgUploadSize) {
      return false;
    }
    return true;
  };

  const handleRemoveImage = (index) => {
    const newUploadedImages = [...formData.uploaded_documents];
    newUploadedImages.splice(index, 1);
    setFormData({
      ...formData,
      uploaded_documents: newUploadedImages,
    });
  };

  const handlePreviewImage = () => {
    if (formData?.uploaded_documents.length > 0) {
      setImgOpen(true);
    }
  };

  const handlePreviewCancel = () => {
    setImgOpen(false);
  };

  const ImageUploader = () => {
    return (
      <div>
        <div className="flex justify-center items-center" style={{ width: '100%', height: "15vw", backgroundColor: "#cbcbcb"}}>
          <div
            className="flex justify-center items-center"
            style={{
              width: '70%',
              height: '70%',
              border: '2px dashed gray',
              borderRadius: "0.3vw",
              position: 'relative'
            }}>
            {formData.uploaded_documents?.length === 6 ? 
            <p id="common_reimburse_supportTxt">You have reached the maximum limit of 6 file attachments.</p>:
            <Upload
              accept="image/*, application/pdf"
              beforeUpload={beforeUpload}
              onChange={handleUploadChange}
              fileList={formData.uploaded_documents}
              multiple
              maxCount={6}
              showUploadList={false}
            >
              <div className="flex flex-col justify-center items-center">
                <img src={ImagePaths.imageUpload.default} alt="upload image" style={{ width: "2.5vw", height: "2.5vw", marginBottom: "0.8vw" }} />
                <p id="common_cancelTxt" style={{ marginBottom: "0.8vw" }}>Drag And Drop Or</p>
                <Button type="text" id="common_cancelTxt" size="small" style={{ borderRadius: 0, marginBottom: "0.8vw" }}>Click Here To Upload</Button>
                <p id="common_reimburse_supportTxt">Supported Format Pdf And All Image Formats</p>
              </div>
            </Upload>
            }
          </div>
        </div>
        {formData.uploaded_documents?.length > 0 ?
          <div className="grid grid-cols-2 gap-x-3 gap-y-2 mt-2">
            {formData.uploaded_documents.map((file, index) => (
              <div key={index} className="flex justify-between items-center border-b" style={{ paddingInline: "0.3dvw"}}>
                {file?.name?.length > 15 ?
                  <Tooltip title={file?.name}>{<span style={{ fontSize: "0.8vw" }}>{"..." + file?.name?.slice(-14)}</span>}</Tooltip>
                  : <p style={{ fontSize: "0.8vw" }}>{file?.name ? file?.name : "-"}</p>}
                <div>
                  <IconButton
                    title="View"
                    icon={<EyeOutlined style={styles.closeIcon} />}
                    onClick={() => handlePreviewImage(index)}
                  />
                  <IconButton
                    title="Delete"
                    icon={<DeleteOutlined style={styles.closeIcon} />}
                    onClick={() => handleRemoveImage(index)}
                  />
                </div>
              </div>
            ))}
          </div>
          : null}
      </div>
    );
  }

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={"60vw"}
    >
      <div div className="w-full">
        <p id="common_ApprovalTxt">{fromEdit ? "Edit Leave" : "Apply For Leave"}</p>
        <Form
          layout="vertical"
          form={form}
          name="basicform"
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          colon={false}
          requiredMark={false}
          style={{ width: "100%" }}
          initialValues={true}
        >
          <div className="grid grid-cols-2 gap-5">
            <div className="grid grid-cols-1">
              <Form.Item
                label="Select Leave Type "
                name="leave_type"
                rules={[{ required: true, message: "This field is required " }]}
                tooltip={{
                  title: "This field is required",
                  icon: <span style={{ color: "red" }}>*</span>,
                }}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  value={formData.leave_type ? formData.leave_type : ""}
                  name={"leave_type"}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      leave_type: value ? Number(value) : "",
                    });
                    form.setFieldValue("leave_type", value ? Number(value) : "");
                  }}
                  filterOption={filterOption}
                  ref={hideCursorRef}
                  onSelect={() => {
                    hideCursorRef.current.blur();
                  }}
                >
                  {leaveTypeList.map((option) => (
                    (option.id === formData.leave_type || option.is_active) ?
                      <Select.Option key={option.id} value={option.id}>{option.leave_name}</Select.Option> : null
                  ))}
                </Select>
                {leaveInfo && (<p style={{ fontSize: "0.75vw", color: "#616161" }}>{leaveInfo} </p>)}
              </Form.Item>

              <div className="grid gap-4 grid-cols-2">
                <Form.Item
                  label="From Date"
                  name="from_date"
                  rules={[{ required: true, message: "This field is required" }]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("from_date")}
                </Form.Item>
                <Form.Item
                  label="To Date"
                  name="to_date"
                  rules={[{ required: true, message: "This field is required" }]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("to_date")}
                </Form.Item>
              </div>
              {/* as per dicussion with hr Rejoining Date hidded */}
              {/* <div className="grid gap-4 grid-cols-2">
              <Form.Item label="Rejoining Date" name="rejoining_date">
                {renderDatePicker("rejoining_date")}
              </Form.Item>
            </div> */}
              <div className="leaveText">
                <Form.Item
                  label="Reason"
                  name="reason"
                  rules={[{ required: true, message: "This field is required" },
                  { validator: noBlankSpacesValidator }
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  <Input.TextArea
                    bordered={true}
                    className="mt-2 textarea"
                    maxLength={150}
                    rows={3}
                    autoSize={{
                      minRows: 3,
                      maxRows: 4,
                    }}
                    placeholder="Please provide the reason for requesting leave."
                    name="reason"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        reason: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item name="uploaded_documents">
              <ImageUploader />
            </Form.Item>
          </div>
          <Form.Item>
            <div className='flex justify-end items-end'>
              <MyButton htmlType="button" label="Cancel" onClick={handleCancel} outlined={true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
              {fromEdit ? null
                : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />}
              <MyButton htmlType="submit" label={fromEdit ? "Update Request" : "Send Request"} loading={loading} paddingX={"0 1.2vw"} />
            </div>
          </Form.Item>
        </Form>
      </div>
      {(isImgOpen && formData?.uploaded_documents.length > 0) ?
        <Modal
          centered
          open={isImgOpen}
          onCancel={handlePreviewCancel}
          footer={null}
          width={"50vw"}>
          <CommonCarouselPopup documents={[...formData?.uploaded_documents]} />
        </Modal> : null}
    </Modal>
  );
}
const styles = {
  closeIcon: {
    color: "#04B7B1",
    fontSize: "0.95vw",
    fontWeight: "bolder"
  }
}
export default AddEditLeaveRequest;

