import React, { useEffect, useState, useRef } from "react";
import { Card, Table, Tooltip, Space } from "antd";
import AddEmployeeType from "./AddEmployeeType";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import MasterFilter from "pages/master/MasterFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeeTypePagination } from "hooks/api/masterApi/employeeTypeApi";
import { EMPLOYEE_TYPE } from "constants";
import {checkUserPermissionFunc, deleteDraftItemsById} from "components/common/CommonFuntion";
import { CommonDeletePopup } from 'components/common/CommonComponent';

const initialData = {
  employee_type_code: "",
  employee_type_name: "",
  is_active: "",
}

function EmployeeType() {
  const authtoken = sessionStorage.getItem("token");
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;

  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
  const tableContainerHeight = (addVisible || editVisible) ? "64vh" : isDraft ? "77.5vh" : "72vh";

    useEffect(() => {
      if (!authtoken) {
        window.location.href = "/";
      }
    }, [authtoken]);

  const userPermission = checkUserPermissionFunc("master", "Employee Type", "SubMenu");

  const [data, pageInfo, loading, _message, refetch] = useApiData(getEmployeeTypePagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const addClick = () => {
    setAddVisible(true);
    setEditVisible(false);
    setSelectedDetails({});
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg('warning', "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
      setAddVisible(false);
    }
  };

  const handleSave = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setIsDraft(false);
    refetch(pageNo,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
  };

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `employee_code=${data.employee_type_code ? data.employee_type_code : ""}&employee_type=${data.employee_type_name ? data.employee_type_name : ""}&active_status=${data.is_active !== undefined ? data.is_active : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
  }
  //filter end

  const handleSearch = () => {
    setEditVisible(false)
    setAddVisible(false)
    setSelectedDetails({});
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    queryRef.current = isDraft ? `is_draft=True` : "";
    refetch(pagination.current,
      pageSize,
      queryRef.current,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const columns = [
    {
      title: "Employee Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      width: "17.05%",
      fixed: "left",
      render: (_, record) =>
      (record?.code?.length > 20 ?
        <Tooltip title={record?.code}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.code?.slice(0, 20) + "..."}</span>}</Tooltip>
        : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.code ? record?.code : "-"}</p>),
    },
    {
      title: "Employee Type",
      dataIndex: "employee_type_name",
      key: "employee_type_name",
      sorter: true,
      width: "17.05%",
      fixed: "left",
      render: (_, record) =>
      (record?.employee_type_name?.length > 20 ?
        <Tooltip title={record?.employee_type_name}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.employee_type_name?.slice(0, 20) + "..."}</span>}</Tooltip>
        : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.employee_type_name ? record?.employee_type_name : "-"}</p>),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      sorter: true,
      width: "22%",
      fixed: "left",
      render: (_, record) =>
      (record?.notes?.length > 28 ?
        <Tooltip title={record?.notes}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.notes?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.notes ? record?.notes : "-"}</p>),
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      sorter: true,
      width: "16.25%",
      fixed: "left",
      render: (_, record) =>
        record.is_active === true ? (
          <span style={{ color: "black" }}>Active</span>
        ) : (
          <span style={{ color: "#CACACA" }}>Inactive</span>
        ),
      align: 'center',
      fixed: 'center',
    },
  ];
  
  if (userPermission?.edit) {
    columns.push({
      title: "Action",
      key: "action",
      align: 'center',
      width: "10.6%",
      fixed: "left",
      render: (_, record) => (
        <div className="flex justify-center items-center">
          <Space size={"middle"}>
            <IconButton
              title="Edit"
              icon={<img src={ImagePaths.edit.default} alt="Edit" />}
              onClick={() => (loading ? "" : handleEditClick(record))}
              testID={'ug_btn_edit'}
            />
            {record.is_draft === true ?
              <IconButton
                title="Delete"
                icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                onClick={() => (loading ? "" : handleDeleteClicked(record?.id))}
                testID={'ug_btn_delete'}
              /> : null}
          </Space>
        </div>
      ),
    })
  }

  //delete start
  const handleDeleteClicked = (id) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: id,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: undefined,
      isDeleteClicked: false
    }));
  }

  const handleDeleteBtn = async (id) => {
    try {
      const apiData = await deleteDraftItemsById(id, EMPLOYEE_TYPE);
      if (apiData && apiData.status === "success") {
        ToastMsg("success", apiData.message);
        handleDraft();
      } else {
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      handleCancelBtn();
    }
  } 
  //delete end
 
  const showAddEdit = () => {
    return (
      <AddEmployeeType
        editDetails={selectedDetails}
        onHide={handleCancel}
        onSave={handleSave}
        fromEdit={editVisible}
        isActive = {userPermission?.active !== undefined ? userPermission?.active : true}
      />
    )
  }

  const handleFilter = () => {
    setFilterVisible(true)
  }
 
  const handleDraft = () => {
    setIsDraft(true);
    setFilterVisible(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = `is_draft=True`
    refetch(1,
      pageSize,
      queryRef.current,
      searchText,
      "");
  }

  const handleBackToHome = () => {
    setIsDraft(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1,
      pageSize,
      queryRef.current,
      searchText,
      "");
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&employee_code=" + (filterDatas.employee_type_code ? filterDatas.employee_type_code : "") +
    "&employee_type=" + (filterDatas.employee_type_name ? filterDatas.employee_type_name : "") +
    "&active_status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Employee Type"}
          addVisible={addVisible} editVisible={editVisible} addComponent={showAddEdit}
          pageInfo={pageInfo} handleSearch={handleSearch} selectedDetails={selectedDetails}
          addClick={addClick} onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          handleDraft={handleDraft} isDraft={isDraft} handleBackToHome={handleBackToHome}
          url={EMPLOYEE_TYPE} query={queryString}
          isAddRequired = {userPermission?.add ? true : false}
          isDraftRequired = {userPermission?.draft ? true : false}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <MasterFilter
              pageName={"EmployeeType"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table 
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
        />
        </div>
      </div>
      {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
    </Card>
  );
}

export default EmployeeType;
