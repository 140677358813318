import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getRosterReports } from "hooks/api/reportsApi.js";
import { ROSTERING_REPORT } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import dayjs from 'dayjs';
import { getSubordinateCardList } from "hooks/api/configurationApi/payrollPeriodApi";
import { formatTimeToAMPM } from 'components/common/validation';

const initialData = {
    roster_employee_name: "",
    roster_department: "",
    roster_team: "",
    cardTltList: []
}

const RosterReport = () => {
    const authtoken = sessionStorage.getItem("token");
    const pageSize = 10;

    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ ...initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dates, setDates] = useState({
        roster_from_date: dayjs().startOf('week').add(1, 'day'),
        roster_to_date: dayjs().endOf('week').add(1, 'day'),
    })
    const tableContainerHeight = "67dvh";
    const dateFormat = 'YYYY-MM-DD'
    const parsedFromDate = dates.roster_from_date ? dayjs(dates.roster_from_date) : null;
    const parsedToDate = dates.roster_to_date ? dayjs(dates.roster_to_date) : null;
    const [query, setQuery] = useState("from_date=" + (parsedFromDate ? parsedFromDate.format(dateFormat) : "") +
        "&to_date=" + (parsedToDate ? parsedToDate.format(dateFormat) : "") +
        "&employee=" + (filterDatas.roster_employee_name ? filterDatas.roster_employee_name : "") +
        "&department=" + (filterDatas.roster_department ? filterDatas.roster_department : "") +
        "&team=" + (filterDatas.roster_team ? filterDatas.roster_team : ""));

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    useEffect(() => {
        const parsedFromDate = dates.roster_from_date ? dayjs(dates.roster_from_date) : null;
        const parsedToDate = dates.roster_to_date ? dayjs(dates.roster_to_date) : null;
        const newQuery = "from_date=" + (parsedFromDate ? parsedFromDate.format(dateFormat) : "") +
        "&to_date=" + (parsedToDate ? parsedToDate.format(dateFormat) : "") +
        "&employee=" + (filterDatas.roster_employee_name ? filterDatas.roster_employee_name : "") +
        "&department=" + (filterDatas.roster_department ? filterDatas.roster_department : "") +
        "&team=" + (filterDatas.roster_team ? filterDatas.roster_team : "");
        setQuery(newQuery);
    }, [filterDatas, dates]);

    const getCardList = async () => {
        try {
            const apiData = await getSubordinateCardList(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    cardTltList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    cardTltList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                cardTltList: []
            }))
        }
    };

    useEffect(() => {
        getCardList();
    }, []);

    const [data, pageInfo, loading, _message, refetch] = useApiData(getRosterReports, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    useEffect(() => {
        let { roster_from_date, roster_to_date } = dates;
        const startDate = dayjs(roster_from_date).startOf('day');
        const endDate = dayjs(roster_to_date).startOf('day');
        const totalDays = endDate.diff(startDate, 'day') + 1;
        let newColumns = [];
        for (let i = 0; i < totalDays; i++) {
            const currentDay = startDate.add(i, 'day');
            let columnDate = currentDay.format('DD-MM-YYYY');
            newColumns.push({
                title: <div>
                    <p>{currentDay.format('dddd')}</p>
                    <p>{columnDate}</p>
                </div>,
                width: 130,
                align: "center",
                dataIndex: columnDate,
                key: columnDate.toString(),
                render: (text, record) => {
                    const details = record?.shift?.find(item => item.date === currentDay.format('YYYY-MM-DD'));
                    let shiftText = details?.is_weekoff ? "Week-Off"
                        : details?.is_leave ? "Full Day Leave"
                            : details?.shift?.name ? details?.shift?.name : "";
                    return (<div className="flex flex-col justify-center items-center"
                        style={{
                        width: "100%",
                        height: "6dvh",
                        background: details?.is_weekoff ? "#D94853" : details?.is_leave ? "#EF7A55" : "transparent",
                        border: `2px solid ${details?.is_weekoff ? "#D94853" : details?.is_leave ? "#EF7A55" : "#04B7B1"}`
                    }}>
                        {shiftText?.length > 10 ? (
                            <Tooltip title={shiftText}>
                                <span className="musterStatusTxt"
                                    style={{ fontWeight: 600, color: details?.is_weekoff || details?.is_leave ? "white" : "#183433" }}>
                                    {shiftText?.slice(0, 10) + "..."}</span>
                            </Tooltip>
                        ) : (<p className="musterStatusTxt"
                            style={{ fontWeight: 600, color: details?.is_weekoff || details?.is_leave ? "white" : "#183433" }}>
                            {shiftText ? shiftText : "-"}</p>)}
                        <p className="musterStatusTxt"
                            style={{ fontSize: "0.65vw", color: details?.is_weekoff || details?.is_leave ? "white" : "#999292", fontWeight: 600 }}>
                            {details?.shift?.start_time && details?.shift?.end_time ? `${formatTimeToAMPM(details?.shift?.start_time)} - ${formatTimeToAMPM(details?.shift?.end_time)}`
                                : formatTimeToAMPM(details?.shift?.start_time) || formatTimeToAMPM(details?.shift?.end_time) || ""}
                        </p>
                    </div>)
                }
            });
        }
        setDynamicColumns(newColumns);
    }, [dates?.roster_from_date, dates?.roster_to_date, data]);

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            roster_employee_name: "",
            roster_department: "",
            roster_team: "",
        }));
        setPageNo(1);
    }
    //filter end

    const handleSearch = () => {
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "employee__employee_name",
            key: "employee__employee_name",
            width: 200,
            sorter: true,
            fixed: 'left',
            render: (_, record) => {
                let { employee } = record;
                let empDetails = {
                    ...employee,
                    "first_name": employee?.employee_name ? employee?.employee_name : "",
                    "profile": employee?.profile ? employee?.profile : "",
                    "designation_id": employee?.designation?.name ?
                        { id: employee?.designation?.id, designation_name: employee?.designation?.name }
                         : { id: 0, designation_name: "" },
                    "id": employee?.employee_id ? employee?.employee_id : ""
                }
                return <EmployeeDetails details={empDetails} />;
            }
        },
        ...dynamicColumns,
        {
            title: "Total Working Hours",
            dataIndex: "total_working_hours",
            key: "total_working_hours",
            align: "center",
            sorter: true,
            fixed: 'right',
            width: 150,
        },
    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString = "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&from_date=" + (parsedFromDate ? parsedFromDate.format(dateFormat) : "") +
        "&to_date=" + (parsedToDate ? parsedToDate.format(dateFormat) : "") +
        "&employee=" + (filterDatas.roster_employee_name ? filterDatas.roster_employee_name : "") +
        "&department=" + (filterDatas.roster_department ? filterDatas.roster_department : "") +
        "&team=" + (filterDatas.roster_team ? filterDatas.roster_team : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        tblContainer: {
            height: "72dvh",
            overflow: "hidden",
        },
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
            overflow: "hidden",
        },
    }

    const handleFromDateChange = (date) => {
        setDates((prevState) => {
            let newState = { ...prevState, roster_from_date: date };
            if (newState.roster_to_date && dayjs(newState.roster_to_date).isBefore(date, 'day')) {
                newState.roster_to_date = null;
            }
            return newState;
        });
    };

    const handleToDateChange = (date) => {
        setDates((prevState) => ({
            ...prevState,
            roster_to_date: date,
        }));
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"}>
                <CommonTblHeader pageName={"Roster Report"}
                    pageInfo={pageInfo} handleSearch={handleSearch}
                    onStateChange={handleStateChanges} handleFilter={handleFilter}
                    columns={columns} url={ROSTERING_REPORT} query={queryString}
                    isAddRequired={false} isDraftRequired={false}
                    isDateRequired = {true} handleDateChange = {handleFromDateChange}
                    selectedDate={dates.roster_from_date} isToDateRequired = {true}
                    handleToDateChange = {handleToDateChange} selectedToDate={dates.roster_to_date}
                    fromDateTlt = {"Select From Date"} toDateTlt = {"Select To Date"}
                />
            </div>
            <div className="h-5/6 mt-1">
                <div className={"mt-4 w-full"} style={styles.tblContainer}>
                    <div className='flex items-center justify-center' style={{ height: "5dvh" }}>
                        <div className='flex items-center' key={"Applied Leave"}>
                            <div className='dotBtn mx-5' style={{ backgroundColor: "#EF7A55" }}></div>
                            <span className='rostering_split_txt password-tlt'>Applied Leave</span>
                        </div>
                        {/* <div className='flex items-center' key={"Permission"}>
                            <div className='dotBtn  mx-5' style={{ backgroundColor: "#F7DD71" }}></div>
                            <span className='rostering_split_txt password-tlt'>Permission</span>
                        </div> */}
                        <div className='flex items-center' key={"Week-off"}>
                            <div className='dotBtn mx-5' style={{ backgroundColor: "#D94853" }}></div>
                            <span className='rostering_split_txt password-tlt'>Week-off</span>
                        </div>
                    </div>
                    <div className={"relative w-full"} style={styles.fltContainer}>
                        {filterVisible ?
                            <div className="absolute w-1/4 z-10"
                                style={{
                                    ...styles.fltContainer,
                                    background: "white",
                                    borderTop: "none",
                                    borderLeft: "none"
                                }}>
                                <ReportsFilter
                                    pageName={"Roster"}
                                    filterDatas={filterDatas}
                                    closeFilter={ClosePopUp}
                                    filteredData={renderFilter}
                                    clearData={clearFilter} />
                            </div>
                    : null}
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading={loading}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ x: 1300, y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={dataUpdated ? "updated" : "not-updated"}
                    />
                </div>
                </div>
            </div>
        </Card>
    );
}

export default RosterReport;