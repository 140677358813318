import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from "components/ui/Button/MyButton";
import { Card, Form, Input, Checkbox, Tooltip, Upload } from "antd";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { ADMIN_DOCUMENTS } from "constants";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys, extractImageName } from "components/common/validation";
import { imgUploadSize } from "components/common/enum";

export const AddEditDocuments = () => {
  const [formData, setFormData] = useState({
    code: "",
    document_name: "",
    comments: "",
    is_active: true,
    uploaded_documents:[],
    doc_name: "",
  });
  const [loading, setLoading] = useState(false);
  const prevPropsRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        code: editDetails.code ? editDetails.code : "",
        document_name: editDetails.document_name ? editDetails.document_name : "",    
        comments: editDetails.comments ? editDetails.comments : "",
        is_active: editDetails.is_active,
        uploaded_documents: editDetails?.document_url ? editDetails?.document_url : [],
        doc_name: editDetails.document_name ? editDetails.document_name : "",    
      }));
      form.setFieldsValue({
        id: editDetails.id,
        code: editDetails.code ? editDetails.code : "",
        document_name: editDetails.document_name ? editDetails.document_name : "",
        comments: editDetails.comments ? editDetails.comments : "",
        is_active: editDetails.is_active,
        uploaded_documents: editDetails?.document_url ? editDetails?.document_url : [],
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData])

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    form.setFieldsValue({
      ...formData,
      [name]: value,
    });
  };
  const handleUploadChange = ({ fileList }) => {
    const validFileList = Array.isArray(fileList) ? fileList : [];
    setFormData({
      ...formData,
      uploaded_documents: validFileList,
    });
  };

  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input
        autoComplete='off'
        style={styles.textField}
        disabled = {statekey === "code"}
        type={"text"}
        name={statekey}
        value={formData[statekey]}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testID={testID}
      />
      </>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/admin/Documents');
  }, [navigate, form])

  const handleSubmit = useCallback(() => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      const dataForm = new FormData();
      dataForm.append("code", formData.code ? formData.code : "");
      dataForm.append("document_name", formData.document_name ? formData.document_name : "");
      dataForm.append("comments", formData.comments ? formData.comments : "");
      dataForm.append('id', formData?.id ? formData?.id : '');
      dataForm.append('is_active', formData?.is_active);
      if(typeof formData.uploaded_documents === "string" )
        {
          dataForm.append("uploaded_documents", formData.uploaded_documents)
        } else {
          dataForm.append("uploaded_documents", formData.uploaded_documents[0].originFileObj)
        }
      fetch(ADMIN_DOCUMENTS, {
        method: methods,
        headers: {
          'Authorization': `token ${authtoken}`
        },
        body: dataForm
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
              code: "",
              document_name: "",
              is_active: true,
              comment: "",
              uploaded_documents: [],
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } 
  }, [formData, fromEdit, handleBackCancel]);

  const checkAllMandatory = useCallback(() => {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const beforeUpload = (file) => {
    const fileSizeMB = file.size / (1024 * 1024);
    const isWordDocument = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    setFormData({
      ...formData,
      doc_name: file.name,
    })
    
    if (!isWordDocument) {
      ToastMsg("error", "Only Word documents are supported");
      return false;
    }
    if (fileSizeMB > imgUploadSize) {
      ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
      return false;
    }
    return true;
  };

  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Organization Documents" : "Add Organization Documents"}</p>
        </div>
        <Checkbox
          disabled = {fromEdit ? !isActive : true }
          checked={formData.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
          data-testid={'doc_sts'}
        >
          Active
        </Checkbox>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          initialValues={formData}
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          data-testid='hs_form'
        >
          <div style={{ height: "400px"}}>
          <div className='w-full grid grid-rows-2 grid-cols-3 gap-5 m-1' style={{ height: "150px"}}>
            {fromEdit ? <Form.Item
              style={styles.formItem}
              name="code"
              rules={[{ required: true, message: "This field is required" },
                { validator: noBlankSpacesValidator }
            ]}
            >
              {renderInput('Document Code', "code", 'doc_code', 10, true)}
            </Form.Item> : null}
            <Form.Item
              style={styles.formItem}
              name="document_name"
              rules={[{ required: true, message: "Document Name is required" },
                { validator: noBlankSpacesValidator }
            ]}  
            >
              {renderInput('Document Name', "document_name", 'doc_name', 50, true)}
            </Form.Item>
            <Form.Item name="comments" style={styles.formItem}>
              {renderInput('Comments', "comments", 'doc_comment', 150)}
            </Form.Item>
            <Form.Item style={styles.emptyFormItem} />
          </div>
          <div>
            <p className="common_addTltTxt">Upload Document</p>
            <p className="my-2">Supported Format Word document</p>
            
            <Form.Item name ="uploaded_documents" rules={[{ required: true, message: 'Please upload a document!' }]}>
              <div>
              <Upload
              accept=".doc,.docx"
              beforeUpload={beforeUpload}
              onChange={handleUploadChange}
              fileList={!fromEdit ? formData?.uploaded_documents : []}
              maxCount={1}
              showUploadList={false}
              >
                <MyButton className="flex items-center" icon={ImagePaths.Upload.default} htmlType="button" label="Upload Dcoument" paddingX={".8vw 1vw"}  testID="doc_upload"/>
              </Upload>
              {formData.doc_name ? <p className="success_txt"> {formData.doc_name}</p> : null }
              </div>
            </Form.Item>
          </div>
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Cancel" onClick={handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="hs_save" />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Card>
  );
};

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default AddEditDocuments;
