import { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import { useNavigate } from 'react-router-dom';
import EmployeeFilter from "../EmployeeFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeePagination, getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { EMPLOYEE_PERSONAL_INFO } from "constants";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import dayjs from "dayjs";
import { EmployeeDetails } from "components/common/CommonComponent";
// import {checkUserPermissionFunc} from "components/common/CommonFuntion";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeGroup } from "hooks/api/masterApi/employeeGroupApi";

const initialData = {
  designation_name: "",
  department_name: "",
  reporting_manager: "",
  emplyee_group: "",
  is_active: "",
  designationList: [],
  departmentlist: [],
  reportingManagerList: [],
  employeeGroupList: []
}

function EmployeeList() {
  const query = "";
  const pageSize = 10;
  const authtoken = sessionStorage.getItem("token");
  const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));
  const isEditAccess = (is_super_admin || is_admin);

  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";

  const navigate = useNavigate();

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  // const userPermission = checkUserPermissionFunc("employee", "Employees", "SubMenu");

  /*call designation*/
  const getEmployeeDesignationList = async () => {
    try {
      const apiData = await getEmployeeDesignation(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          designationList: [...apiData.data]
        }))
      } else {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          designationList: []
        }))
      }
    } catch (error) {
      setFilterDatas((prevFilterData) => ({
        ...prevFilterData,
        designationList: []
      }))
    }
  }

  /*call departments*/
  const getDepartmentList = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          departmentlist: [...apiData.data]
        }))
      } else {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          departmentlist: []
        }))
      }
    } catch (error) {
      setFilterDatas((prevFilterData) => ({
        ...prevFilterData,
        departmentlist: []
      }))
    }
  }

  /*call employee group*/
  const getEmpGrpList = async () => {
    try {
      const apiData = await getEmployeeGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          employeeGroupList: [...apiData.data]
        }))
      } else {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          employeeGroupList: []
        }))
      }
    } catch (error) {
      setFilterDatas((prevFilterData) => ({
        ...prevFilterData,
        employeeGroupList: []
      }))
    }
  }

  // call Reporting Manager
  const getReportingManagerList = async () => {
    try {
      const apiData = await getEmployee(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          reportingManagerList: [...apiData.data]
        }))
      } else {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          reportingManagerList: []
        }))
      }
    } catch (error) {
      setFilterDatas((prevFilterData) => ({
        ...prevFilterData,
        reportingManagerList: []
      }))
    }
  };

  useEffect(() => {
    getDepartmentList();
    getEmpGrpList();
    getEmployeeDesignationList();
    getReportingManagerList();
  }, [])

  const [data, pageInfo, loading, _message, refetch, extras] = useApiData(getEmployeePagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting
  }); 

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const addClick = () => {
    if (!extras?.data?.extras){
      navigate('/employee/employeelist/add',
        {
          state: {
            fromEdit: false,
            id: null,
            departmentId: null,
            designationId: null,
            designation_name: null
          }
        });
    }
    else{
      ToastMsg("error","You have exceeded your employee limit")
    }
  };

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleClick = (empData) => {
    navigate('/employee/employeelist/add',
      {
        state: {
          fromEdit: true,
          id: empData.id,
          departmentId: empData?.department_id[0]?.id ? empData?.department_id[0]?.id  : null,
          designationId: empData?.designation_id[0]?.id ? empData?.designation_id[0]?.id : null,
          designation_name: empData?.designation_id[0]?.designation_name ? empData?.designation_id[0]?.designation_name : null 
        }
      })
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `designation=${data.designation_name !== undefined ? data.designation_name : ""}&status=${data.is_active ? data.is_active : ""}&department=${data.department_name !== undefined ? data.department_name : ""}&reporting_manager=${data.reporting_manager !== undefined ? data.reporting_manager : ""}&employee_group=${data.emplyee_group !== undefined ? data.emplyee_group : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = (data) => {
    setFilterDatas((prevState) => ({
      ...prevState,
      ...initialData,
      designationList: data.designationList.length > 0 ? data.designationList : [],
      departmentlist: data.departmentlist.length > 0 ? data.departmentlist : [],
      reportingManagerList: data.reportingManagerList.length > 0 ? data.reportingManagerList : [],
      employeeGroupList: data.employeeGroupList.length > 0 ? data.employeeGroupList : []
    }));
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
  }
  //filter end

  const columns = [
    {
      title: "Employee Details",
      dataIndex: "first_name",
      key: "first_name",
      width: "24.08%",
      sorter: true,
      fixed: "left",
      render: (_, record) => {
        let empDetails = {
          ...record,
          "profile": (record?.profile?.length > 0  && record?.profile[0]) ? record?.profile[0] : "",
          "designation_id": record?.designation_id?.length > 0 ? { ...record.designation_id[0] } : { id: 0, designation_name: "" },
          "id": record?.employee_id ? record?.employee_id : ""
        }
        return (
          <span
            onClick={() => handleClick(record)}
            style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}
          >
            <EmployeeDetails details={empDetails} />
          </span>
        )
      }
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "department_id__department_name",
      sorter: true,
      width: "14.58%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        let dep = record?.department_id && record?.department_id[0]?.department_name ? record?.department_id[0]?.department_name : "-";
        return (dep?.length > 20 ?
          <Tooltip title={dep}>{<span style={{ color: colors }}>{dep?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{dep ? dep : "-"}</p>)
      }
    },
    {
      title: "Reporting Manager",
      dataIndex: "reporting_manager_id",
      key: "reporting_manager_id__first_name",
      sorter: true,
      width: "17.08%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        let reporting_manager = record?.reporting_manager_id?.length > 0 ?
          `${record?.reporting_manager_id[0]?.first_name ? record?.reporting_manager_id[0].first_name : ""} ${record?.reporting_manager_id[0]?.last_name ? record?.reporting_manager_id[0].last_name : ""}` : "-"
        return (reporting_manager?.length > 20 ?
          <Tooltip title={reporting_manager}>{<span style={{ color: colors }}>{reporting_manager?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{reporting_manager ? reporting_manager : "-"}</p>)
      }
    },
    {
      title: "Date of Joining",
      dataIndex: "date_of_joining",
      key: "date_of_joining",
      sorter: true,
      width: "14.58%",
      render: (_, record) => (<span style={{ color: record.is_active ? "black" : "#CACACA" }}>{record?.date_of_joining ? dayjs(record.date_of_joining, "YYYY-MM-DD").format("DD-MM-YYYY") : "-"}</span>),
      align: 'center', 
      fixed: "left",
    },
    {
      title: "Employee Group",
      dataIndex: "employee_group_id",
      key: "employee_group_id__employee_group_name",
      width: "16.08%",
      sorter: true,
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        let employee_group = record?.employee_group_id?.length > 0 ?
          record?.employee_group_id[0]?.employee_group_name ? record?.employee_group_id[0].employee_group_name : "" : "-"
        return (employee_group?.length > 20 ?
          <Tooltip title={employee_group}>{<span style={{ color: colors }}>{employee_group?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{employee_group ? employee_group : "-"}</p>)
      }
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      sorter: true,
      width: "13.58%",
      render: (_, record) =>
        record.is_active ? (
          <span style={{ color: "black" }}>{"Active"}</span>
        ) : (
          <span style={{ color: "#CACACA" }}>{"Inactive"}</span>
        ),
      align: 'center',
      fixed: "left",
    },
  ];

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&designation=" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
    "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
    "&reporting_manager=" + (filterDatas.reporting_manager ? filterDatas.reporting_manager : "") +
    "&employee_group=" + (filterDatas.emplyee_group ? filterDatas.emplyee_group : "") +
    "&status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"} >
        <CommonTblHeader pageName={"Employees"}
          pageInfo={pageInfo}
          handleSearch={handleSearch} addClick={addClick}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={EMPLOYEE_PERSONAL_INFO} query={queryString}
          isAddRequired = {isEditAccess ? true : false}
          isDraftRequired = {false}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <EmployeeFilter
              pageName={"Employees"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={dataUpdated ? "updated" : "not-updated"}
        />
        </div>
      </div>
    </Card>
  );
}

export default EmployeeList;
