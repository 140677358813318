import React, { useEffect, useState } from "react";
import { Button, Card, Radio, Switch, Table, Tooltip } from "antd";
import { useNavigate } from 'react-router-dom';
import { getmyplan } from "hooks/api/myplanapi";
import useGetData from "components/common/useGetData";
import { EmployeeDetails } from "components/common/CommonFuntion";
import dayjs from "dayjs";
import { ImagePaths } from "utils/ImagePath";

const initialData = {
    selectedPlan : "Standard Package",
    billing_cycle : "monthly",
   
}


const SubscriptionPlan = () => {
    const authtoken = sessionStorage.getItem("token");
    const [billCycle, setBillCycle] = useState('monthly');
    const [crcycle, setCrcycle] = useState();
    const navigate = useNavigate();
    
    useEffect(() => {   
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);  

    const[data] = useGetData(getmyplan);

    useEffect(() => {
        setBillCycle(data?.billing_cycle);
        setCrcycle(data?.billing_cycle);
    }, [data])
    const handlePlanSelect =(plan, type) => {
        navigate('/myplans/updateplan', { state: { editDetails: plan , billCycle: billCycle, type: type, current_plan : data?.current_plan } });
    };
    
    const handleChange = (checked) => {
        setBillCycle(checked ? 'yearly' : 'monthly');
    };
    return (
        <Card style={{overflowY:"scroll",height:"90dvh"}}>
            <div className="text-center" style ={{backgroundColor:"#D7F3F3",height:"35dvh",padding:"1.3vw"}}>  
                <label className="common_header_txt" >A complete HR solution for every business ! </label>
                <p className="common_reimburse_supportTxt">All the modules you need to run your organization</p>
                <div className="" style={{marginTop:"2vw"}}>
                    <label className="common_employee_nameTxt" style={{fontSize:".85vw"}}>Pay Monthly</label>
                    <Switch className="custom-switch" checked={billCycle === "monthly" ? false : true}  onChange={handleChange} />
                    <label className="common_employee_nameTxt" style={{fontSize:".85vw"}}>Pay Yearly</label>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6" style={{padding:"0vw 6vw",marginTop: "-4vw"}}>
                {data?.plans?.map((plan) => (
                    <Card
                        key={plan?.packege_name}
                        className={`shadow-lg ${data?.current_plan === plan.packege_name ? 'selected-plan' : 'normal-plan'}`}
                    >   
                        <p className="header_txt">{plan.packege_name}</p>
                        <p className="sub_txt" style={{height:"8dvh"}}>{plan.packege_description}</p>
                        <p className="text-sm  mt-8 mb-2"><span className="planrs_Txt">₹{billCycle === 'monthly' ? plan.price_per_month : plan.price_per_year}</span>  Per User / {billCycle === 'monthly' ? 'Month' : 'Year'}</p>
                        <Button className="plan_btn" type="button" disabled = {(data?.current_plan === plan.packege_name && crcycle === billCycle) ? true : false }  onClick={() => handlePlanSelect(plan , "upgrade")}>
                                {(data?.current_plan === plan.packege_name) ? 'Current Plan' : (plan.packege_name === "Standard Package" && data?.current_plan === "Basic Package" || (plan.packege_name === "Premium Package" && ( data?.current_plan === "Basic Package" || data?.current_plan === "Standard Package"))) ? 'Upgrade Plan' : 'Change Plan'} 
                        </Button>
                        {(data?.current_plan === plan.packege_name) ? 
                            <Button className="plan_btn" type="button" onClick={() => handlePlanSelect(plan , "usercount")}>
                                Add User
                            </Button>
                            : null
                        }
                        <ul>
                            {plan?.features.map((modules, index) => (
                                <li key={index} className="mb-2">
                                   <span className="flex"> <img src={data?.current_plan === plan.packege_name ? ImagePaths.tick_selected.default : ImagePaths.tick_disabled.default }/><label className="ml-3">{modules}</label></span>
                                </li>
                            ))}
                        </ul>
                    </Card>
                ))}
            </div>
        </Card>
      
    );
}

export default SubscriptionPlan