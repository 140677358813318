import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Modal, Select, Button, DatePicker, Upload, Radio } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { ADMIN_NOTICE_BOARD } from "constants";
import { ImagePaths } from "utils/ImagePath";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';
import { extractImageName, areValuesNotEmptyExceptKeys, disabledPastDate } from 'components/common/validation';
import moment from "moment";
import { DeleteOutlined,} from "@ant-design/icons";
import { imgUploadSize } from 'components/common/enum';

function AddEditAdminNoticeBoard({ fromEdit, editDetails, onHide, onSave, departmentsList}) {
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const prevPropsRef = useRef();
  const dateFormat = 'YYYY-MM-DD HH:mm';
  const [formData, setFormData] = useState({
    status: "Published",  /* status types: Published or Scheduled or Draft */
    notice_title: undefined,
    description: undefined,
    attachment: [],
    notice_to_departments: [],
    published_date_time: undefined,
    expiry_date_time: undefined,
  });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [omittedValues, setOmittedValues] = useState([]);
  const [form] = Form.useForm();
  const [isSelectorOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        status: editDetails.status ? editDetails.status === "Draft" ? "Published" : editDetails.status : "Published",
        notice_title: editDetails.notice_title ? editDetails.notice_title : "",
        description: editDetails.description ? editDetails.description : "",
        attachment: editDetails.attachment ? editDetails.attachment : [],
        notice_to_departments: !editDetails?.notice_to_departments ? [] : editDetails.notice_to_departments,
        published_date_time: editDetails.published_date ? dayjs(editDetails.published_date + moment().format("HH:mm"), dateFormat) : "",
        expiry_date_time: editDetails.expiry_date ? dayjs(editDetails.expiry_date + " " + editDetails.expiry_time, dateFormat) : "",
      }));
      form.setFieldsValue({
        id: editDetails.id,
        status: editDetails.status ? editDetails.status === "Draft" ? "Published" : editDetails.status : "Published",
        notice_title: editDetails.notice_title ? editDetails.notice_title : "",
        description: editDetails.description ? editDetails.description : "",
        attachment: editDetails.attachment ? editDetails.attachment : "",
        notice_to_departments: !editDetails?.notice_to_departments ? [] : editDetails.notice_to_departments,
        published_date_time: editDetails.published_date ? dayjs(editDetails.published_date + moment().format("HH:mm"), dateFormat) : "",
        expiry_date_time: editDetails.expiry_date ? dayjs(editDetails.expiry_date + " " + editDetails.expiry_time, dateFormat) : "",
      });
      prevPropsRef.current = editDetails
    }
  }, [editDetails, form, formData])

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    onHide();
    setFormData((prevdata) => (
      {
        ...prevdata,
        status: "",
        notice_title: "",
        description: "",
        attachment: undefined,
        notice_to_departments: [],
        published_date: undefined,
        expiry_date: undefined,
        expiry_time: undefined
      }));
  };

  const getFileValue = (fileData) => {
    if (Array.isArray(fileData) && fileData.length > 0) {
      return fileData[0].originFileObj;
    } else if (typeof fileData === "string") {
      return fileData;
    } else {
      return "";
    }
  };

  const removeEmptyKeys = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== undefined && !(Array.isArray(obj[key]) && obj[key].length === 0)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token");
    const cleanedFormData = removeEmptyKeys(formData);
    try {
      setLoading(true)
      const dataForm = new FormData();
      let modifiedFormData = {};

      if (fromEdit) {
        dataForm.append("id", cleanedFormData.id);
      }

      for (let key in cleanedFormData) {
        if (key === "published_date_time") {
          modifiedFormData["published_date"] = dayjs(cleanedFormData.published_date_time).format('YYYY-MM-DD');
        } else if (key === "expiry_date_time") {
          modifiedFormData["expiry_date"] = dayjs(cleanedFormData.expiry_date_time).format('YYYY-MM-DD');
          modifiedFormData["expiry_time"] = dayjs(cleanedFormData.expiry_date_time).format('HH:mm:ss');
        } else if (key === "status") {
          modifiedFormData["status"] = isDraftClked ? "Draft" : cleanedFormData.status;
        } else if (key === "attachment") {
          modifiedFormData["attachment"] = getFileValue(cleanedFormData?.attachment);
        } else if(key === "notice_to_departments"){
          modifiedFormData["notice_to_departments"] = cleanedFormData.notice_to_departments?.length > 0 ? JSON.stringify(cleanedFormData.notice_to_departments) : [];
        } else {
          modifiedFormData[key] = cleanedFormData[key];
        }
      }

      for (let key in modifiedFormData) {
        dataForm.append(key, modifiedFormData[key]);
      }
      dataForm.append("is_draft", isDraftClked ? true : false);

      fetch(ADMIN_NOTICE_BOARD, {
        method: methods,
        headers: {
          'Authorization': `token ${authtoken}`
        },
        body: dataForm
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            onSave();
            handleCancel();
            setLoading(false);
            setFormData({
              status: "Published",
              notice_title: undefined,
              description: undefined,
              attachment: [],
              notice_to_departments: [],
              published_date_time: undefined,
              expiry_date_time: undefined,
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, fromEdit, handleCancel, onSave]);

  const handleChange = (name, value) => {
    if (name === "notice_to_departments" && value.includes('all')) {
      form.setFieldsValue({
        notice_to_departments: ["all"],
      });
      setFormData({
        ...formData,
        [name]: ["all"],
      });
      setSelectOpen(name === "notice_to_departments" && value.includes('all') ? false : true);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      form.setFieldsValue({
        [name]: value,
      });
    }
  };

  const handleSave = useCallback(() => {
    handleSubmit(false);
  }, [handleSubmit]);

  const handleSaveAsDraft = useCallback(() => {
    handleSubmit(true);
  }, [handleSubmit]);

  const handleFileChange = (statekey, fileList) => {
    form.setFieldsValue({
      [statekey]: fileList
    });
    handleChange(statekey, fileList);
  };

  const handleToggleTooltip = (visible, values, position) => {
    setShowTooltip(visible);
    setOmittedValues(values);
    setTooltipPosition(position);
  };

  const handleMouseEnter = (event, omittedValues) => {
    const rect = event.target.getBoundingClientRect();
    handleToggleTooltip(true, omittedValues, { x: rect.left, y: rect.bottom });
  };

  const handleMouseLeave = () => {
    handleToggleTooltip(false, [], { x: 0, y: 0 });
  };

  const renderMaxTagPlaceholder = (omittedValues) => (
    <div
      onMouseEnter={(event) => handleMouseEnter(event, omittedValues)}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: 'pointer' }}
    >
      +{omittedValues.length}
    </div>
  );

  let ImageUrl = (!Array.isArray(formData.attachment) && (formData.attachment && typeof formData.attachment === "string"))
    ? extractImageName(formData.attachment) : "";
  let isDocAvail = formData.attachment && formData.attachment?.length > 0 && formData.attachment[0]?.name;

  const keysToExclude = ['status'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={"28vw"}>
      <div className="w-full">
        <p id="common_ApprovalTxt">{fromEdit ? "Edit Notice Form" : "Add Notice Form"}</p>
        <Form
          layout="vertical"
          form={form}
          name="basicform"
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          colon={false}
          requiredMark={false}
          style={{ width: "100%" }}
          initialValues={formData}
          data-testid={'nb_form'}
        >
          <div className="grid grid-cols-1">
            <Form.Item
              label="Notice Title"
              name="notice_title"
              rules={[{ required: true, message: "This field is required" }]}
              tooltip={{
                title: "This field is required",
                icon: <span style={{ color: "red" }}>*</span>,
              }}
              style={styles.formItem}
            >
              <Input
                autoComplete='off'
                style={{ width: "100%" }}
                type={"text"}
                name={"notice_title"}
                value={formData.notice_title ? formData.notice_title : ""}
                maxLength={50}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                data-testid={'nb_in_title'}
              />
            </Form.Item>
            <Form.Item name="description" style ={styles.formItem}>
              <Input.TextArea
                bordered="true"
                className="mt-2 textarea"
                rows={4}
                autoSize={{
                  minRows: 4,
                  maxRows: 5,
                }}
                maxLength = {150}
                value={formData.description ? formData.description : ""}
                placeholder="Description about the notice"
                name="description"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                data-testid={'nb_in_description'}
              />
            </Form.Item>
            <Form.Item name="attachment" style={styles.formItem}>
              <div className="commonInputTlt">Upload here</div>
              <Input
                readOnly
                style={styles.uploadInput}
                value={ImageUrl ? ImageUrl : (isDocAvail) ? formData.attachment[0]?.name : ""}
                onClick={() => {
                  const uploadInput = document.getElementById(`notice_board_attachment`);
                  if (uploadInput) {
                    uploadInput.click();
                  }
                }}
                suffix={(ImageUrl || isDocAvail) ?
                  (
                    <Button
                      icon={<DeleteOutlined style={styles.uploadIcon} />}
                      onClick={({ }) => handleFileChange("attachment", [])}
                      style={styles.deleteBtn}
                    />
                  ) :
                  <Upload
                    id={`notice_board_attachment`}
                    maxCount={1}
                    fileList={Array.isArray(formData.attachment) ? formData.attachment : []}
                    onChange={({ fileList }) => handleFileChange("attachment", fileList)}
                    beforeUpload={(file) => {
                      const isImage = file.type.startsWith('image/');
                      const isPDF = file.type === 'application/pdf';
                      if (!isImage && !isPDF) {
                        ToastMsg("error", "You can only upload image or PDF files!");
                        return false;
                      }
                      const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;
                      if (!isWithinSizeLimit) {
                        ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                        return false;
                      }
                      return false;
                    }}
                    showUploadList={false}
                    style={styles.uploadBtn}
                    accept=".jpg,.jpeg,.png,.gif"
                  >
                    <Button style={styles.deleteBtn} icon={<img src={ImagePaths.documentUpload.default} alt="documentUpload" style={styles.uploadIcon} />} />
                  </Upload>}
              />
            </Form.Item>
            <Form.Item
              label="Notice To All Departments"
              name="notice_to_departments"
              rules={[{ required: true, message: "This field is required " }]}
              style={styles.formItem}
              tooltip={{
                title: "This field is required",
                icon: <span style={{ color: "red" }}>*</span>,
              }}
            >
              <Select
                showSearch
                mode="multiple"
                showArrow="true"
                style={{ width: "100%" }}
                onChange={(value) => handleChange("notice_to_departments", value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={formData.notice_to_departments ? formData.notice_to_departments : ""}
                placeholder="Select departments"
                data-testid={'nb_in_departments'}
                open={isSelectorOpen}
                onDropdownVisibleChange={(isOpen) => setSelectOpen(isOpen)}
                maxTagCount={2}
                maxTagTextLength={7}
                maxTagPlaceholder={(omittedValues) => renderMaxTagPlaceholder(omittedValues)}
              >
                {departmentsList.length > 0 ?
                  <Select.Option key={"all"} value={"all"}> All</Select.Option> : null}
                {departmentsList.map(department => (
                  (department.id === formData.notice_to_departments || department.is_active === true) ?
                  <Select.Option key={department.id} value={department.id} disabled={formData.notice_to_departments.includes('all') && department.id !== "all"}>
                    {department.department_name}
                  </Select.Option> : null
                ))}
              </Select>
              {omittedValues.length > 0 && showTooltip && (
                <div className="commonTooltipOfMutliSlt" 
                style={{position: 'fixed', top: tooltipPosition.y + 10, left: tooltipPosition.x }}>
                  <ul className="commonTooltipOfMutliSltDiv">
                    {omittedValues.map(value => (
                      <li key={value.key} className="commonTooltipOfMutliSltTxt">{value.label}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Form.Item>
            <Form.Item
              style ={styles.formItem}
              value={formData.status ? formData.status : ""}
              name="status">
              <div className='flex justify-start items-start'>
                <Radio.Group
                  name="status"
                  className="custom-radio-group"
                  onChange={(value) => handleChange("status", value.target.value)}
                  value={formData.status ? formData.status : ""}
                  data-testid={'nb_btn_pub_sch'}
                >
                  <Radio.Button value="Published">Publish Now</Radio.Button>
                  <Radio.Button value="Scheduled">Schedule</Radio.Button>
                </Radio.Group>
              </div>
            </Form.Item>
            <div className="grid gap-4 grid-cols-2">
              {formData.status !== "Published" && <div>
                <Form.Item
                  label="Publish date & Time"
                  rules={[{ required: true, message: "This field is required" }]}
                  name="published_date_time"
                  style ={styles.formItem}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                  >
                  <DatePicker
                    name="published_date_time"
                    locale={locale}
                    showTime
                    value={formData.published_date_time ? formData.published_date_time : ""}
                    format="DD-MM-YYYY HH:mm"
                    onChange={(date) => handleChange("published_date_time", date)}
                    disabledDate={disabledPastDate}
                    data-testid={'nb_in_pub_d&t'}
                    placeholder = ""
                  />
                </Form.Item>
              </div>}
              <div>
                <Form.Item
                  label="Expiry date & Time"
                  rules={[{ required: true, message: "This field is required" }]}
                  name="expiry_date_time"
                  style ={styles.formItem}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                  >
                  <DatePicker
                    name="expiry_date_time"
                    showTime
                    value={formData.expiry_date_time ? formData.expiry_date_time : ""}
                    format="DD-MM-YYYY HH:mm"
                    locale={locale}
                    onChange={(date) => handleChange("expiry_date_time", date)}
                    disabledDate={disabledPastDate}
                    data-testid={'nb_in_exp_d&t'}
                    placeholder = ""
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <Form.Item style ={styles.formItem}>
            <div className='flex justify-end items-end'>
              {!fromEdit ? 
              <MyButton htmlType="button" label="Draft" loading={loading} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "" : "#cbcbcb"} outlined = {isDataAvail ? true : false} paddingX={"0 0.7vw"} marginRight={"0.625vw"} testID="nb_btn_draft" />: null}
              <MyButton htmlType="submit" label={"Publish"} loading={loading} paddingX={"0 1.2vw"} onClick={() => handleSave} testID="nb_btn_publish" />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
const styles = {
  formItem: {
    margin: "0.5vw"
  },
  uploadInput: {
    flex: '1',
    padding: 0,
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid #616161',
  },
  uploadBtn: {
    border: 'none',
    margin: "0 !important",
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px'
  },
  deleteBtn: {
    padding: 0,
    border: "none"
  }
}
export default AddEditAdminNoticeBoard;